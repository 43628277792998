// Change string into capitalised
export const capitalise = (string: string) => {
  return string[0]?.toUpperCase() + string.substring(1)?.toLowerCase();
};

export const hrefHttpCheck = (url: string) => {
  return !url.startsWith("https://") ? "https://" + url : url;
};

export const getYouTubeID = (url: string) => {
  const regex =
    // eslint-disable-next-line
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  return url.match(regex)?.[1];
};

export const getGoogleMapLocation = (address: string) => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    address
  )}`;
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader.result ? reader.result.toString() : "");
    reader.onerror = (error) => reject(error);
  });
};

export const isEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const gtagEvent = (
  action: string,
  value: number,
  category?: string,
  label?: string
) => {
  (window as any).gtag("event", action, {
    value: value,
    event_category: category,
    event_label: label,
  });
};
