import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import Cookies from "js-cookie";
import { IUser, IUserInfo } from "services/type";

export interface UserState {
  user: Partial<IUser>; //string | null;
  userInfo: Partial<IUserInfo>;
  logged_in: boolean;
  verified: boolean;
}

const user = JSON.parse(localStorage.getItem("user")!);
const userInfo = JSON.parse(localStorage.getItem("user-info")!);
const logged_in = JSON.parse(localStorage.getItem("user-logged_in")!);
const verified = JSON.parse(localStorage.getItem("user-verified")!);

const defaultState: UserState = {
  user: {},
  userInfo: {},
  logged_in: false,
  verified: false,
};

const initialState: UserState = {
  user: user ?? {},
  userInfo: userInfo ?? {},
  logged_in: logged_in ?? false,
  verified: verified ?? false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      // state.user = action.payload;
      // localStorage.setItem("user", JSON.stringify(action.payload));
      // console.log("login cookies setting");
      // console.log(state);
      // console.log(action);
      Cookies.set("API_TOKEN", action.payload, { expires: 1 });
      state.logged_in = true;
      localStorage.setItem("user-logged_in", JSON.stringify(true));
    },
    setUser: (state, action) => {
      state.user = action.payload;
      state.logged_in = true;
      console.log(
        "setUser authcheck",
        !(action.payload.status === "NOT_VERIFIED")
      );
      state.verified = !(action.payload.status === "NOT_VERIFIED");
      localStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem("user-logged_in", JSON.stringify(true));
      localStorage.setItem(
        "user-verified",
        JSON.stringify(!(action.payload.status === "NOT_VERIFIED"))
      );
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
      localStorage.setItem("user-info", JSON.stringify(action.payload));
    },
    logout: () => {
      Cookies.remove("API_TOKEN");
      localStorage.removeItem("user");
      localStorage.removeItem("user-info");
      localStorage.removeItem("user-logged_in");
      localStorage.removeItem("user-verified");
      return defaultState;
    },
  },
});

export const { login, logout, setUser, setUserInfo } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.user;
export const selectUserInfo = (state: RootState) => state.user.userInfo;
export const isLoggedin = (state: RootState) => state.user.logged_in;

export default userSlice.reducer;
