import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import Button from "components/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIClient } from "services/api";
import { ILoginResponse } from "services/type";
import { login, logout, setUser } from "slices/userSlice";
import styles from "./style.module.scss";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  // const [email, setEmail] = useState<string>("evatar.dev@gmail.com");
  // const [password, setPassword] = useState<string>("Pass!23456");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const payload = {
    email: email,
    password: password,
  };

  const fetchAuthGet = async () => {
    try {
      const res = await APIClient.AuthGet();
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      if (status === 200) {
        setLoading(false);
        dispatch(setUser(data.data));
      }
    } catch {
      setLoading(false);
      console.log("APIClient.AuthGet - catch error");
    }
  };

  const fetchLogin = async () => {
    setLoading(true);
    try {
      const res = await APIClient.Login(payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      const loginRes: ILoginResponse = data?.data;
      if (status === 200) {
        dispatch(login(loginRes.jwt));
        fetchAuthGet();
      }
    } catch (err: any) {
      setLoading(false);
      console.log("APIClient.Login return error ---", err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  return (
    <section className={styles.Login}>
      <div className="container">
        <div className={styles.title}>Login</div>
        <div className={classNames(styles.intro, styles.fullwidth)}>
          <TextField
            className="MuiInput"
            label="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            className="MuiInput"
            label="Password"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {passwordVisible ? (
                    <VisibilityOutlinedIcon
                      onClick={() => setPasswordVisible((boolean) => !boolean)}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      onClick={() => setPasswordVisible((boolean) => !boolean)}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button
          small
          className={classNames(styles.button, styles.fullwidth)}
          onClick={() => fetchLogin()}
        >
          {loading ? <CircularProgress color="inherit" size={16} /> : "Sign in"}
        </Button>
      </div>
    </section>
  );
};

export default Login;
