import { TextField } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useAppDispatch } from "app/hooks";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import LogoLoading from "components/LogoLoading";
import Modal from "components/Modal";
import Placeholder from "components/Placeholder";
import { AnimatePresence, motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { APIClient } from "services/api";
import { logout } from "slices/userSlice";
import styles from "./style.module.scss";

type CardProps = {
  className?: string | undefined;
  img: string;
  title: string;
  content: string;
  link?: string;
  external?: boolean;
};

const Card: React.FC<CardProps> = ({
  className,
  img,
  title,
  content,
  link,
  external,
}) => {
  const LinkContext: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    if (!link) return children as React.ReactElement;

    return external ? (
      <a
        className={classNames(styles.wrapper, link ? styles.hover : undefined)}
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    ) : (
      <Link
        className={classNames(styles.wrapper, link ? styles.hover : undefined)}
        to={link}
      >
        {children};
      </Link>
    );
  };
  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className={classNames(styles.Card, className ? className : undefined)}
    >
      <LinkContext>
        <Placeholder
          className={styles.placeholder}
          src={img}
          x={600}
          y={400}
          centered
        />
        <div className={styles.text}>
          <div className={classNames(styles.title, "h6")}>{title}</div>
          <p className={styles.content}>{content}</p>
        </div>
      </LinkContext>
    </motion.div>
  );
};

export default Card;

type DashboardCardProps = {
  className?: string | undefined;
  id: string;
  name: string;
  img: string;
  index: number;
  defaultProfileId?: string;
  to: string;
};

export const DashboardCard: React.FC<DashboardCardProps> = ({
  className,
  img,
  id,
  name,
  index,
  defaultProfileId,
  to,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [popperOpen, setPopperOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  // const [confirmOpen, setConfirmOpen] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  // const avatarEditorRef = useRef<AvatarEditor>(null);
  // const [currentImageUrl, setCurrentImageUrl] = useState<string>("");
  const [currentCardName, setCurrentCardName] = useState<string>(name);

  // const { getRootProps, getInputProps } = useDropzone({
  //   maxFiles: 1,
  //   accept: {
  //     "image/webp": [".webp"],
  //     "image/svg+xml": [".svg"],
  //     "image/gif": [".gif"],
  //     "image/jpeg": [".jpg", ".jpeg"],
  //     "image/png": [".png"],
  //   },
  //   useFsAccessApi: false,
  //   onDrop: (acceptedFiles) => {
  //     setCurrentImageUrl(URL.createObjectURL(acceptedFiles[0]));
  //   },
  // });

  const handleToggle = () => {
    setPopperOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setPopperOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setPopperOpen(false);
    } else if (event.key === "Escape") {
      setPopperOpen(false);
    }
  }

  const fetchUpdateCard = async (payload: any) => {
    setUpdateLoading(true);
    try {
      const res = await APIClient.UpdateCard(id, payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // console.log("APIClient.UpdateCard - data", data.data, status);
      setUpdateLoading(false);
      setModalOpen(false);
      if (status === 200) {
        console.log("submit success data -----", data);
        navigate(0);
      }
    } catch (err: any) {
      setUpdateLoading(false);
      setModalOpen(false);
      console.log("APIClient.UpdateCard - catch error", err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  // Form Handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formEl = useRef<HTMLFormElement>(null);
  const dispatchSubmit = () => {
    formEl?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  return (
    <>
      <motion.div
        key={`${name}-${index}`}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 0.6,
          delay: index * 0.1,
        }}
        viewport={{ once: true }}
        className={classNames(
          styles.DashboardCard,
          className ? className : undefined
        )}
      >
        <Link className={styles.wrapper} to={`/dashboard/${to}/${id}`}>
          <Placeholder
            className={styles.image}
            src={img}
            x={450}
            y={284}
            centered
            contained
          />
          <div className={styles.name}>{name}</div>
        </Link>
        <IconButton
          className={styles.more}
          ref={anchorRef}
          onClick={handleToggle}
        >
          <span className="sr-only">More actions</span>
          <span className="ico ico-more"></span>
        </IconButton>
        <Popper
          className={styles.dropdown}
          open={popperOpen}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right top",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={popperOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={() => setModalOpen(true)}>
                      Edit name
                    </MenuItem>
                    {/* <MenuItem onClick={() => setConfirmOpen(true)}>
                      Delete
                    </MenuItem> */}
                    <MenuItem
                      onClick={() => {
                        window.open(`/io/${id}`, "_blank", "noreferrer");
                      }}
                    >
                      Show card
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </motion.div>
      <Modal
        title="Edit card"
        open={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        okayText="Save"
        okayAction={() => {
          dispatchSubmit();
        }}
        awaitLoading={updateLoading}
      >
        {/* <div className={styles.upload}>
          <p className={styles.label}>Card cover</p>
          <Placeholder
            className={styles.placeholder}
            {...(currentImageUrl
              ? {
                  component: (
                    <div className="avataredit">
                      <AvatarEditor
                        ref={avatarEditorRef}
                        image={currentImageUrl}
                        width={200}
                        height={200}
                        border={0}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={1}
                        rotate={0}
                      />
                    </div>
                  ),
                }
              : {
                  // centered: true
                  component: (
                    <div
                      {...getRootProps({
                        className: classNames("dropzone", styles.dropzone),
                      })}
                    >
                      <input {...getInputProps()} />
                      <AddIcon fontSize="large" />
                    </div>
                  ),
                })}
            // centered
            x={200}
            y={200}
            // src={img}
            // alt=""
          />
        </div> */}
        <form
          className={classNames(styles.upload, "MuiInput")}
          ref={formEl}
          onSubmit={handleSubmit((data) => {
            fetchUpdateCard({
              cardName: data.cardName,
              defaultProfileId: defaultProfileId,
            });
          })}
        >
          <TextField
            {...register("cardName", { required: true })}
            error={errors.cardName ? true : false}
            fullWidth
            label="Card Name"
            value={currentCardName}
            onChange={(e) => setCurrentCardName(e.target.value)}
          />
        </form>
      </Modal>
      {/* <ConfirmModal
        title="Confirm to delete this card?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
      /> */}
    </>
  );
};

type ProfileCardProps = {
  className?: string | undefined;
  id: string;
  index: number;
  cardId: string;
  img?: string;
  isDefault?: boolean;
  name: string;
  setDefaultProfile: React.Dispatch<React.SetStateAction<string>>;
  // data: ProfileData;
};

export const ProfileCard: React.FC<ProfileCardProps> = ({
  className,
  id,
  index,
  cardId,
  img = "",
  isDefault,
  name,
  setDefaultProfile,
  // data = {},
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [actionOpen, setActionOpen] = useState(false);
  // const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setActionOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setActionOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setActionOpen(false);
    } else if (event.key === "Escape") {
      setActionOpen(false);
    }
  }

  const fetchUpdateCard = async (payload: any) => {
    setLoading(true);
    try {
      const res = await APIClient.UpdateCard(cardId, payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      setLoading(false);
      // console.log("APIClient.UpdateCard - data", data.data, status);
      if (status === 200) {
        console.log("submit success data -----", data);
        setDefaultProfile(id);
      }
    } catch (err: any) {
      setLoading(false);
      console.log("APIClient.UpdateCard - catch error", err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  return (
    <>
      {loading ? <LogoLoading /> : null}
      <motion.div
        //<Paper
        key={`${name}-${index}`}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          duration: 0.6,
          delay: index * 0.1,
        }}
        viewport={{ once: true }}
        className={classNames(
          styles.DashboardCard,
          // styles.ProfileCard,
          className ? className : undefined
        )}
      >
        <div className={styles.wrapper}>
          <div className={styles.head}>
            <div className={styles.left}>{/* Profile id: {id} */}</div>
            <div className={styles.right}>
              {/* <IconButton className={styles.more}>
                <span className="sr-only">Share Profile</span>
                <ShareIcon />
              </IconButton> */}
              <IconButton
                ref={anchorRef}
                className={styles.more}
                onClick={handleToggle}
              >
                <span className="sr-only">More actions</span>
                <span className="ico ico-more"></span>
              </IconButton>
            </div>
          </div>
          <Popper
            className={styles.dropdown}
            open={actionOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-end"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: "right top",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={actionOpen}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={(e) => {
                          fetchUpdateCard({
                            cardName: name,
                            defaultProfileId: id,
                          });
                          handleClose(e);
                        }}
                        {...(isDefault ? { disabled: true } : undefined)}
                      >
                        Set as default
                      </MenuItem>
                      {/* <MenuItem onClick={() => setConfirmOpen(true)}>
                        Delete
                      </MenuItem> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Link
            className={styles.cardwrapper}
            to={`/dashboard/cards/${cardId}/edit/${id}`}
          >
            <div className={styles.cardholder}>
              <Placeholder
                className={classNames(
                  styles.card,
                  img ? undefined : styles.gradient
                )}
                {...(img
                  ? { src: img }
                  : {
                      component: (
                        <div className={styles.defaultIcon}>
                          <span className="ico ico-evatar"></span>
                        </div>
                      ),
                    })}
                centered
                x={200}
                y={190}
              />
              <AnimatePresence mode="wait">
                {isDefault ? (
                  <motion.div
                    initial={{ y: 20, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    exit={{ y: 20, opacity: 0 }}
                    transition={{
                      duration: 0.8,
                      delay: 0.2,
                    }}
                    className={styles.default}
                  >
                    Default
                  </motion.div>
                ) : null}
              </AnimatePresence>
            </div>
            <div className={styles.info}>
              <div className={styles.name}>{name}</div>
              {/* <div className={styles.position}>{data.jobTitle}</div>
              <div className={styles.department}>IT Department</div>
              <div className={styles.company}>{data.company}</div> */}
            </div>
          </Link>
        </div>
      </motion.div>
      {/* <ConfirmModal
        title="Confirm to delete this profile?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
      /> */}
    </>
  );
};
