import { useAppDispatch } from "app/hooks";
import { HttpStatusCode } from "axios";
import Profile from "components/Profile";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIClient } from "services/api";
import { IProfileData } from "services/type";
import { logout } from "slices/userSlice";

const IOPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cardid = "" } = useParams();
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Partial<IProfileData>>({});
  const [displayProfileId, setDisplayProfileId] = useState<string>("");

  useEffect(() => {
    console.log("io page is running...");
    const fetchCard = async () => {
      try {
        const res = await APIClient.CardScan(cardid);
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        setLoading(false);
        // console.log("APIClient.CardScan - data", data.data, status);
        if (status === 200) {
          console.log("card data", data.data);
          setProfile(data.data.data);
          setDisplayProfileId(data.data.id);
        }
      } catch (err: any) {
        setLoading(false);
        console.log("APIClient.CardScan - catch error", err);
        if (err && err.response?.status === 401) {
          console.log("401 detected, logout now");
          dispatch(logout());
          navigate("/login", {
            replace: true,
          });
        }
      }
    };

    fetchCard();
    // eslint-disable-next-line
  }, []);

  return (
    <Profile
      cardDefaultProfile={displayProfileId}
      currentImageUrl={profile?.iconUrl}
      currentCompanyLogoUrl={profile?.companyLogoUrl}
      editData={profile}
      editLoading={loading}
    />
  );
};

export default IOPage;
