import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "app/hooks";
import logo from "assets/img/evatar-logo.svg";
import classNames from "classnames";
import Button from "components/Button";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";
// import img from "assets/img/dummy-image.png";
import Placeholder from "components/Placeholder";
// import { useLocalStorage } from "hooks/useLocalStorage";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HttpStatusCode } from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { APIClient } from "services/api";
import { countryCodeList, regionList } from "services/constants";
import {
  calcTotal,
  selectCart,
  selectProductList,
  selectSubtotal,
} from "slices/cartSlice";
import { selectUserInfo } from "slices/userSlice";

const Checkout: React.FC = () => {
  const userInfo = useAppSelector(selectUserInfo);
  const deliveryInformation = userInfo?.deliveryInformation;
  const isMobile = useMediaQuery("(max-width:991px)");
  const [loading, setLoading] = useState<boolean>(false);
  // const [currentStep, setCurrentStep] = useState<checkoutSteps>(
  //   checkoutSteps.input
  // );
  const [open, setOpen] = useState<boolean>(isMobile ? false : true);
  // const previewCart =
  //   currentStep === checkoutSteps.input ||
  //   currentStep === checkoutSteps.confirmation;

  const dispatch = useAppDispatch();
  // const currentShipping = useAppSelector(selectShipping);
  // const shippingFee = shippingList.find(
  //   (item) => item.label === currentShipping
  // )?.price;
  const cartList = useAppSelector(selectCart);
  const productList = useAppSelector(selectProductList);
  const subtotal = useAppSelector(selectSubtotal);
  // const total = subtotal! + shippingFee!;

  useEffect(() => {
    dispatch(calcTotal());
    // eslint-disable-next-line
  }, [cartList, productList]);

  // const handleConfirmSubmission = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   setCurrentStep(checkoutSteps.success);
  // };

  // const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   dispatch(setShipping((event.target as HTMLInputElement).value));
  // };

  const cartVariants = {
    hidden: { x: "100%" },
    shown: { x: 0 },
  };

  const openCart = () => {
    setOpen(true);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const closeCart = () => {
    setOpen(false);
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "unset";
    }
  };

  // Form Handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formEl = useRef<HTMLFormElement>(null);

  const fetchCheckout = async (payload: Object) => {
    setLoading(true);
    try {
      const res = await APIClient.Checkout(payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      setLoading(false);
      // console.log("APIClient.Checkout - data", data.data, status);
      if (status === 200) {
        console.log("checkout...", data.data);
        window.location.replace(data.data);
      }
    } catch (err: any) {
      setLoading(false);
      console.log("APIClient.Checkout - catch error", err);
    }
  };

  return (
    <>
      <section className={styles.Checkout}>
        <div
          className={classNames(
            styles.flow
            // previewCart ? undefined : styles.single
          )}
        >
          <div className={styles.head}>
            <Link className={styles.logo} to="/" aria-label="Back to Homepage">
              <img src={logo} alt="" />
            </Link>
            <h3>Checkout</h3>
          </div>
          {/* {StepRender(currentStep)} */}
          <motion.div
            // key={`step-${checkoutSteps.input}`}
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <form
              className={styles.InputInfos}
              // onSubmit={handleFormSubmission}
              ref={formEl}
              onSubmit={handleSubmit((data) => {
                const payload = {
                  currency: "HKD",
                  products: cartList,
                  deliveryInformation: data,
                };
                console.log("submitting...", payload);
                fetchCheckout(payload);
              })}
            >
              <div className={styles.section}>
                <div className={styles.title}>Contact Information</div>
                <div className={styles.field}>
                  <div className="MuiInput">
                    <TextField
                      {...register("firstName", {
                        required: true,
                      })}
                      error={errors.firstName ? true : false}
                      defaultValue={
                        deliveryInformation
                          ? deliveryInformation.firstName ?? ""
                          : ""
                      }
                      fullWidth
                      label="First name"
                    />
                  </div>
                  <div className="MuiInput">
                    <TextField
                      {...register("lastName", {
                        required: true,
                      })}
                      error={errors.lastName ? true : false}
                      defaultValue={
                        deliveryInformation
                          ? deliveryInformation.lastName ?? ""
                          : ""
                      }
                      fullWidth
                      label="Last name"
                    />
                  </div>
                </div>
                <div className={styles.field}>
                  <div className="MuiInput">
                    <TextField
                      {...register("email", {
                        required: true,
                      })}
                      error={errors.email ? true : false}
                      defaultValue={
                        deliveryInformation
                          ? deliveryInformation.email ?? ""
                          : ""
                      }
                      fullWidth
                      label="Email"
                    />
                  </div>
                </div>
                <div className={styles.field}>
                  <div className="MuiInput">
                    <FormControl fullWidth>
                      <Select
                        className="MuiSelect"
                        MenuProps={{ className: "MuiSelectMenu" }}
                        IconComponent={(props) => (
                          <div {...props}>
                            <span className="ico ico-arrow-down"></span>
                          </div>
                        )}
                        labelId="phone-region-select"
                        id="phone-region-select"
                        defaultValue={
                          deliveryInformation
                            ? deliveryInformation.callingCode ?? ""
                            : ""
                        }
                        label="Country Code"
                        {...register("callingCode", {
                          required: true,
                        })}
                        error={errors.callingCode ? true : false}
                      >
                        {countryCodeList.length
                          ? countryCodeList.map((item, index) => (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      <InputLabel id="phone-region-select-label">
                        Country Code
                      </InputLabel>
                    </FormControl>
                  </div>

                  <div className="MuiInput">
                    <TextField
                      {...register("phoneNumber", {
                        required: true,
                      })}
                      error={errors.phoneNumber ? true : false}
                      defaultValue={
                        deliveryInformation
                          ? deliveryInformation.phoneNumber ?? ""
                          : ""
                      }
                      fullWidth
                      label="Phone Number"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.section}>
                <div className={styles.title}>Shipping Address</div>

                <div className={styles.field}>
                  <div className="MuiInput">
                    <TextField
                      {...register("addressLine1", {
                        required: true,
                      })}
                      error={errors.addressLine1 ? true : false}
                      defaultValue={
                        deliveryInformation
                          ? deliveryInformation.addressLine1 ?? ""
                          : ""
                      }
                      fullWidth
                      label="Address"
                    />
                  </div>
                </div>
                <div className={styles.field}>
                  {/* <div className="MuiInput">
                    <TextField fullWidth label="Postal code" />
                  </div>

                  <div className="MuiInput">
                    <TextField fullWidth label="City" />
                  </div> */}

                  <div className="MuiInput">
                    <FormControl fullWidth>
                      <InputLabel id="region-select-label">Region</InputLabel>
                      <Select
                        className="MuiSelect"
                        MenuProps={{ className: "MuiSelectMenu" }}
                        IconComponent={(props) => (
                          <div {...props}>
                            <span className="ico ico-arrow-down"></span>
                          </div>
                        )}
                        labelId="region-select"
                        id="region-select"
                        label="Region"
                        {...register("regionCode", {
                          required: true,
                        })}
                        error={errors.regionCode ? true : false}
                        defaultValue={
                          deliveryInformation
                            ? deliveryInformation.regionCode ?? ""
                            : ""
                        }
                      >
                        {regionList.length
                          ? regionList.map((item, index) => (
                              <MenuItem key={index} value={item.regionCode}>
                                {item.display}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </div>
                </div>

                {/* <div className={styles.field}>same as billing address</div> */}
              </div>
              <div className={styles.foot}>
                {/* <Payment /> */}
                <Button className={styles.submit} type="submit" small>
                  {loading ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : (
                    "Ship to This Address"
                  )}
                </Button>
                {/* <div>
                  By placing order you agree to our{" "}
                  <Link className={styles.alink} to="/terms-and-condtions">
                    Terms &amp; Conditions
                  </Link>
                </div> */}
              </div>
            </form>
          </motion.div>
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            className={styles.preview}
            initial="hidden"
            variants={cartVariants}
            animate={isMobile && !open ? "hidden" : "shown"}
            exit="hidden"
            transition={{ duration: 0.6 }}
          >
            <div className={styles.toggle}>
              <button
                className={styles.button}
                onClick={open ? closeCart : openCart}
              >
                <span className="ico ico-bag"></span>
                <span className="sr-only">Toggle cart summary</span>
              </button>
            </div>
            <div className={styles.close}>
              <button className={styles.button} onClick={closeCart}>
                <span className="sr-only">Close</span>
                <span className="ico ico-close-circle"></span>
              </button>
            </div>
            <div className={styles.head}>
              <div className={styles.text}>{cartList.length} items</div>
            </div>
            <div className={styles.border}></div>
            {cartList.length ? (
              <div className={styles.cartlist}>
                {cartList.map((item, index) => {
                  const product = productList.filter(
                    (product) => product.id === item.productId
                  )?.[0];

                  const display = product?.display?.filter(
                    (product) => product.locale === "EN"
                  )?.[0];

                  const correspondingCurrencyPrice =
                    product?.data?.prices?.filter(
                      (product) => product.currency === "HKD"
                    )?.[0];
                  return (
                    <div
                      className={styles.cartitem}
                      key={`${item.name}-${index}`}
                    >
                      <div className={styles.left}>
                        <Placeholder
                          className={styles.thumbnail}
                          src={product?.data?.iconUrl}
                          x={75}
                          y={52}
                          centered
                          contained
                          alt={display?.value}
                        />
                      </div>
                      <div className={styles.info}>
                        <div>{display?.value}</div>
                        <span className={styles.number}>
                          QTY: {item.quantity}
                        </span>
                        {/* <div className={styles.quantity}>
                          <MuiButton
                            className="MuiButton"
                            onClick={() => dispatch(decrement(item.productId))}
                            {...(item.quantity === 1
                              ? { disabled: true }
                              : void {})}
                          >
                            <RemoveIcon />
                            <span className="sr-only">Subtract one</span>
                          </MuiButton>
                          <span className={styles.number}>{item.quantity}</span>
                          <MuiButton
                            className="MuiButton"
                            onClick={() => dispatch(increment(item.productId))}
                          >
                            <AddIcon />
                            <span className="sr-only">Add one</span>
                          </MuiButton>
                        </div> */}
                      </div>
                      <div className={styles.price}>
                        HKD $
                        {item.quantity *
                          (correspondingCurrencyPrice?.finalPrice! ?? 0)}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className={styles.empty}>
                You have no items in your bag
                <span className="ico ico-bag"></span>
              </div>
            )}
            <div className={styles.border}></div>
            <div className={styles.summary}>
              {/* <div className={styles.row}>
                  <div className={styles.left}>Subtotal:</div>
                  <div className={styles.right}>${subtotal}</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.left}>Shipping:</div>
                  <div className={styles.right}>
                    ${shippingFee ? shippingFee : 0}
                  </div>
                </div>
                <div className={styles.border}></div> */}
              <div className={classNames(styles.row, styles.total)}>
                <div className={styles.left}>Total to pay:</div>
                <div className={styles.right}>
                  <span>HKD</span> ${subtotal}
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </section>
      <AnimatePresence mode="wait">
        {isMobile && open ? (
          //previewCart && isMobile && open ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
            className={styles.mask}
          ></motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};

export default Checkout;

// const shippingList = [
//   {
//     label: "Standard Shipping",
//     date: "Fri 16 Dec - Mon 19 Dec, 2022",
//     price: 50,
//   },
//   {
//     label: "Express Shipping",
//     date: "Wed 14 Dec - Fri 16 Dec, 2022",
//     price: 250,
//   },
// ];

// enum checkoutSteps {
//   input = 0,
//   confirmation = 1,
//   success = 2,
//   failure = 2,
// }

// const StepRender = (currentStep: checkoutSteps) => {
//   switch (currentStep) {
//     case checkoutSteps.input:
//       return (
//         <motion.div
//           key={`step-${checkoutSteps.input}`}
//           initial={{ y: 50, opacity: 0 }}
//           whileInView={{ y: 0, opacity: 1 }}
//           transition={{ duration: 0.8 }}
//           viewport={{ once: true }}
//         >
//           <form className={styles.InputInfos} onSubmit={handleFormSubmission}>
//             <div className={styles.section}>
//               <div className={styles.title}>Contact Information</div>
//               <div className={styles.field}>
//                 <div className="MuiInput">
//                   <TextField fullWidth label="First name" />
//                 </div>
//                 <div className="MuiInput">
//                   <TextField fullWidth label="Last name" />
//                 </div>
//               </div>
//               <div className={styles.field}>
//                 <div className="MuiInput">
//                   <TextField fullWidth label="Email" />
//                 </div>
//               </div>
//               <div className={styles.field}>
//                 <div className="MuiInput">
//                   <FormControl fullWidth>
//                     <Select
//                       className="MuiSelect"
//                       MenuProps={{ className: "MuiSelectMenu" }}
//                       IconComponent={(props) => (
//                         <div {...props}>
//                           <span className="ico ico-arrow-down"></span>
//                         </div>
//                       )}
//                       labelId="phone-region-select"
//                       id="phone-region-select"
//                       value={currentCountryCode}
//                       label="Country Code"
//                       onChange={(event: SelectChangeEvent) => {
//                         setCurrentCountryCode(event.target.value as string);
//                       }}
//                     >
//                       {countryCodeList.length
//                         ? countryCodeList.map((item, index) => (
//                             <MenuItem key={index} value={item}>
//                               {item}
//                             </MenuItem>
//                           ))
//                         : null}
//                     </Select>
//                     <InputLabel id="phone-region-select-label">
//                       Country Code
//                     </InputLabel>
//                   </FormControl>
//                 </div>

//                 <div className="MuiInput">
//                   <TextField fullWidth label="Phone Number" />
//                 </div>
//               </div>
//             </div>
//             <div className={styles.section}>
//               <div className={styles.title}>Shipping Address</div>

//               <div className={styles.field}>
//                 <div className="MuiInput">
//                   <TextField fullWidth label="Address" />
//                 </div>
//               </div>
//               <div className={styles.field}>
//                 {/* <div className="MuiInput">
//                   <TextField fullWidth label="Postal code" />
//                 </div>

//                 <div className="MuiInput">
//                   <TextField fullWidth label="City" />
//                 </div> */}

//                 <div className="MuiInput">
//                   <FormControl fullWidth>
//                     <InputLabel id="region-select-label">Region</InputLabel>
//                     <Select
//                       className="MuiSelect"
//                       MenuProps={{ className: "MuiSelectMenu" }}
//                       IconComponent={(props) => (
//                         <div {...props}>
//                           <span className="ico ico-arrow-down"></span>
//                         </div>
//                       )}
//                       labelId="region-select"
//                       id="region-select"
//                       value={currentRegion}
//                       label="Region"
//                       onChange={(event: SelectChangeEvent) => {
//                         setCurrentRegion(event.target.value as string);
//                       }}
//                     >
//                       {regionList.length
//                         ? regionList.map((item, index) => (
//                             <MenuItem key={index} value={item.regionCode}>
//                               {item.display}
//                             </MenuItem>
//                           ))
//                         : null}
//                     </Select>
//                   </FormControl>
//                 </div>
//               </div>

//               {/* <div className={styles.field}>same as billing address</div> */}
//             </div>
//             <div className={styles.foot}>
//               {/* <Payment /> */}
//               <Button className={styles.submit} type="submit" small>
//                 Ship to This Address
//               </Button>
//               {/* <div>
//                 By placing order you agree to our{" "}
//                 <Link className={styles.alink} to="/terms-and-condtions">
//                   Terms &amp; Conditions
//                 </Link>
//               </div> */}
//             </div>
//           </form>
//         </motion.div>
//       );
//     case checkoutSteps.confirmation:
//       return (
//         <motion.div
//           key={`step-${checkoutSteps.confirmation}`}
//           initial={{ y: 50, opacity: 0 }}
//           whileInView={{ y: 0, opacity: 1 }}
//           transition={{ duration: 0.8 }}
//           viewport={{ once: true }}
//         >
//           <form
//             className={styles.Confirmation}
//             onSubmit={handleConfirmSubmission}
//           >
//             <div className={styles.section}>
//               <InfoBox
//                 className={styles.block}
//                 title="Contact Information"
//                 button="Change"
//                 action={() => setCurrentStep(checkoutSteps.input)}
//                 list={[
//                   { label: "Name", value: "Uno Chan" },
//                   { label: "Email Address", value: "unox@gmail.com" },
//                   { label: "Phone", value: "+852 6123 4567" },
//                 ]}
//               />
//             </div>
//             {/* <div className={styles.section}>
//               <div className={styles.title}>Shipping options</div>

//               <FormControl>
//                 <FormLabel
//                   className="sr-only"
//                   id="shipping-radio-group-label"
//                 >
//                   Shipping options
//                 </FormLabel>
//                 <RadioGroup
//                   aria-labelledby="shipping-radio-group-label"
//                   value={currentShipping}
//                   onChange={handleRadioChange}
//                   name="shipping-radio-group"
//                   className="MuiRadioGroup"
//                 >
//                   {shippingList.length
//                     ? shippingList.map((item) => (
//                         <FormControlLabel
//                           className={
//                             currentShipping === item.label
//                               ? "Mui-checked"
//                               : undefined
//                           }
//                           key={item.label}
//                           value={item.label}
//                           control={<Radio />}
//                           label={
//                             <div className={styles.radio}>
//                               <div>{item.label}</div>
//                               <div className={styles.date}></div>
//                               <div className={styles.price}>
//                                 <span>HKD</span>${item.price}
//                               </div>
//                             </div>
//                           }
//                         />
//                       ))
//                     : null}
//                 </RadioGroup>
//               </FormControl>
//             </div> */}
//             <div className={styles.section}>
//               <InfoBox
//                 className={styles.block}
//                 title="Shipping Address"
//                 button="Change"
//                 action={() => setCurrentStep(checkoutSteps.input)}
//                 list={[
//                   {
//                     label: "Ship to",
//                     value: (
//                       <>
//                         <span>
//                           7/F, Allied Kajima Building, 138 Gloucester Road,
//                           Wan Chai, Hong Kong Island
//                         </span>
//                         <span>Hong Kong</span>
//                       </>
//                     ),
//                   },
//                 ]}
//               />
//             </div>
//             <div className={styles.foot}>
//               {/* <Payment /> */}
//               <Button className={styles.submit} type="submit" small>
//                 Place Order
//               </Button>
//               {/* <div>
//                 By placing order you agree to our{" "}
//                 <Link to="/terms-and-condtions">Terms &amp; Conditions</Link>
//               </div> */}
//             </div>
//           </form>
//         </motion.div>
//       );
//     case checkoutSteps.success:
//       return (
//         <motion.div
//           key={`step-${checkoutSteps.success}`}
//           initial={{ y: 50, opacity: 0 }}
//           whileInView={{ y: 0, opacity: 1 }}
//           transition={{ duration: 0.8 }}
//           viewport={{ once: true }}
//           className={styles.section}
//         >
//           <div className={styles.thankyou}>THANK YOU FOR YOUR ORDER !</div>
//           <div className={styles.message}>
//             Please check email for confirmation
//           </div>
//           <InfoBox
//             className={styles.block}
//             summary
//             list={[
//               { label: "Order Total", value: "HKD $1690" },
//               { label: "Order Reference", value: "Z12345678" },
//               {
//                 label: "Ship to",
//                 value: (
//                   <>
//                     <span>7/F, Allied Kajima Building</span>
//                     <span>138 Gloucester Road, Wan Chai</span>
//                     <span>Hong Kong Island</span>
//                     <span>00000</span>
//                     <span>Hong Kong</span>
//                   </>
//                 ),
//               },
//             ]}
//           />
//           <div className={classNames(styles.block, styles.card)}>
//             <div className={styles.content}>
//               <div className={styles.strong}>{cartList.length} Items</div>
//               {cartList.length ? (
//                 <div className={styles.cartlist}>
//                   {cartList.map((item, index) => {
//                     const product = productList.filter(
//                       (product) => product.id === item.productId
//                     )?.[0];

//                     const display = product?.display?.filter(
//                       (product) => product.locale === "EN"
//                     )?.[0];

//                     const correspondingCurrencyPrice =
//                       product?.data?.prices?.filter(
//                         (product) => product.currency === "HKD"
//                       )?.[0];

//                     return (
//                       <div className={styles.cartitem} key={index}>
//                         <div className={styles.left}>
//                           <Placeholder
//                             className={styles.thumbnail}
//                             src={product?.data?.iconUrl}
//                             x={75}
//                             y={52}
//                             centered
//                             contained
//                             alt={display?.value}
//                           />
//                         </div>
//                         <div className={styles.info}>
//                           <div>{display?.value}</div>
//                           <div>
//                             QTY:{" "}
//                             <span className={styles.quantityNum}>
//                               {item.quantity}
//                             </span>
//                           </div>
//                         </div>
//                         <div className={styles.price}>
//                           HKD $
//                           {item.quantity *
//                             (correspondingCurrencyPrice?.finalPrice! ?? 0)}
//                         </div>
//                       </div>
//                     );
//                   })}
//                 </div>
//               ) : (
//                 <div className={styles.empty}>
//                   You have no items in your bag
//                   <span className="ico ico-bag"></span>
//                 </div>
//               )}
//             </div>
//           </div>
//           <div className={styles.foot}>
//             <ButtonBase
//               className={styles.print}
//               onClick={() => window.print()}
//             >
//               Print this out
//             </ButtonBase>
//             <LinkButton
//               className={styles.submit}
//               to={"/product"}
//               type="submit"
//               small
//             >
//               Continue Shopping
//             </LinkButton>
//           </div>
//         </motion.div>
//       );
//     case checkoutSteps.failure:
//       return null;
//   }
// };
