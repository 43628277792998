import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import Button from "components/Button";
import { motion } from "framer-motion";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { APIClient } from "services/api";
import styles from "./style.module.scss";

const contactList = [
  {
    label: "Email",
    value: "support@evatar.tech",
  },
  {
    label: "Phone",
    value: "+852 5500 1081",
  },
  {
    label: "Address",
    value: (
      <>
        <span>801, Gravity</span>
        <span>29 Hing Yip St</span>
        <span>Kwun Tong, Hong Kong</span>
      </>
    ),
  },
];

const ContactUs: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // Form Handling
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const formEl = useRef<HTMLFormElement>(null);

  const fetchEnquiry = async (payload: Object) => {
    setLoading(true);
    try {
      const res = await APIClient.SendEnquiry(payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      setLoading(false);
      // console.log("APIClient.SendEnquiry - data", data.data, status);
      if (status === 200) {
        console.log("enquiry sent", data.data);
        reset();
        setSnackbarOpen(true);
      }
    } catch (err: any) {
      setLoading(false);
      console.log("APIClient.SendEnquiry - catch error", err);
    }
  };

  return (
    <section className={styles.Contact}>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message="We have received your message successfully. Please wait for our reply patiently."
      />
      <div className="container">
        <div className={styles.wave}>
          <div className={"animated-wave dark"}></div>
        </div>
        <div className={styles.info}>
          {contactList.length
            ? contactList.map((item, index) => (
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.8,
                    delay: index * 0.2,
                  }}
                  viewport={{ once: true }}
                  key={index}
                  className={styles.item}
                >
                  <div className={styles.label}>{item.label}</div>
                  <div className={styles.value}>{item.value}</div>
                </motion.div>
              ))
            : null}
        </div>

        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.6,
          }}
          viewport={{ once: true }}
          className={styles.message}
        >
          <form
            className={styles.form}
            ref={formEl}
            onSubmit={handleSubmit((data) => {
              console.log(data);
              fetchEnquiry(data);
            })}
          >
            <div className={classNames("h4", styles.fullwidth)}>
              GET IN TOUCH WITH US.
            </div>
            <TextField
              {...register("name", {
                required: true,
              })}
              error={errors.name ? true : false}
              className={styles.input}
              placeholder="Your Name"
            />
            <TextField
              {...register("company")}
              className={styles.input}
              placeholder="Your Company"
            />
            <TextField
              {...register("phoneNumber")}
              className={styles.input}
              placeholder="Your Phone"
            />
            <TextField
              {...register("email", {
                required: true,
              })}
              error={errors.email ? true : false}
              className={styles.input}
              placeholder="Your Email"
            />
            <TextField
              {...register("topic", {
                required: true,
              })}
              error={errors.topic ? true : false}
              className={classNames(styles.input, styles.fullwidth)}
              placeholder="Your Topic"
            />
            <TextField
              multiline
              {...register("message", {
                required: true,
              })}
              error={errors.message ? true : false}
              className={classNames(styles.input, styles.fullwidth)}
              placeholder="Your Message"
            />
            <Button
              className={classNames(styles.submit, styles.fullwidth)}
              small
              type="submit"
            >
              {loading ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                "Send a message"
              )}
            </Button>
          </form>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactUs;
