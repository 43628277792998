const EvatarLogo = ({ color = "#404d54" }: { color?: string }) => {
  return (
    <svg
      width="1238"
      height="243"
      viewBox="0 0 1238 243"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_247_7778)">
        <path
          d="M302.623 204.492C303.286 205.82 303.805 207.201 304.175 208.614C304.608 210.168 304.933 211.785 305.186 213.053C305.438 214.322 305.872 216.224 306.124 217.905C306.24 216.275 306.482 214.654 306.846 213.053C307.114 211.576 307.487 210.114 307.965 208.678C308.42 207.254 308.974 205.856 309.625 204.492L400.47 21.8793C402.107 18.3756 404.929 15.3981 408.555 13.3495C412.224 11.2473 416.519 10.1442 420.899 10.1786H431.221C421.597 29.8171 411.972 49.2442 402.347 68.4599C392.723 87.6756 383.17 106.881 373.69 126.075C364.065 145.291 354.44 164.538 344.816 183.818C335.191 203.097 325.566 222.545 315.941 242.162H296.307L180.811 10.1786H191.133C195.539 10.1239 199.863 11.2282 203.549 13.3495C207.27 15.3802 210.202 18.3543 211.959 21.8793L302.623 204.492Z"
          fill={color}
        />
        <path
          d="M501.638 10.2103H521.164C530.789 29.8488 540.414 49.3288 550.038 68.6502L578.912 126.361C588.537 145.513 598.09 164.729 607.57 184.008C617.05 203.287 626.675 222.682 636.444 242.194H626.194C621.845 242.24 617.582 241.135 613.958 239.023C610.347 236.94 607.491 233.991 605.729 230.525L584.074 187.274H438.404L417.217 230.557C415.552 234.065 412.689 237.034 409.024 239.055C405.311 241.149 400.985 242.251 396.572 242.226H386.286L501.638 10.2103ZM448.726 167.075H574.22L514.884 47.9124C514.209 46.5785 513.654 45.2002 513.224 43.7903C512.761 42.3311 512.399 40.8486 512.141 39.351C511.782 37.748 511.529 36.1281 511.383 34.4995C511.13 36.1167 510.842 37.6704 510.481 39.351C510.12 41.0316 509.903 42.2365 509.47 43.7903C509.096 45.2067 508.564 46.5874 507.882 47.9124L448.726 167.075Z"
          fill={color}
        />
        <path
          d="M602.048 30.4407V10.0518H799.041V30.4407H712.058V242.194H689.031V30.4407H602.048Z"
          fill={color}
        />
        <path
          d="M881.802 10.2103H901.328C910.953 29.8488 920.577 49.3288 930.202 68.6502L959.076 126.361C968.701 145.513 978.253 164.729 987.734 184.008C997.214 203.287 1006.84 222.682 1016.61 242.194H1006.29C1001.94 242.24 997.674 241.135 994.05 239.023C990.439 236.94 987.583 233.991 985.821 230.525L964.165 187.274H818.567L797.381 230.525C795.715 234.034 792.853 237.002 789.188 239.023C785.475 241.118 781.149 242.219 776.736 242.194H766.45L881.802 10.2103ZM828.89 167.075H954.204L895.048 47.9124C894.373 46.5785 893.818 45.2002 893.388 43.7903C892.925 42.3311 892.563 40.8486 892.305 39.351C891.928 37.7506 891.675 36.1298 891.547 34.4995C891.294 36.1167 891.005 37.6704 890.644 39.351C890.284 41.0316 890.067 42.2365 889.634 43.7903C889.26 45.2067 888.728 46.5874 888.046 47.9124L828.89 167.075Z"
          fill={color}
        />
        <path
          d="M1114.49 98.7104C1113.56 98.7114 1112.67 98.3992 1112 97.8384C1111.33 97.2776 1110.93 96.5112 1110.88 95.698C1111.07 91.3477 1109.35 87.1003 1106.06 83.844C1102.77 80.5878 1098.17 78.5749 1093.23 78.2263C1092.76 78.2021 1092.29 78.0958 1091.87 77.9136C1091.44 77.7314 1091.06 77.4769 1090.74 77.1646C1090.43 76.8524 1090.18 76.4885 1090.03 76.0939C1089.88 75.6993 1089.81 75.2817 1089.84 74.8652C1089.87 74.4485 1089.99 74.0407 1090.2 73.6652C1090.4 73.2897 1090.69 72.9539 1091.05 72.6769C1091.4 72.4 1091.82 72.1875 1092.27 72.0515C1092.72 71.9155 1093.19 71.8588 1093.67 71.8845C1100.39 72.3286 1106.66 75.012 1111.2 79.3857C1115.74 83.7594 1118.21 89.4928 1118.1 95.4127C1118.1 96.2269 1117.75 97.0103 1117.11 97.6006C1116.47 98.1909 1115.6 98.5429 1114.67 98.5836L1114.49 98.7104Z"
          fill={color}
        />
        <path
          d="M1140.87 98.2982C1139.95 98.2992 1139.06 97.987 1138.38 97.4262C1137.71 96.8654 1137.31 96.099 1137.27 95.2858C1135.39 58.1545 1098.36 55.6812 1096.77 55.586C1095.81 55.5356 1094.92 55.1531 1094.28 54.5228C1093.64 53.8924 1093.32 53.0658 1093.38 52.2249C1093.43 51.3839 1093.87 50.5974 1094.59 50.0384C1095.3 49.4794 1096.25 49.1938 1097.2 49.2442C1112.79 50.0687 1142.75 60.6278 1144.45 95.0004C1144.45 95.8147 1144.09 96.5981 1143.46 97.1884C1142.82 97.7787 1141.94 98.1306 1141.02 98.1713L1140.87 98.2982Z"
          fill={color}
        />
        <path
          d="M1169.53 101.469C1168.58 101.469 1167.66 101.135 1166.98 100.54C1166.3 99.9457 1165.92 99.1392 1165.92 98.2982C1162.71 34.2141 1098.72 30.0602 1096.01 29.9017C1095.09 29.8531 1094.23 29.4978 1093.6 28.9083C1092.97 28.3188 1092.62 27.5398 1092.62 26.7308C1092.67 25.8951 1093.1 25.1127 1093.81 24.5543C1094.52 23.9959 1095.46 23.7068 1096.41 23.7501C1097.17 23.8769 1169.75 28.5382 1173.14 98.2982C1173.14 99.1392 1172.76 99.9457 1172.08 100.54C1171.41 101.135 1170.49 101.469 1169.53 101.469Z"
          fill={color}
        />
        <path
          d="M1196.85 102.737C1195.9 102.737 1194.98 102.403 1194.3 101.809C1193.63 101.214 1193.25 100.408 1193.25 99.5666C1191.4 62.7523 1174.77 35.8313 1143.8 19.6279C1129.66 12.3159 1113.89 7.78317 1097.56 6.34182C1097.09 6.31757 1096.63 6.2113 1096.2 6.02911C1095.77 5.84692 1095.39 5.59239 1095.07 5.28012C1094.76 4.96785 1094.52 4.60398 1094.36 4.20938C1094.21 3.81479 1094.14 3.39723 1094.17 2.98065C1094.2 2.55534 1094.32 2.13917 1094.54 1.757C1094.75 1.37483 1095.05 1.03448 1095.42 0.756286C1095.78 0.478089 1096.21 0.267742 1096.67 0.137803C1097.14 0.00786383 1097.62 -0.0390038 1098.1 -2.05077e-06C1099.08 -2.05077e-06 1195.81 6.08814 1200.46 99.408C1200.46 100.249 1200.08 101.056 1199.41 101.65C1198.73 102.245 1197.81 102.579 1196.85 102.579V102.737Z"
          fill={color}
        />
        <path
          d="M1150.66 140.059C1158.96 139.26 1167 137.041 1174.33 133.527C1187.58 126.044 1191.4 119.797 1191.4 119.797H1068.69V55.0787V39.5729C1069.48 29.8277 1064.82 21.6256 1054.69 14.9667L1046.02 9.22734V54.6031V242.194H1068.69V140.059H1122.11L1198.98 229.891C1204.98 238.114 1214.31 242.215 1226.99 242.194H1237.82L1150.66 140.059Z"
          fill={color}
        />
        <path
          d="M127.502 242.194H0.347473V10.2103H133.096V30.4407H23.3746V114.945H132.122V135.176H23.3746V221.964H127.502V242.194Z"
          fill={color}
        />
        <path
          d="M65.6751 10.2103V30.409H129.126C134.611 30.5216 140.042 29.4396 144.936 27.2597C149.829 25.0797 154.032 21.8696 157.17 17.9156L163.667 10.2737H112.235L65.6751 10.2103Z"
          fill={color}
        />
        <path
          d="M66.1804 242.194V221.964H129.667C135.165 221.883 140.599 222.999 145.486 225.212C150.373 227.425 154.561 230.667 157.675 234.647L164.208 242.289H112.559L66.1804 242.194Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_247_7778">
          <rect
            width="1237.65"
            height="242.194"
            fill="white"
            transform="translate(0.347473)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EvatarLogo;
