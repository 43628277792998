import classNames from "classnames";
import React from "react";
import styles from "./style.module.scss";

type PlaceholderProps = {
  className?: string | undefined;
  circle?: boolean;
  src?: string;
  src2x?: string;
  alt?: string;
  x?: number;
  y?: number;
  centered?: boolean;
  rounded?: boolean;
  contained?: boolean;
  component?: React.ReactNode;
};

const Placeholder: React.FC<PlaceholderProps> = ({
  className,
  circle,
  src,
  src2x,
  alt = "",
  x = 16,
  y = circle ? 16 : 9,
  rounded,
  centered,
  contained,
  component,
}) => {
  const percentage = `${Math.round((y / x) * 100)}%`;

  return (
    <div
      className={classNames(
        styles.Placeholder,
        className ? className : undefined
      )}
    >
      {src ? (
        <picture
          className={classNames(
            styles.frame,
            circle ? styles.circle : undefined,
            rounded ? styles.rounded : undefined
          )}
          style={{ paddingBottom: percentage }}
        >
          {src2x ? <source media="(max-width:767px)" srcSet={src2x} /> : null}
          <img
            className={classNames(
              styles.image,
              centered ? styles.centered : undefined,
              contained ? styles.contained : undefined
            )}
            src={src}
            alt={alt}
          />
        </picture>
      ) : (
        <div
          className={classNames(
            styles.frame,
            circle ? styles.circle : undefined,
            rounded ? styles.rounded : undefined
          )}
          style={{ paddingBottom: percentage }}
        >
          {component ? component : null}
        </div>
      )}
    </div>
  );
};

export default Placeholder;
