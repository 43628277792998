import Button from "components/Button";
import ContactBanner from "components/ContactBanner";
import React from "react";
import styles from "./style.module.scss";
import SubscribeTable from "./SubscribeTable";

const Subscription: React.FC = () => {
  return (
    <>
      <div className="container">
        <SubscribeTable
          footer={<Button className={styles.button}>Get Your Cards</Button>}
        />
      </div>
      <ContactBanner secondary />
    </>
  );
};

export default Subscription;
