import React from "react";
// import Footer from "layouts/footer";
import { useAppSelector } from "app/hooks";
import classNames from "classnames";
import Header, { DashboardHeader } from "layouts/header";
import styles from "pages/dashboard/style.module.scss";
import { useMatches } from "react-router-dom";
import { selectUser } from "slices/userSlice";
import Footer from "./footer";
import ScrollToTop from "./ScrollToTop";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const matches = useMatches();
  // @ts-ignore
  const headerType = matches[matches.length - 1].handle?.headerType;

  return (
    <>
      <ScrollToTop />
      {headerType ? <Header /> : null}
      <div>{children}</div>
      {headerType ? <Footer /> : null}
    </>
  );
};

export default Layout;

export const DashboardLayout: React.FC<LayoutProps> = ({ children }) => {
  const user = useAppSelector(selectUser);

  return (
    <>
      <ScrollToTop />
      <DashboardHeader />
      <div className={classNames(user ? styles.Dashboard : undefined)}>
        {/* {user ? <Breadcrumb /> : null} */}
        {children}
      </div>
    </>
  );
};
