import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IProfileData } from "services/type";

export interface editState {
  profile: Partial<IProfileData>;
  imageUrl: string;
  companyLogoUrl: string;
}

const defaultState: editState = {
  profile: {},
  imageUrl: "",
  companyLogoUrl: "",
};

const initialState: editState = {
  profile: {},
  imageUrl: "",
  companyLogoUrl: "",
};

export const editSlice = createSlice({
  name: "edit",
  initialState,
  reducers: {
    clearEdit: () => {
      return defaultState;
    },
    setProfile: (state, action) => {
      const temp = JSON.stringify(action.payload);
      const copiedValues = JSON.parse(temp);
      state.profile = copiedValues;
    },
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    setCompanyLogoUrl: (state, action) => {
      state.companyLogoUrl = action.payload;
    },
  },
});

export const { clearEdit, setProfile, setImageUrl, setCompanyLogoUrl } =
  editSlice.actions;
export const getEditProfile = (state: RootState) => state.edit.profile;
export const getEditImageUrl = (state: RootState) => state.edit.imageUrl;
export const getEditCompanyLogoUrl = (state: RootState) =>
  state.edit.companyLogoUrl;

export default editSlice.reducer;
