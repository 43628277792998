import { useAppSelector } from "app/hooks";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import { LinkButton } from "components/Button";
import InfoBox from "components/InfoBox";
import LogoLoading from "components/LogoLoading";
import Placeholder from "components/Placeholder";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIClient } from "services/api";
import { regionList } from "services/constants";
import { selectProductList } from "slices/cartSlice";
import styles from "../../checkout/style.module.scss";

const OrderSuccess = () => {
  const { orderId = "" } = useParams();
  const productList = useAppSelector(selectProductList);
  const [order, setOrder] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const res = await APIClient.GetOrder(orderId);
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        setLoading(false);
        // console.log("APIClient.GetOrder - data", data.data, status);
        if (status === 200) {
          console.log("order data", data.data);
          setOrder(data.data);
        }
      } catch (err: any) {
        setLoading(false);
        console.log("APIClient.GetOrder - catch error", err);
      }
    };
    fetchOrder();
  }, [orderId]);

  return (
    <section className={styles.Checkout}>
      {loading ? (
        <LogoLoading />
      ) : (
        <div className={classNames(styles.flow, styles.single)}>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className={styles.section}
          >
            <div className={styles.thankyou}>THANK YOU FOR YOUR ORDER !</div>
            <div className={styles.message}>
              Please check email for confirmation
            </div>
            <InfoBox
              className={styles.block}
              summary
              list={[
                {
                  label: "Order Total",
                  value: `HKD $${order?.totalItemPrice ?? ""}`,
                },
                { label: "Order Reference", value: order?.id },
                {
                  label: "Name",
                  value: (
                    <span>
                      {order?.deliveryInformation?.firstName}{" "}
                      {order?.deliveryInformation?.lastName}
                    </span>
                  ),
                },
                { label: "Email", value: order?.deliveryInformation?.email },
                {
                  label: "Phone Number",
                  value: (
                    <span>
                      +{order?.deliveryInformation?.callingCode}{" "}
                      {order?.deliveryInformation?.phoneNumber}
                    </span>
                  ),
                },
                {
                  label: "Ship to",
                  value: (
                    <>
                      <span>{order?.deliveryInformation?.addressLine1}</span>
                      {order?.deliveryInformation?.addressLine2 ? (
                        <span>{order?.deliveryInformation?.addressLine2}</span>
                      ) : null}
                      {order?.deliveryInformation?.addressLine3 ? (
                        <span>{order?.deliveryInformation?.addressLine3}</span>
                      ) : null}

                      <span>
                        {
                          regionList.filter(
                            (region) =>
                              region.regionCode ===
                              order?.deliveryInformation?.regionCode
                          )[0]?.display
                        }
                      </span>
                    </>
                  ),
                },
              ]}
            />
            <div className={classNames(styles.block, styles.card)}>
              <div className={styles.content}>
                <div className={styles.strong}>
                  {order?.products?.length} Items
                </div>
                {order?.products?.length ? (
                  <div className={styles.cartlist}>
                    {order.products.map((item: any, index: number) => {
                      const product = productList.filter(
                        (product) => product.id === item.id
                      )?.[0];

                      return (
                        <div className={styles.cartitem} key={index}>
                          <div className={styles.left}>
                            <Placeholder
                              className={styles.thumbnail}
                              src={product?.data?.iconUrl}
                              x={75}
                              y={52}
                              centered
                              contained
                              alt={item.displayName}
                            />
                          </div>
                          <div className={styles.info}>
                            <div>{item.displayName}</div>
                            <div>
                              QTY:{" "}
                              <span className={styles.quantityNum}>
                                {item.quantity}
                              </span>
                            </div>
                          </div>
                          <div className={styles.price}>
                            HKD ${item.itemPrice}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className={styles.empty}>
                    You have not purchased any items
                  </div>
                )}
              </div>
            </div>
            <div className={styles.foot}>
              {/* <ButtonBase
                className={styles.print}
                onClick={() => window.print()}
              >
                Print this out
              </ButtonBase> */}
              <LinkButton
                className={styles.submit}
                to={"/product"}
                type="submit"
                small
              >
                Continue Shopping
              </LinkButton>
            </div>
          </motion.div>
        </div>
      )}
    </section>
  );
};

export default OrderSuccess;
