import Button from "components/Button";
import React from "react";
import styles from "../login/style.module.scss";

const Setup: React.FC = () => {
  return (
    <section className={styles.Login}>
      <div className="container">
        <div className={styles.title}>Lets get you set up!</div>
        <div className={styles.intro}>
          <p>
            We will go through the most important steps to get you up and
            running.
          </p>
        </div>
        <div className={styles.ul}>
          <p className={styles.li}>Customize Your Business</p>
          <p className={styles.li}>Upload Profile Image</p>
          <p className={styles.li}>Add Content</p>
        </div>
        <Button className={styles.button}>Get Started</Button>
      </div>
    </section>
  );
};

export default Setup;
