// import { getOS } from '@admin-portal/common-util';
import axios, { InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import CONFIG from "utils/config";
// import { environment } from '../../environments/environment';
// import { logout } from "../reducers/appReducer";
// import store from '../store';
// import { ConfigurationDetail } from '../libs/types';

export const API = () => {
  const apiInstance = axios.create({
    baseURL: `${CONFIG.REACT_APP_APP_API_DOMAIN}/`,
    // baseURL: '',
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  });
  apiInstance.defaults.withCredentials = true;
  /**
   * Set Authorization Headers
   */
  apiInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = Cookies.get("API_TOKEN");
    // console.log("token=" + token);
    if (token) {
      if (config && config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  });

  /**
   * Response handling
   */

  apiInstance.interceptors.response.use(
    (res) => {
      const { headers } = res;
      console.log(headers, headers["set-cookie"]);
      return Promise.resolve(res);
    },
    (err) => {
      console.error(err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        // dispatch(logout());
      }
      return Promise.reject(err);
    }
  );
  return apiInstance;
};
export const API_PATH = {
  SESSION_INIT: "api/session/init",
  LOGIN: "api/auth/login",
  SIGNUP: "api/auth/signUp",
  VERIFY: "api/auth/verifyAccount",
  RESEND_VERIFY: "api/auth/resendVerification",
  AUTH_GET: "api/auth/get",
  ACTIVATE_CARD: "api/card/activate",
  CARD_LIST: "api/card/cards/list",
  PROFILE_LIST: "api/profile/profiles/list",
  PROFILE: "api/profile",
  UPLOAD_PROFILE_IMAGE: "api/file/upload",
  UPDATE_PROFILE: "api/profile/update",
  CARD_SCAN: "api/io",
  UPDATE_CARD: "api/card/update",
  PRODUCT_LIST: "api/utilities/data/list/PRODUCT",
  PRODUCT_ITEM: "api/utilities/data/get",
  CART_LIST: "api/order/getCart",
  CART_UPDATE: "api/order/updateCart",
  CHECKOUT_SESSION: "api/order/createCheckout",
  GET_ORDER: "api/order/get",
  USER_INFO: "api/user/get",
  SEND_ENQUIRY: "api/enquiry/send",
};

export const APIClient = {
  /**
   * Session Init API
   * @returns Promise<AxiosResponse<any, any>>
   */
  SessionInit: async () => {
    return API().post(API_PATH.SESSION_INIT);
  },
  /**
   * Login API
   * @param {{ email: string, password: string }}
   * @returns Promise<AxiosResponse<any, any>>
   */
  Login: async ({ email, password }: { email: string; password: string }) => {
    return API().post(API_PATH.LOGIN, { email, password });
  },
  /**
   * Sign Up API
   * @param {{ username: string, email: string, password: string }}
   * @returns Promise<AxiosResponse<any, any>>
   */
  SignUp: async ({
    username,
    email,
    password,
  }: {
    username: string;
    email: string;
    password: string;
  }) => {
    return API().post(API_PATH.SIGNUP, { username, email, password });
  },
  /**
   * Verify Account API
   * * @param {token: string}
   * @returns Promise<AxiosResponse<any, any>>
   */
  VerifyAccount: async (token: string) => {
    return API().get(API_PATH.VERIFY + "/" + token);
  },
  /**
   * Resend Verification API
   * @returns Promise<AxiosResponse<any, any>>
   */
  ResendVerify: async () => {
    return API().post(API_PATH.RESEND_VERIFY);
  },
  /**
   * Auth Get API
   * @returns Promise<AxiosResponse<any, any>>
   */
  AuthGet: async () => {
    return API().get(API_PATH.AUTH_GET);
  },
  /**
   * Activate Card API
   * * @param {activationCode: string}
   * @returns Promise<AxiosResponse<any, any>>
   */
  ActivateCard: async (activationCode: string) => {
    return API().get(API_PATH.ACTIVATE_CARD + "/" + activationCode);
  },
  /**
   * Card List API
   * @returns Promise<AxiosResponse<any, any>>
   */
  CardList: async () => {
    return API().get(API_PATH.CARD_LIST);
  },
  /**
   * Profile List API
   * * * @param {cardId: string}
   * @returns Promise<AxiosResponse<any, any>>
   */
  ProfileList: async (cardId: string) => {
    return API().get(API_PATH.PROFILE_LIST + "/" + cardId);
  },
  /**
   * Profile API
   * * * @param {profileId: string}
   * @returns Promise<AxiosResponse<any, any>>
   */
  Profile: async (profileId: string) => {
    return API().get(API_PATH.PROFILE + "/" + profileId);
  },
  /**
   * Upload Profile Image API
   * @param {{ username: string, email: string, password: string }}
   * @returns Promise<AxiosResponse<any, any>>
   */
  UploadProfileImage: async ({
    type,
    fileName,
    base64,
  }: {
    type: string;
    fileName: string;
    base64: string;
  }) => {
    return API().post(API_PATH.UPLOAD_PROFILE_IMAGE, {
      type,
      fileName,
      base64,
    });
  },
  /**
   * Update Profile API
   * @param {profileId: string, payload: Object}
   * @returns Promise<AxiosResponse<any, any>>
   */
  UpdateProfile: async (profileId: string, payload: any) => {
    return API().post(API_PATH.UPDATE_PROFILE + "/" + profileId, payload);
  },
  /**
   * Card Scan API
   * @param {cardId: string}
   * @returns Promise<AxiosResponse<any, any>>
   */
  CardScan: async (cardId: string) => {
    return API().get(API_PATH.CARD_SCAN + "/" + cardId);
  },
  /**
   * Update Profile API
   * @param {cardId: string, payload: Object}
   * @returns Promise<AxiosResponse<any, any>>
   */
  UpdateCard: async (cardId: string, payload: any) => {
    return API().post(API_PATH.UPDATE_CARD + "/" + cardId, payload);
  },
  /**
   * Product List API
   * @returns Promise<AxiosResponse<any, any>>
   */
  ProductList: async () => {
    return API().get(API_PATH.PRODUCT_LIST);
  },
  /**
   * Get Product API
   * @param {productId: string}
   * @returns Promise<AxiosResponse<any, any>>
   */
  GetProduct: async (productId: string) => {
    return API().get(API_PATH.PRODUCT_ITEM + "/" + productId);
  },
  /**
   * Cart List API
   * @returns Promise<AxiosResponse<any, any>>
   */
  CartList: async () => {
    return API().get(API_PATH.CART_LIST);
  },
  /**
   * Cart Update API
   * * @param {payload: Object}
   * @returns Promise<AxiosResponse<any, any>>
   */
  CartUpdate: async (payload: Object) => {
    return API().post(API_PATH.CART_UPDATE, payload);
  },
  /**
   * Create Checkout Session API
   * * @param {payload: Object}
   * @returns Promise<AxiosResponse<any, any>>
   */
  Checkout: async (payload: Object) => {
    return API().post(API_PATH.CHECKOUT_SESSION, payload);
  },
  /**
   * Get Order API
   * * @param {orderId: string}
   * @returns Promise<AxiosResponse<any, any>>
   */
  GetOrder: async (orderId: string) => {
    return API().get(API_PATH.GET_ORDER + "/" + orderId);
  },
  /**
   * Get User Info API
   * @returns Promise<AxiosResponse<any, any>>
   */
  UserInfo: async () => {
    return API().get(API_PATH.USER_INFO);
  },
  /**
   * Send Enquiry API
   * * @param {payload: Object}
   * @returns Promise<AxiosResponse<any, any>>
   */
  SendEnquiry: async (payload: Object) => {
    return API().post(API_PATH.SEND_ENQUIRY, payload);
  },
};
