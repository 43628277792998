import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IProduct } from "services/type";
// import img from "assets/img/dummy-image.png";

export interface cartState {
  open: boolean;
  productList: IProduct[];
  cartItems: CartItem[];
  subtotal: number;
  quantity: number;
  shipping: string;
}

export type CartItem = {
  productId: string;
  name?: string;
  price?: number;
  material?: string;
  color?: string;
  img?: string;
  quantity: number;
};

const cartLocalKey = "shopping-cart-products";
const cart = JSON.parse(localStorage.getItem(cartLocalKey)!);
// const dummyProductList: CartItem[] = [
//   {
//     id: 0,
//     name: "0 Standard EVATAR NFC Digital Business Card",
//     price: 800,
//     material: "Metal",
//     color: "Black",
//     img: img,
//     quantity: 1,
//   },
//   {
//     id: 1,
//     name: "1 Standard EVATAR NFC Digital Business Card",
//     price: 800,
//     material: "Metal",
//     color: "Black",
//     img: img,
//     quantity: 1,
//   },
//   {
//     id: 2,
//     name: "2 Standard EVATAR NFC Digital Business Card",
//     price: 800,
//     material: "Metal",
//     color: "Black",
//     img: img,
//     quantity: 1,
//   },
//   {
//     id: 3,
//     name: "3 Standard EVATAR NFC Digital Business Card",
//     price: 800,
//     material: "Metal",
//     color: "Black",
//     img: img,
//     quantity: 1,
//   },
//   {
//     id: 4,
//     name: "4 Standard EVATAR NFC Digital Business Card",
//     price: 800,
//     material: "Metal",
//     color: "Black",
//     img: img,
//     quantity: 1,
//   },
// ];
const shippingLocalKey = "shopping-cart-shipping";
const shipping = JSON.parse(localStorage.getItem(shippingLocalKey)!);

const initialState: cartState = {
  open: false,
  productList: [],
  cartItems: cart ? cart : [],
  // cartItems: dummyProductList ? dummyProductList : [],
  subtotal: 0,
  quantity: 0,
  shipping: shipping ? shipping : "Standard Shipping",
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add: (state, action) => {
      state.cartItems.push({
        productId: action.payload.productId,
        quantity: action.payload.quantity,
      });
      localStorage.setItem(cartLocalKey, JSON.stringify(state.cartItems));
    },
    remove: (state, action) => {
      state.cartItems = state.cartItems.filter(
        (item) => item.productId !== action.payload
      );
      localStorage.setItem(cartLocalKey, JSON.stringify(state.cartItems));
    },
    increment: (state, action) => {
      if (
        state.cartItems.find((item) => item.productId === action.payload) ==
        null
      ) {
        state.cartItems.push({ productId: action.payload, quantity: 1 });
      } else {
        state.cartItems = state.cartItems.map((item) => {
          if (item.productId === action.payload)
            return { ...item, quantity: item.quantity + 1 };
          else return item;
        });
      }
      localStorage.setItem(cartLocalKey, JSON.stringify(state.cartItems));
    },
    decrement: (state, action) => {
      if (
        state.cartItems.find((item) => item.productId === action.payload)
          ?.quantity === 1
      ) {
        state.cartItems = state.cartItems.filter(
          (item) => item.productId !== action.payload
        );
      } else {
        state.cartItems = state.cartItems.map((item) => {
          if (item.productId === action.payload)
            return { ...item, quantity: item.quantity - 1 };
          else return item;
        });
      }
      localStorage.setItem(cartLocalKey, JSON.stringify(state.cartItems));
    },
    calcTotal: (state) => {
      state.subtotal = state.cartItems.reduce((subtotal, item) => {
        const product = state.productList?.filter(
          (product) => product.id === item.productId
        )?.[0];

        const correspondingCurrencyPrice = product?.data?.prices?.filter(
          (product) => product.currency === "HKD"
        )?.[0];

        return (
          subtotal +
          item.quantity * (correspondingCurrencyPrice?.finalPrice! ?? 0)
        );
      }, 0);
      state.quantity = state.cartItems.reduce(
        (quantity, item) => quantity + item.quantity,
        0
      );
    },
    setCart: (state, action) => {
      state.cartItems = action.payload ?? [];
      localStorage.setItem(cartLocalKey, JSON.stringify(state.cartItems));
    },
    setShipping: (state, action) => {
      state.shipping = action.payload;
      localStorage.setItem(shippingLocalKey, JSON.stringify(state.shipping));
    },
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
    setCartOpen: (state, action) => {
      state.open = action.payload;
    },
  },
});

export const {
  add,
  remove,
  increment,
  decrement,
  calcTotal,
  setCart,
  setShipping,
  setProductList,
  setCartOpen,
} = cartSlice.actions;
export const selectCartOpen = (state: RootState) => state.shoppingcart.open;
export const selectCart = (state: RootState) => state.shoppingcart.cartItems;
export const selectProductList = (state: RootState) =>
  state.shoppingcart.productList;
export const selectSubtotal = (state: RootState) => state.shoppingcart.subtotal;
export const selectQuantity = (state: RootState) => state.shoppingcart.quantity;
export const selectShipping = (state: RootState) => state.shoppingcart.shipping;

export default cartSlice.reducer;
