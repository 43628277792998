import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import Button from "components/Button";
import React, { useState } from "react";
import styles from "../login/style.module.scss";

const SetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmation, setConfirmation] = useState<string>("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmationVisible, setConfirmationVisible] =
    useState<boolean>(false);

  return (
    <section className={styles.Login}>
      <div className="container">
        <div className={styles.title}>Setup Password</div>
        <div className={classNames(styles.intro, styles.fullwidth)}>
          <TextField
            className="MuiInput"
            label="Enter Password"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span
                    className="ico ico-visible"
                    onClick={() => setPasswordVisible((boolean) => !boolean)}
                  ></span>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            className="MuiInput"
            label="Confirm Password"
            type={confirmationVisible ? "text" : "password"}
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <span
                    className="ico ico-visible"
                    onClick={() =>
                      setConfirmationVisible((boolean) => !boolean)
                    }
                  ></span>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button className={classNames(styles.button, styles.fullwidth)}>
          Confirm
        </Button>
      </div>
    </section>
  );
};

export default SetPassword;
