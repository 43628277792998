import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from "react-beautiful-dnd";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
} from "react-hook-form";
import styles from "../../../style.module.scss";
import { IDragSelection } from "../../edit";

type ComponentDraggableProps = {
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  remove: UseFieldArrayRemove;
  errors: FieldErrors<FieldValues>;
  selections: IDragSelection[];
  setFilteredSelectionList: React.Dispatch<
    React.SetStateAction<IDragSelection[]>
  >;
  defaultValue?: string;
  innerRef: (element: HTMLElement | null) => void;
  draggableProps: DraggableProvidedDraggableProps | null | undefined;
  dragHandleProps: DraggableProvidedDragHandleProps | null | undefined;
  draggableOrder: any[];
  setDraggableOrder: React.Dispatch<React.SetStateAction<any[]>>;
  dragIndex: number;
  inputValue?: string | string[];
};

const ComponentDraggable: React.FC<ComponentDraggableProps> = ({
  control,
  setValue,
  register,
  unregister,
  remove,
  errors,
  selections,
  setFilteredSelectionList,
  defaultValue,
  innerRef,
  draggableProps,
  dragHandleProps,
  draggableOrder,
  setDraggableOrder,
  dragIndex,
  inputValue = "",
}) => {
  const [previous, setPrevious] = useState<string>("");
  const [current, setCurrent] = useState<string>(
    defaultValue ?? selections[0].value
  );
  const [input, setInput] = useState<string | string[]>(inputValue);
  const previousSelection = previous
    ? selections.filter((item) => item.value === previous)[0]
    : undefined;
  const currentSelection = selections.filter(
    (item) => item.value === current
  )[0];

  useEffect(() => {
    setFilteredSelectionList((selections) => {
      let clearArray = selections.slice(0);
      const newFiltered = clearArray.filter((select, index) => {
        return select.value !== current;
      });
      // const duplicate = selections.some((el, i, arr) => arr.indexOf(el) !== i);
      const found = selections.some((el) => el.value === previous);
      if (!found && previousSelection) {
        newFiltered.push(previousSelection);
      }
      return newFiltered;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  useEffect(() => {
    const arr = draggableOrder.reduce((accumulator, item, index) => {
      return [
        ...accumulator,
        dragIndex === index
          ? {
              ...currentSelection,
              // ...selections.filter((item) => item.value === current)[0],
              inputValue: input,
              // ...(labelValue !== undefined ? { labelValue: label } : {}),
            }
          : item,
      ];
    }, []);

    setDraggableOrder(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, input]);
  // }, [current, input, label]);
  // Below added to solve compile error, may cause performance issue
  // dragIndex,
  // draggableOrder,
  // selections,
  // setDraggableOrder,

  const handleSelect = (event: SelectChangeEvent) => {
    const defaultValue =
      event.target.value === "Contact"
        ? ["", "", "", "", ""]
        : event.target.value === "Whatsapp"
        ? ["", ""]
        : "";
    const defaultData =
      event.target.value === "Contact"
        ? {
            companyName: "",
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
          }
        : event.target.value === "Whatsapp"
        ? {
            phoneNumber: "",
            text: "",
          }
        : "";
    console.log("selection check 111 ", event.target.value, defaultValue);
    setPrevious(current);
    setCurrent(event.target.value as string);
    setInput(defaultValue);
    setValue(`contacts.${dragIndex}`, {
      type: event.target.value?.toUpperCase(),
      title: event.target.value,
      data: defaultData,
    });
  };

  const handleRemove = () => {
    remove(dragIndex);
    // unregister(`contacts.${dragIndex}`);
    // setValue(`contacts.${dragIndex}`, null);
    const arr = draggableOrder.filter((item, index) => {
      if (dragIndex === index) {
        setFilteredSelectionList((selections) => {
          selections.push(item);
          return selections;
        });
        return false;
      } else {
        return true;
      }
    });
    setDraggableOrder(arr);
  };

  const singleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInput(e.target.value);
  };

  const multiInput =
    (inputIndex: number) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      // console.log("Array.isArray(input)", Array.isArray(input));
      if (Array.isArray(input)) {
        const newInput = (input as any[]).reduce((accumulator, item, index) => {
          return [...accumulator, inputIndex === index ? e.target.value : item];
        }, []);
        setInput(newInput);
      }
    };

  return (
    <div
      className={classNames(styles.HalfRow, styles.draggable)}
      ref={innerRef}
      {...draggableProps}
    >
      <input
        {...register(`contacts.${dragIndex}.type`)}
        value={current?.toUpperCase()}
        hidden
      />
      <div className={styles.bar}>
        <Select
          {...register(`contacts.${dragIndex}.title`, {
            onChange: handleSelect,
          })}
          className={styles.HalfSelect}
          value={current}
          MenuProps={{ className: "MuiSelectMenu" }}
          IconComponent={(props) => (
            <div {...props}>
              <span className="ico ico-arrow-down"></span>
            </div>
          )}
        >
          {selections.map((item, index) => (
            <MenuItem key={`${item.label}-${index}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>

        {currentSelection?.placeholder ? (
          <TextField
            {...register(`contacts.${dragIndex}.data`, {
              required: true,
              onChange: singleInput,
            })}
            error={errors[`contacts.${dragIndex}.data`] ? true : false}
            fullWidth
            className={classNames(
              styles.ComponentField,
              styles.ComponentInput,
              styles.HalfInput
            )}
            label={currentSelection.placeholder}
            value={input || ""}
          />
        ) : null}
        {currentSelection?.fields ? (
          <div
            className={classNames(
              styles.ComponentField,
              styles.ComponentInput,
              styles.HalfInput
            )}
          >
            {selections
              .filter((item) => item.value === current)[0]
              .fields?.map((field, index) => (
                <TextField
                  {...register(`contacts.${dragIndex}.data.${field.name}`, {
                    required: true,
                    onChange: multiInput(index),
                  })}
                  error={
                    errors[`contacts.${dragIndex}.data.${field.name}`]
                      ? true
                      : false
                  }
                  key={`${field}-${index}`}
                  fullWidth
                  label={field.label}
                  value={input[index] || ""}
                />
              ))}
          </div>
        ) : null}
        <div className={styles.actions}>
          <IconButton
            className={classNames(styles.accordionBtn, styles.yellow)}
            onClick={handleRemove}
          >
            <span className="ico ico-trash"></span>
            <span className="sr-only">Delete</span>
          </IconButton>
          <div
            {...dragHandleProps}
            className={classNames(styles.accordionBtn, styles.yellow)}
          >
            <span className="ico ico-drag"></span>
            <span className="sr-only">Drag</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ComponentDraggable);
