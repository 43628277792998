import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch, useAppSelector } from "app/hooks";
import logo from "assets/img/evatar-logo.svg";
import EvatarLogo from "components/EvatarLogo";
import classNames from "classnames";
import Drawer from "components/Drawer";
import Cart from "layouts/cart";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { isLoggedin, logout } from "slices/userSlice";
import Account from "./account";
import Navigation, {
  dashboardNav,
  navList,
  responsiveNavList,
} from "./navigation";
import styles from "./style.module.scss";
import { selectSnapInView } from "slices/UISlice";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const snapInView = useAppSelector(selectSnapInView);

  const { pathname } = useLocation();
  // const [cartOpen, setCartOpen] = useState(false);
  const logged_in = useAppSelector(isLoggedin);
  const breakpoint = useMediaQuery("(min-width: 768px)");

  const ref = useRef<HTMLElement>(null);
  const [height, setHeight] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Scroll Animation
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll, { passive: true });
    // clean up code
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, []);

  return (
    <>
      <Drawer
        open={drawerOpen}
        setOpen={setDrawerOpen}
        menu={breakpoint || logged_in ? navList : responsiveNavList}
      />
      <header
        className={classNames(
          styles.Header,
          pathname === "/" && offset > height ? styles.shadow : undefined,
          pathname === "/" ? styles.fixed : undefined,
          pathname === "/" && snapInView ? styles.white : undefined
        )}
        ref={ref}
      >
        <div className="container container--narrow">
          <div className={styles.aside}>
            <button
              className={styles.burger}
              onClick={() => setDrawerOpen(true)}
            >
              <span className="sr-only">Open Menu</span>
              <span className="ico ico-menu"></span>
            </button>
            <Link className={styles.logo} to="/" aria-label="Back to Homepage">
              {/* <img src={logo} alt="Back to Homepage" /> */}
              <EvatarLogo
                color={pathname === "/" && snapInView ? "#f7f7f7" : "#404d54"}
              />
            </Link>
          </div>
          <div className={styles.center}>
            <Navigation menu={navList} />
          </div>
          <div className={classNames(styles.aside, styles.right)}>
            <Cart />
            {logged_in ? (
              <>
                <Tooltip title="Dashboard">
                  <IconButton
                    className={styles.redirect}
                    component={Link}
                    to="/login"
                  >
                    <DashboardIcon />
                  </IconButton>
                </Tooltip>
                {breakpoint ? (
                  <Tooltip title="Logout">
                    <IconButton
                      className={styles.redirect}
                      onClick={() => dispatch(logout())}
                    >
                      <ExitToAppIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            ) : breakpoint ? (
              <>
                <Link className={styles.redirect} to="/login">
                  Login
                </Link>
                <Link className={styles.redirect} to="/signup">
                  Sign Up
                </Link>
              </>
            ) : null}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

export const DashboardHeader: React.FC = () => {
  const logged_in = useAppSelector(isLoggedin);
  const dispatch = useAppDispatch();
  const breakpoint = useMediaQuery("(min-width:767px)");

  return (
    <header
      className={classNames(
        styles.Header,
        styles.shadow,
        !logged_in ? styles.login : undefined
      )}
    >
      <div className="container container--narrow">
        <div className={styles.aside}>
          {/* <button className={styles.burger}>
                <span className="sr-only">Open Menu</span>
                <span className="ico ico-menu"></span>
              </button> */}
          <Link className={styles.logo} to="/" aria-label="Back to Homepage">
            <img src={logo} alt="Back to Homepage" />
          </Link>
        </div>
        <div className={classNames(styles.aside, styles.right)}>
          {breakpoint ? (
            <>
              <Navigation menu={dashboardNav} />
              <Account />
            </>
          ) : (
            <>
              <Tooltip title="Get Cards">
                <IconButton
                  className={styles.redirect}
                  component={Link}
                  to="/product"
                >
                  <StorefrontIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Logout">
                <IconButton
                  className={styles.redirect}
                  onClick={() => dispatch(logout())}
                >
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      </div>
    </header>
  );
};
