import SearchBar from "components/SearchBar";
// import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ButtonBase from "@mui/material/ButtonBase";
import img from "assets/img/dummy-image.png";
import classNames from "classnames";
import { DashboardCard } from "components/Card";
import Tabs from "components/Tabs";
import { useState } from "react";
import styles from "../style.module.scss";

const cardList = [
  { id: "124", name: "Uno Mars", img: "" },
  { id: "125", name: "Uno Mars", img: "" },
  { id: "126", name: "Uno Mars", img: "" },
  { id: "234", name: "Uno Mars", img: img },
  { id: "244", name: "Uno Mars", img: img },
  { id: "247", name: "Uno Mars", img: img },
];
const categoryList = ["All", "Monthly", "Annually"];

const Subscriptions: React.FC = () => {
  const [currentCategory, setCurrentCategory] = useState<string>(
    categoryList[0]
  );

  return (
    <>
      <section className={styles.Cards}>
        <div className="container">
          <div className={classNames(styles.header, styles.wrap)}>
            <SearchBar className={styles.search} />

            {categoryList.length ? (
              <Tabs
                primary
                fullWidth
                className={styles.tabs}
                list={categoryList}
                current={currentCategory}
                setCurrent={setCurrentCategory}
                ariaLabel="subscriptions"
                ariaControls="subscriptions-category"
              />
            ) : null}
          </div>
          <div className={styles.content}>
            {cardList
              ? cardList.map((card, index) => (
                  <DashboardCard
                    key={card.id}
                    index={index}
                    id={card.id}
                    name={card.name}
                    img={card.img}
                    to="subscriptions"
                  />
                ))
              : null}

            <ButtonBase className={styles.empty}>
              <div className={styles.add}>
                <AddIcon />
                <span>Add Card</span>
              </div>
            </ButtonBase>
            {/* <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{
                duration: 0.6,
                delay: cardList.length * 0.1,
              }}
              viewport={{ once: true }}
              className={styles.remarks}
            >
              Click any part of the card to start editing or add new fields
              using the buttons below. No changes to the card will be persisted
              until you click the Save Changes button.
            </motion.div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscriptions;
