import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import styles from "./style.module.scss";

type SearchProps = {
  className?: string | undefined;
  setQuery?: React.Dispatch<React.SetStateAction<string>>;
};

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
];

const SearchBar: React.FC<SearchProps> = ({ className, setQuery }) => (
  <form
    className={classNames(styles.SearchBar, className ? className : undefined)}
  >
    <Autocomplete
      freeSolo
      id="search"
      disableClearable
      options={top100Films.map((option) => option.title)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            placeholder: "Search Cards",
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <span className="ico ico-search"></span>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  </form>
);

export default SearchBar;
