// import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
// import FormControl from "@mui/material/FormControl";
// import MenuItem from "@mui/material/MenuItem";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import Button from "components/Button";
import Placeholder from "components/Placeholder";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
// import { useDropzone } from "react-dropzone";
import { useLocation, useParams } from "react-router-dom";
import styles from "../style.module.scss";

// import nfcBand1x from "assets/img/products/product-band.png";
// import nfcBand2x from "assets/img/products/product-band@2x.png";
// import nfcTag1x from "assets/img/products/product-tag.png";
// import nfcTag2x from "assets/img/products/product-tag@2x.png";
// import Tabs from "components/Tabs";
import { HttpStatusCode } from "axios";
import { APIClient } from "services/api";
// import { useAppDispatch } from "app/hooks";
// import { logout } from "slices/userSlice";
import { IProduct } from "services/type";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiButton from "@mui/material/Button";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { stepList } from "services/constants";
import { selectCart, setCart, setCartOpen } from "slices/cartSlice";

// const categoryList = ["Cards", "Tag", "Band"];
// const subDurationList = ["Monthly", "Yearly"];
// const quantityList = ["1", "2", "3", "4", "5", "10"];
// const colorList: FormRowItem[] = [{ label: "Black" }, { label: "Silver" }];
// const subPlanList: FormRowItem[] = [
//   {
//     label: "Professional",
//     price: 39,
//     details: [
//       "Available on iOS, Android and the web.",
//       "Create up to 2 different digital business cards for yourself",
//       "Add as many fields as you like to your cards",
//       "Share your card via QR code, text message, email or airdrop",
//       "Track who receives your card as well as when and where you met them",
//     ],
//   },
//   {
//     label: "Premium",
//     price: 59,
//     details: [
//       "Available on iOS, Android and the web.",
//       "Create up to 2 different digital business cards for yourself",
//       "Add as many fields as you like to your cards",
//       "Share your card via QR code, text message, email or airdrop",
//       "Track who receives your card as well as when and where you met them",
//     ],
//   },
//   { label: "One time purchase" },
// ];

// const materialList: any[] = [
//   { label: "Metal", price: 898 },
//   { label: "Wood", price: 498 },
//   { label: "PVC", price: 298 },
// ];

const CustomProduct: React.FC = () => {
  const { productId = "" } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const cartList = useAppSelector(selectCart);
  const [quantity, setQuantity] = useState<number>(1);
  const [product, setProduct] = useState<IProduct>(
    location.state?.product ? location.state?.product : {}
  );

  // const [currentMaterial, setCurrentMaterial] = useState<string>(
  //   materialList[0]?.label ?? ""
  // );

  const display = product.display?.filter(
    (product) => product.locale === "EN"
  )[0];
  const price = product.data?.prices?.filter(
    (product) => product.currency === "HKD"
  )[0]?.finalPrice;
  // const [currentCategory, setCurrentCategory] = useState<string>(
  //   // location.state?.product ? location.state?.product : categoryList[0]
  //   "Cards"
  // );
  // const [currentSubDuration, setCurrentSubDuration] = useState<string>(
  //   subDurationList[0]
  // );
  // const [currentSubPlan, setCurrentSubPlan] = useState<string>(
  //   subPlanList[0]?.label ?? ""
  // );
  // const [currentQuantity, setCurrentQuantity] = useState<string>(
  //   quantityList[0]
  // );
  // const [currentColor, setCurrentColor] = useState<string>(
  //   colorList[0]?.label ?? ""
  // );
  // const [currentImageUrl, setCurrentImageUrl] = useState<string>("");

  const avatarEditorRef = useRef<AvatarEditor>(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const canvas = avatarEditorRef.current
      ?.getImageScaledToCanvas()
      .toDataURL();
    if (canvas) {
      fetch(canvas)
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          console.log("imageURL --- ", URL.createObjectURL(blob));
        });
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const res = await APIClient.GetProduct(productId);
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        // setLoading(false);
        // console.log("APIClient.GetProduct - data", data.data, status);
        if (status === 200) {
          console.log("product data", data.data);
          setProduct(data.data);
        }
      } catch (err: any) {
        // setLoading(false);
        console.log("APIClient.GetProduct - catch error", err);
      }
    };

    console.log(location.state?.product);
    if (!location.state?.product) {
      fetchProduct();
    }
    // eslint-disable-next-line
  }, []);

  const fetchCart = async () => {
    try {
      const res = await APIClient.CartList();
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // setLoading(false);
      // console.log("APIClient.CartList - data", data.data, status);
      if (status === 200) {
        console.log("cart data", data.data);
        dispatch(setCart(data.data?.products));
        dispatch(setCartOpen(true));
      }
    } catch (err: any) {
      // setLoading(false);
      console.log("APIClient.CartList - catch error", err);
    }
  };

  const fetchCartUpdate = async (payload: Object) => {
    try {
      const res = await APIClient.CartUpdate(payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // setLoading(false);
      // console.log("APIClient.CartUpdate - data", data.data, status);
      if (status === 200) {
        console.log("cart updated", data.data);
        fetchCart();
      }
    } catch (err: any) {
      // setLoading(false);
      console.log("APIClient.CartUpdate - catch error", err);
    }
  };

  const addToCart = () => {
    let currentCart = cartList.slice(0);
    console.log("currentCart", currentCart);
    let isExist = currentCart.some((item) => item.productId === productId);
    console.log("isExist", isExist);
    if (isExist) {
      currentCart = currentCart.map((item) => {
        if (item.productId === productId)
          return { ...item, quantity: item.quantity + quantity };
        else return item;
      });
    } else {
      currentCart.push({ productId: productId, quantity: quantity });
    }
    console.log("after modify", currentCart);
    fetchCartUpdate({ products: currentCart });
  };

  return (
    <section className={styles.Custom}>
      <form className="container" onSubmit={handleSubmit}>
        <div className={styles.product}>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className={styles.upload}
          >
            <AnimatePresence mode="wait">
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -50, opacity: 0 }}
                transition={{ duration: 0.4 }}
                className={styles.preview}
              >
                <AnimatePresence mode="wait">
                  <motion.div
                    key="flip-front"
                    initial={{ rotateY: -90 }}
                    animate={{ rotateY: 0 }}
                    exit={{ rotateY: 90 }}
                    transition={{ duration: 0.4 }}
                    className={classNames(styles.image, styles.card)}
                    style={{
                      transformStyle: "preserve-3d",
                    }}
                  >
                    <Placeholder
                      centered
                      contained
                      x={550}
                      y={334}
                      src={product.data?.iconUrl ? product.data?.iconUrl : ""}
                      alt=""
                    />
                  </motion.div>
                </AnimatePresence>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </div>

        <div className={styles.customise}>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className={styles.row}
          >
            <div className={"h4"}>{display?.value}</div>
          </motion.div>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className={styles.row}
          >
            <i>{display.material}</i>
          </motion.div>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className={styles.row}
          >
            <div className={styles.price}>HK${price}</div>
          </motion.div>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className={styles.row}
          >
            <div className={styles.bold}>{display?.title}</div>
          </motion.div>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className={styles.row}
          >
            {display?.content}
          </motion.div>
          {/* {materialList.length ? (
            <FormRadioRow
              fullWidth
              state={currentMaterial}
              setState={setCurrentMaterial}
              list={materialList}
              label="Choose your card material"
            />
          ) : null} */}

          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className={styles.quantity}
          >
            <p>Quantity</p>

            <div className={styles.quantityInput}>
              <MuiButton
                className="MuiButton"
                onClick={() => setQuantity((value) => value - 1)}
                {...(quantity === 1 ? { disabled: true } : void {})}
              >
                <RemoveIcon />
                <span className="sr-only">Subtract one</span>
              </MuiButton>
              <TextField
                type="number"
                fullWidth
                placeholder="0"
                inputProps={{ min: 1 }}
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
              />
              <MuiButton
                className="MuiButton"
                onClick={() => setQuantity((value) => value + 1)}
              >
                <AddIcon />
                <span className="sr-only">Add one</span>
              </MuiButton>
            </div>
          </motion.div>

          <div className={styles.steps}>
            {stepList.length
              ? stepList.map((step, index) => (
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.8,
                      delay: index * 0.2,
                    }}
                    viewport={{ once: true }}
                    key={index}
                    className={styles.step}
                  >
                    <Placeholder
                      className={styles.image}
                      src={step.srcDark}
                      centered
                      x={1}
                      y={1}
                    />
                    <p className={styles.desc}>{step.desc}</p>
                  </motion.div>
                ))
              : null}
          </div>

          <motion.div
            // initial={{ y: 50, opacity: 0 }}
            // whileInView={{ y: 0, opacity: 1 }}
            // transition={{ duration: 0.8 }}
            // viewport={{ once: true }}
            className={styles.submit}
          >
            <Button className={styles.button} small onClick={addToCart}>
              Add to Cart
            </Button>
          </motion.div>
        </div>
      </form>
    </section>
  );
};

export default CustomProduct;
