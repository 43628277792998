import classNames from "classnames";
import React from "react";
import styles from "./style.module.scss";
import { Link, LinkProps } from "react-router-dom";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  small?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  icon,
  small,
  fullWidth,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classNames(
        styles.Button,
        className ? className : undefined,
        small ? styles.small : undefined,
        fullWidth ? styles.fullWidth : undefined
      )}
    >
      {children}
      {icon ? <span className={`ico ico-${icon}`}></span> : null}
    </button>
  );
};

interface LinkButtonProps extends LinkProps {
  icon?: string;
  small?: boolean;
  fullWidth?: boolean;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  className,
  children,
  icon,
  small,
  fullWidth,
  to,
  ...props
}) => {
  return (
    <Link
      {...props}
      to={to}
      className={classNames(
        styles.Button,
        className ? className : undefined,
        small ? styles.small : undefined,
        fullWidth ? styles.fullWidth : undefined
      )}
    >
      {children}
      {icon ? <span className={`ico ico-${icon}`}></span> : null}
    </Link>
  );
};

export default Button;
