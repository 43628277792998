import styled from "@emotion/styled";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { navItem } from "layouts/header/navigation";
import { NavLink } from "react-router-dom";
import { isLoggedin, logout } from "slices/userSlice";

type DrawerProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  menu: navItem[];
};

const CustomDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .MuiBox-root {
    min-width: 240px;
  }
  .MuiTypography-root {
    font-family: "poppins";
    font-size: 14px;
    color: #404d54;
  }
  .MuiListItemIcon-root {
    min-width: 40px;
  }
  .MuiListItemButton-root {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 32px;
  }
`;

const ListItemLink = styled(NavLink)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 32px;
`;

const Drawer: React.FC<DrawerProps> = ({ open, setOpen, menu }) => {
  const logged_in = useAppSelector(isLoggedin);
  const dispatch = useAppDispatch();
  const breakpoint = useMediaQuery("(max-width: 767px)");

  return (
    <CustomDrawer
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Box
        role="presentation"
        onClick={() => setOpen(false)}
        onKeyDown={() => setOpen(false)}
      >
        <List>
          {menu.length
            ? menu.map((item, index) => (
                <ListItem key={`${item.label}-${index}`} disablePadding>
                  <ListItemLink to={item.path}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemLink>
                </ListItem>
              ))
            : null}
          {logged_in && breakpoint ? (
            <ListItem disablePadding>
              <ListItemButton onClick={() => dispatch(logout())}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </Box>
    </CustomDrawer>
  );
};

export default Drawer;
