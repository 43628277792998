import { useAppDispatch, useAppSelector } from "app/hooks";
import { HttpStatusCode } from "axios";
import Cookies from "js-cookie";
import { DashboardLayout } from "layouts";
import { Navigate, Outlet, useMatches, useNavigate } from "react-router-dom";
import { APIClient } from "services/api";
import { logout, setUser, setUserInfo } from "slices/userSlice";

const PrivateRoutes = () => {
  const access_token = Cookies.get("API_TOKEN") ?? "";
  const verified = useAppSelector((state) => state.user.verified);
  const matches = useMatches();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  console.log("access_token", access_token);

  const fetchAuthGet = async () => {
    try {
      const res = await APIClient.AuthGet();
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      console.log("APIClient.AuthGet - data", data, status);
      dispatch(setUser(data.data));
    } catch (err: any) {
      console.log("APIClient.AuthGet - catch error", err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  const fetchUser = async () => {
    try {
      const res = await APIClient.UserInfo();
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // console.log("APIClient.UserInfo - data", data.data, status);
      if (status === 200) {
        console.log("user data", data.data);
        dispatch(setUserInfo(data.data));
      }
    } catch (err: any) {
      console.log("APIClient.UserInfo - catch error", err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  if (!access_token) {
    dispatch(logout());
    return <Navigate to="/login" />;
  } else {
    fetchAuthGet();
    fetchUser();
  }

  if (!verified) {
    return <Navigate to="/signup/success" replace />;
  } else {
    if (matches.length <= 1) {
      return <Navigate to="/dashboard/cards" replace />;
    }
  }

  return (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  );
};

export default PrivateRoutes;
