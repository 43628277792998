import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import classNames from "classnames";
import dayjs, { Dayjs } from "dayjs";
import styles from "pages/dashboard/style.module.scss";
import * as React from "react";
import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { IProfileSectionDraggable } from "../../edit";

type ComponentDatePickerProps = {
  label: React.ReactNode;
  defaultValue?: Dayjs | null;
  name: string;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  accordionOrder: any[];
  setAccordionOrder: React.Dispatch<
    React.SetStateAction<Partial<IProfileSectionDraggable>[]>
  >;
  dragIndex: number;
  setFormValue: UseFormSetValue<FieldValues>;
};

const ComponentDatePicker: React.FC<ComponentDatePickerProps> = ({
  label,
  defaultValue = null,
  name,
  control,
  register,
  errors,
  accordionOrder,
  setAccordionOrder,
  dragIndex,
  setFormValue,
}) => {
  const [value, setValue] = React.useState<Dayjs | null>(defaultValue);
  const isFuture = (date: Dayjs) => {
    const now = dayjs();

    return now.isBefore(date, "day");
  };

  useEffect(() => {
    const arr = accordionOrder.reduce((accumulator, item, index) => {
      return [
        ...accumulator,
        dragIndex === index
          ? {
              ...item,
              // ...selections.filter((item) => item.value === current)[0],
              data: {
                ...item.data,
                [name.split(".")[name.split(".").length - 1]]: value
                  ? value.format("DD-MM-YYYY")
                  : null,
              },
            }
          : item,
      ];
    }, []);

    setAccordionOrder(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className={classNames(styles.ComponentInput)}
        orientation="portrait"
        label={label}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          setFormValue(name, newValue?.format("DD-MM-YYYY"));
        }}
        shouldDisableDate={isFuture}
        renderInput={(params) => (
          <TextField
            {...params}
            {...register(name, {
              required: true,
            })}
            error={
              errors[name] || value?.format("DD-MM-YYYY") === "Invalid Date"
                ? true
                : false
            }
            fullWidth
          />
        )}
        inputFormat="DD-MM-YYYY"
      />
    </LocalizationProvider>
  );
};

export default ComponentDatePicker;
