import LogoLoading from "components/LogoLoading";
import Profile from "components/Profile";
import Checkout from "pages/checkout";
import ContactUs from "pages/contactus";
import Cards from "pages/dashboard/cards";
import CardEdit from "pages/dashboard/cards/edit";
import CardProfiles from "pages/dashboard/cards/profiles";
import Subscriptions from "pages/dashboard/subscriptions";
import SubscriptionEdit from "pages/dashboard/subscriptions/edit";
import Home from "pages/home";
import IOPage from "pages/io";
import Login from "pages/login";
import NotFound from "pages/notfound";
import OrderFail from "pages/order/fail";
import OrderSuccess from "pages/order/success";
import Product from "pages/product";
import CustomProduct from "pages/product/custom";
import SetPassword from "pages/setpassword";
import Setup from "pages/setup";
import SignUp from "pages/signup";
import SignUpSuccess from "pages/signup/success";
import Subscription from "pages/subscription";
import Verify from "pages/verify";
import {
  createBrowserRouter,
  DataRouteObject,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./Routes";

function Routers() {
  const routes: DataRouteObject[] = [
    {
      id: "Index",
      path: "/",
      element: <PublicRoutes />,
      children: [
        {
          index: true,
          id: "Home",
          path: "/",
          element: <Home />,
          handle: {
            headerType: 1,
          },
        },
        {
          id: "ContactUs",
          path: "/contactus",
          element: <ContactUs />,
          handle: {
            headerType: 1,
          },
        },
        {
          id: "Product",
          path: "/product",
          element: <Outlet />,
          children: [
            {
              id: "Product Landing",
              index: true,
              element: <Product />,
              handle: {
                headerType: 1,
              },
            },
            {
              id: "Product Custom",
              path: ":productId",
              element: <CustomProduct />,
              handle: {
                headerType: 1,
              },
            },
          ],
        },
        {
          id: "Subscription",
          path: "/subscription",
          element: <Subscription />,
          handle: {
            headerType: 1,
          },
        },
        {
          id: "Checkout",
          path: "/checkout",
          element: <Checkout />,
        },
        {
          id: "Order-base",
          path: "/order",
          element: <Outlet />,
          children: [
            {
              id: "Order",
              index: true,
              element: <NotFound />,
            },
            {
              id: "OrderSuccess",
              path: "success/:orderId",
              element: <OrderSuccess />,
            },
            {
              id: "OrderFail",
              path: "fail/:orderId",
              element: <OrderFail />,
            },
          ],
        },
        {
          id: "Login",
          path: "/login",
          element: <Login />,
          handle: {
            headerType: 1,
          },
        },
        {
          id: "SignUp-Base",
          path: "/signup",
          element: <Outlet />,
          children: [
            {
              id: "SignUp",
              index: true,
              element: <SignUp />,
              handle: {
                headerType: 1,
              },
            },
            {
              id: "SignUpSuccess",
              path: "success",
              element: <SignUpSuccess />,
              handle: {
                headerType: 1,
              },
            },
          ],
        },
        {
          id: "Profile-Base",
          path: "/profile",
          element: <Outlet />,
          children: [
            {
              id: "Profile",
              path: ":profileid",
              element: <Profile />,
            },
          ],
        },
        {
          id: "IO-Base",
          path: "/io",
          element: <Outlet />,
          children: [
            {
              id: "IO",
              path: ":cardid",
              element: <IOPage />,
            },
          ],
        },
        {
          id: "Password",
          path: "/setpassword",
          element: <SetPassword />,
        },
        {
          id: "Setup",
          path: "/setup",
          element: <Setup />,
        },
        {
          id: "VerifyAccount",
          path: "/verify/:token",
          element: <Verify />,
          handle: {
            headerType: 1,
          },
        },
      ],
    },
    {
      id: "Dashboard",
      path: "/dashboard",
      element: <PrivateRoutes />,
      children: [
        {
          id: "Cards-Base",
          path: "cards",
          element: <Outlet />,
          children: [
            {
              id: "Cards",
              index: true,
              element: <Cards />,
            },
            {
              id: "Card",
              path: ":cardid",
              element: <Outlet />,
              children: [
                {
                  id: "Profiles",
                  index: true,
                  element: <CardProfiles />,
                },
                {
                  id: "Edit-Base",
                  path: "edit",
                  element: <Outlet />,
                  children: [
                    {
                      id: "Edit Profile",
                      path: ":profileid",
                      element: <CardEdit />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: "Subscriptions",
          path: "subscriptions",
          element: <Outlet />,
          children: [
            {
              id: "Subscriptions-Base",
              index: true,
              element: <Subscriptions />,
            },
            {
              id: "Card2",
              path: ":cardid",
              element: <SubscriptionEdit />,
            },
          ],
        },
        {
          id: "Analytics",
          path: "analytics",
          element: <Cards />,
        },
        {
          id: "Settings",
          path: "settings",
          element: <Cards />,
        },
      ],
    },
    {
      id: "NotFound",
      path: "*",
      element: <NotFound />,
    },
  ];

  let router = createBrowserRouter(routes);

  return <RouterProvider router={router} fallbackElement={<LogoLoading />} />;
}

export default Routers;
