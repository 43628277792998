import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import classNames from "classnames";
import { LinkButton } from "components/Button";
import Tabs from "components/Tabs";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

const planList = [
  {
    label: "Professional",
    price: 39,
    details: ["3 profiles", "1 video upload", "Deeplink action"],
  },
  {
    label: "Premium",
    price: 59,
    details: [
      "10 profiles",
      "3 videos upload",
      "Custom buttons",
      "Deeplink action",
      "Usage analytics",
      "1 replacement card warranty",
    ],
  },
];

const timeList = ["Monthly", "Annually"];

type SubscribeTableProps = {
  footer?: React.ReactNode;
};

const SubscribeTable: React.FC<SubscribeTableProps> = ({ footer }) => {
  const [currentTime, setCurrentTime] = useState<string>(timeList[0]);

  const unit = currentTime === "Monthly" ? "month" : "year";
  const calcPrice = (price: number) => {
    return currentTime === "Monthly" ? price : price * 10;
  };

  return (
    <div className={styles.Subscription}>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
        className={styles.title}
      >
        Subscription Plans
      </motion.div>
      <Tabs
        className={styles.tabs}
        primary
        fullWidth
        list={timeList}
        current={currentTime}
        setCurrent={setCurrentTime}
        ariaLabel="time"
        ariaControls="time"
      />
      <div
        className={classNames(
          styles.grid
          // , !footer ? styles.column : undefined
        )}
      >
        {planList.length
          ? planList.map((plan, index) => (
              <div className={styles.cardWrapper}>
                <motion.a
                  href="/subscription"
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8, delay: (index + 1) * 0.2 }}
                  viewport={{ once: true }}
                  key={`${plan.label}-${index}`}
                  className={styles.item}
                >
                  <div className={styles.label}>{plan.label}</div>
                  <motion.div
                    className={styles.price}
                    key={unit}
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <span className={styles.sign}>$</span>
                    <span className={styles.number}>
                      {calcPrice(plan.price)}
                    </span>
                    <span className={styles.unit}> / {unit}</span>
                  </motion.div>
                  <div className={styles.ul}>
                    {plan.details.map((desc, index) => (
                      <div key={index} className={styles.li}>
                        <CircleOutlinedIcon fontSize="small" />
                        {desc}
                      </div>
                    ))}
                  </div>
                </motion.a>
                {!footer ? (
                  <LinkButton
                    key={`${plan.label}-${index}-button`}
                    className={styles.try}
                    to="/checkout"
                  >
                    Try EVATAR {plan.label}
                  </LinkButton>
                ) : null}
              </div>
            ))
          : null}
      </div>
      {footer ? (
        footer
      ) : (
        <>
          {/* <div className={styles.grid}>
            {planList.length
              ? planList.map((plan, index) => (
                  <LinkButton
                    key={`${plan.label}-${index}-button`}
                    className={styles.try}
                    to="/checkout"
                  >
                    Try EVATAR {plan.label}
                  </LinkButton>
                ))
              : null}
          </div> */}
          <Link className={styles.about} to="/privacy">
            About Subscriptions and Privacy
          </Link>
        </>
      )}
    </div>
  );
};

export default SubscribeTable;
