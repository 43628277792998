import ShareIcon from "@mui/icons-material/Share";
import MessageIcon from "@mui/icons-material/Message";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Spline from "@splinetool/react-spline";
import { useAppDispatch } from "app/hooks";
import logo from "assets/img/evatar-logo-qrcode.svg";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import LogoLoading from "components/LogoLoading";
import Modal from "components/Modal";
import Placeholder from "components/Placeholder";
import dayjs from "dayjs";
import QRCodeStyling from "qr-code-styling";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIClient } from "services/api";
import { SOCIAL_LINKS } from "services/constants";
import { IProfileData } from "services/type";
import { logout } from "slices/userSlice";
import {
  capitalise,
  getGoogleMapLocation,
  getYouTubeID,
  hrefHttpCheck,
} from "utils";
import CONFIG from "utils/config";
import styles from "./style.module.scss";

interface ProfileProps {
  editMode?: boolean;
  cardDefaultProfile?: string;
  editData?: Partial<IProfileData>;
  editLoading?: boolean;
  currentImageUrl?: string;
  currentCompanyLogoUrl?: string;
  setDemoScroll?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    // eslint-disable-next-line
    [ref]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
    // eslint-disable-next-line
  }, []);

  return isIntersecting;
}

const Profile: React.FC<ProfileProps> = ({
  editMode,
  cardDefaultProfile,
  editData,
  editLoading,
  currentImageUrl,
  currentCompanyLogoUrl,
  setDemoScroll,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profileid = "" } = useParams();
  const [data, setData] = useState<Partial<IProfileData>>(editData ?? {});
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // console.log("currentImageUrl ===", currentImageUrl);
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await APIClient.Profile(
          cardDefaultProfile ? cardDefaultProfile : profileid
        );
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        // console.log("APIClient.Profile - data", data.data, status);
        setLoading(false);
        if (status === 200) {
          // console.log("set data", data.data.data);
          setData(data?.data?.data);
        }
      } catch (err: any) {
        setLoading(false);
        console.log("APIClient.Profile - catch error", err);
        if (err && err.response?.status === 401) {
          console.log("401 detected, logout now");
          dispatch(logout());
          navigate("/login", {
            replace: true,
          });
        }
      }
    };

    if (!editData) {
      fetchProfile();
    } else {
      setLoading(false);
    }
    setDemoScroll?.(true);
    // console.log("check edit data ---", isEmptyObject(editData ?? {}));
    // eslint-disable-next-line
  }, [cardDefaultProfile]);

  useEffect(() => {
    console.log("changes ... ", editData);
    setData(editData ?? {});
  }, [editData]);

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const qrCode = new QRCodeStyling({
    data: `https://${CONFIG.REACT_APP_APP_CLIENT_DOMAIN}/profile/${
      cardDefaultProfile ? cardDefaultProfile : profileid
    }`,
    width: 300,
    height: 300,
    image: logo,
    dotsOptions: {
      color: "white",
      type: "classy-rounded",
    },
    cornersDotOptions: {
      type: "dot",
    },
    cornersSquareOptions: {
      type: "extra-rounded",
    },
    imageOptions: {
      crossOrigin: "anonymous",
    },
    backgroundOptions: {
      color: "transparent",
    },
  });

  useEffect(() => {
    if (isVisible) {
      qrCode.append(ref.current!);
    }
    // console.log("=========check modalOpen=========");
    // eslint-disable-next-line
  }, [modalOpen]);

  return loading || editLoading ? (
    // <Box
    //   sx={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     minHeight: 300,
    //   }}
    // >
    //   <CircularProgress />
    // </Box>
    <LogoLoading hideBackdrop={!editMode} />
  ) : (
    <>
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message="Copied to Clipboard"
      />
      <div
        className={classNames(
          styles.Profile,
          editMode ? styles.editMode : undefined
        )}
      >
        <div className={styles.SplineBackground}>
          <Spline
            onLoad={(e) => setLoading(false)}
            scene="https://prod.spline.design/6QTG802nuDgxluGp/scene.splinecode"
          />
        </div>
        <ButtonBase
          className={styles.shareBtn}
          onClick={() => {
            setModalOpen(true);
            setDemoScroll?.(false);
            setTimeout(() => {
              qrCode.append(ref.current!);
            }, 100);
          }}
        >
          {/* <span className="ico ico-share"></span>
        Share */}
          <ShareIcon />
        </ButtonBase>
        {profileid.split("-").length > 1 ? (
          <ButtonBase
            className={styles.msgBtn}
            onClick={() => {
              window.open(
                `${CONFIG.REACT_APP_TTECH_URL}/website/im?idnfccar=${profileid
                  .split("-")
                  .slice(1)
                  .join("-")}`,
                "_blank"
              );
            }}
          >
            <MessageIcon />
          </ButtonBase>
        ) : null}

        <Modal
          // title="Share Profile"
          // {...(isEmptyObject(editData ?? {}) ? undefined : { demo: true })}
          {...(setDemoScroll ? { demo: true } : undefined)}
          glass
          contentClassName={styles.ProfileShare}
          open={modalOpen}
          handleClose={() => {
            setModalOpen(false);
            setDemoScroll?.(true);
          }}
          footer={null}
        >
          <div className={styles.modalName}>{data?.name}</div>
          <div className={styles.modalContainer}>
            <div className={classNames(styles.modalGlass, styles.fullbreed)}>
              <div ref={ref} />
            </div>
            <ButtonBase
              className={classNames(styles.modalGlass, styles.modalAction)}
              onClick={() =>
                navigator.share({
                  url: `https://${CONFIG.REACT_APP_APP_CLIENT_DOMAIN}/profile/${
                    cardDefaultProfile ? cardDefaultProfile : profileid
                  }`,
                  title: `${data?.name} - EVATAR`,
                  text: `Hello, this is ${data?.name}, please find my profile below:\nhttps://${CONFIG.REACT_APP_APP_CLIENT_DOMAIN}/profile/${profileid}`,
                })
              }
            >
              <span className="ico ico-share"></span>Share profile
            </ButtonBase>
            <ButtonBase
              className={classNames(styles.modalGlass, styles.modalAction)}
              onClick={() => {
                console.log("cardDefaultProfile:", cardDefaultProfile);
                console.log("profileid:", profileid);
                navigator.clipboard.writeText(
                  `https://${CONFIG.REACT_APP_APP_CLIENT_DOMAIN}/profile/${
                    cardDefaultProfile ? cardDefaultProfile : profileid
                  }`
                );
                setSnackbarOpen(true);
              }}
            >
              <span className="ico ico-url"></span>Copy link
            </ButtonBase>
          </div>
        </Modal>

        <div className={styles.profileInfo}>
          <div className={styles.propic}>
            {currentCompanyLogoUrl || data?.companyLogoUrl ? (
              <Placeholder
                className={styles.companyIcon}
                src={editData ? currentCompanyLogoUrl : data?.companyLogoUrl}
                centered
                x={200}
                y={200}
              />
            ) : null}
            <Placeholder
              className={styles.placeholder}
              {...(editData
                ? currentImageUrl
                  ? { src: currentImageUrl }
                  : {
                      component: (
                        <div className={styles.defaultIcon}>
                          <span className="ico ico-evatar"></span>
                        </div>
                      ),
                    }
                : data?.iconUrl
                ? { src: data?.iconUrl }
                : {
                    component: (
                      <div className={styles.defaultIcon}>
                        <span className="ico ico-evatar"></span>
                      </div>
                    ),
                  })}
              centered
              x={400}
              y={640}
            />
            <div className={styles.infos}>
              <div className={styles.basicInfo}>
                <div className={styles.name}>{data?.name}</div>
                <div>{data?.jobTitle}</div>
                <div>{data?.company}</div>
              </div>
              <div className={styles.contactInfo}>
                {data?.contacts
                  ? data?.contacts.map((item: any, index: number) => {
                      const type = item.type?.toLowerCase();
                      let href = "";
                      let alt = "";
                      switch (type) {
                        case "contact":
                          href = item.data?.url ?? "";
                          alt = `Open the contact of ${data?.name}`;
                          break;
                        case "phone":
                          href = `tel:${item.data}`;
                          alt = `Call ${data?.name}`;
                          break;
                        case "email":
                          href = `mailto:${item.data}`;
                          alt = `Send an email to ${data?.name}`;
                          break;
                        case "whatsapp":
                          href = `https://api.whatsapp.com/send?phone=${
                            item.data.phoneNumber
                          }${
                            item.data.text
                              ? `&text=${item.data.text}%0a%0ahttps://${
                                  CONFIG.REACT_APP_APP_CLIENT_DOMAIN
                                }/profile/${
                                  cardDefaultProfile
                                    ? cardDefaultProfile
                                    : profileid
                                }`
                              : ``
                          }`;
                          alt = `Send a Whatsapp message to ${data?.name}`;
                          break;
                        case "location":
                          href = getGoogleMapLocation(item.data);
                          alt = `Open location of ${data?.name}`;
                          break;
                        case "sms":
                          href = `sms:${item.data}`;
                          alt = `Send an SMS to ${data?.name}`;
                          break;
                      }
                      return (
                        <IconButton
                          key={`${item.type?.toLowerCase()}-${index}`}
                          href={href}
                          target="_blank"
                          className={styles.contactIcon}
                        >
                          <span className={`ico ico-${type}`}></span>
                          <span className="sr-only">{alt}</span>
                        </IconButton>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>

        {data?.sections
          ? data?.sections.map((section: any, index) => {
              let content: React.ReactNode = null;
              switch (section.type) {
                case "HEADER_TEXT":
                  content = (
                    <div className={styles.customInfo}>
                      <div className={styles.head}>{section.data?.header}</div>
                      <div className={styles.desc}>{section.data?.text}</div>
                    </div>
                  );
                  break;
                case "VIDEO":
                  content = (
                    <div className={styles.customInfo}>
                      <div className="embedded-video">
                        <iframe
                          src={`https://www.youtube.com/embed/${getYouTubeID(
                            section.data?.videoUrl
                          )}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        />
                      </div>
                      <div className={styles.subhead}>
                        {section.data?.title}
                      </div>
                      <div className={styles.desc}>
                        {section.data?.description}
                      </div>
                    </div>
                  );
                  break;
                case "WORK_EXPERIENCE":
                  content = (
                    <div
                      className={classNames(styles.customInfo, styles.narrow)}
                    >
                      <div className={styles.head}>
                        {section.data?.jobTitle}
                      </div>
                      <div className={styles.desc}>
                        <i>{section.data?.company}</i>
                      </div>
                      <div className={styles.date}>
                        {dayjs(section.data?.from, "DD-MM-YYYY").format(
                          "D MMM YYYY"
                        )}
                        <span>–</span>
                        {section.data?.to === dayjs().format("DD-MM-YYYY")
                          ? "Now"
                          : dayjs(section.data?.to, "DD-MM-YYYY").format(
                              "D MMM YYYY"
                            ) ?? "Now"}
                      </div>
                      <div className={styles.desc}>
                        {section.data?.description}
                      </div>
                    </div>
                  );
                  break;
                case "LINKS":
                  content = (
                    <a
                      className={styles.socialInfo}
                      href={hrefHttpCheck(section.data?.url)}
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Go to link"
                    >
                      <div className={styles.left}>
                        {section.data?.iconUrl ? (
                          <Placeholder
                            className={styles.icon}
                            src={section.data?.iconUrl}
                            centered
                            circle
                          />
                        ) : (
                          <Placeholder
                            className={classNames(styles.icon, styles.default)}
                            component={
                              <div className={styles.linkIcon}>
                                <span className="ico ico-url"></span>
                              </div>
                            }
                            centered
                            circle
                          />
                        )}
                        <div className={styles.media}>
                          <div className={styles.mediaName}>
                            {section.data?.title}
                          </div>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <span className="ico ico-arrow-right"></span>
                      </div>
                    </a>
                  );
                  break;
                case "SOCIAL_LINKS":
                  const icon = SOCIAL_LINKS.filter(
                    (item) => item.id === section.data?.type
                  )[0].data.iconUrl;
                  content = (
                    <a
                      className={styles.socialInfo}
                      href={hrefHttpCheck(section.data?.url)}
                      target="_blank"
                      rel="noreferrer"
                      aria-label={`Go to ${capitalise(section.data?.type)}`}
                    >
                      <div className={styles.left}>
                        <Placeholder
                          className={styles.icon}
                          src={icon}
                          centered
                          circle
                          rounded
                        />
                        <div className={styles.media}>
                          <div className={styles.mediaName}>
                            {capitalise(section.data?.type)}
                          </div>
                          <div className={styles.mediaLabel}>
                            {section.data?.label}
                          </div>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <span className="ico ico-arrow-right"></span>
                      </div>
                    </a>
                  );
                  break;
                default:
                  break;
              }
              return <div key={`${section.type}-${index}`}>{content}</div>;
            })
          : null}
      </div>
    </>
  );
};

export default Profile;
