import classNames from "classnames";
import { LinkButton } from "components/Button";
import { motion } from "framer-motion";
import React from "react";
import styles from "./style.module.scss";

interface ProductBannerProps {
  secondary?: boolean;
}

const ContactBanner: React.FC<ProductBannerProps> = ({ secondary }) => {
  return (
    <section
      className={classNames(
        styles.Bottom,
        secondary ? styles.secondary : undefined
      )}
    >
      <div className="container">
        <div className={styles.card}>
          <motion.h6
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              duration: 0.8,
            }}
            viewport={{ once: true }}
          >
            Want cards for your team?
          </motion.h6>
          <LinkButton to="/contactus" className={styles.button}>
            Contact Us
          </LinkButton>
        </div>
      </div>
    </section>
  );
};
// );

export default ContactBanner;
