import { useAppDispatch, useAppSelector } from "app/hooks";
import { HttpStatusCode } from "axios";
import Button from "components/Button";
import LogoLoading from "components/LogoLoading";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { APIClient } from "services/api";
import { logout, setUser } from "slices/userSlice";
import styles from "../login/style.module.scss";

const SignUpSuccess: React.FC = () => {
  const verified = useAppSelector((state) => state.user.verified);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountEmail = useAppSelector((state) => state.user.user?.email);
  const [email, setEmail] = useState<string>(location.state?.email);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchResend = async () => {
    try {
      const res = await APIClient.ResendVerify();
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      console.log("APIClient.ResendVerify - data", data, status);
    } catch (err: any) {
      console.log("APIClient.ResendVerify - catch error", err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  const fetchAuthGet = async (resend?: boolean) => {
    try {
      const res = await APIClient.AuthGet();
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      console.log("APIClient.AuthGet - data", data, status);
      setLoading(false);
      dispatch(setUser(data.data));
      if (resend) {
        if (data.data.status === "NOT_VERIFIED") {
          fetchResend();
        } else {
          navigate("/", {
            replace: true,
          });
        }
      }
    } catch (err: any) {
      console.log("APIClient.AuthGet - catch error", err);
      setLoading(false);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    fetchAuthGet();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEmail(accountEmail ?? "");
  }, [accountEmail]);

  const resendLink = () => {
    fetchAuthGet(true);
  };

  const relogin = () => {
    dispatch(logout());
    navigate("/login", {
      replace: true,
    });
  };

  if (verified) {
    return <Navigate to="/dashboard/cards" replace />;
  }

  return (
    <section className={styles.Login}>
      <div className="container">
        {loading ? (
          // <CircularProgress />
          <LogoLoading />
        ) : (
          <>
            <div className={styles.title}>Verify your account</div>
            <div className={styles.intro}>
              <p>To complete your profile please verify your email address.</p>
              <p>
                If you use Outlook, the verification email may be in your junk
                folder.
              </p>
              <p>Your verification link has been sent to the below email:</p>
            </div>
            <p className={styles.semibold}>{email}</p>
            <Button small className={styles.button} onClick={resendLink}>
              Resend Link
            </Button>
            <button className={styles.link} onClick={relogin}>
              Sign in with another account
            </button>
          </>
        )}
      </div>
    </section>
  );
};

export default SignUpSuccess;
