import Tab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import classNames from "classnames";
import { motion } from "framer-motion";

type TabsProps = {
  className?: string;
  primary?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  centered?: boolean;
  disableTouchRipple?: boolean;
  ariaLabel?: string;
  ariaControls?: string;
  list: any[];
  current: string;
  setCurrent: React.Dispatch<React.SetStateAction<string>>;
};

const Tabs: React.FC<TabsProps> = ({
  className,
  primary = false,
  secondary = false,
  fullWidth = false,
  centered = false,
  disableTouchRipple = false,
  list = [],
  ariaLabel = "label",
  ariaControls = "controls",
  current,
  setCurrent,
}) => {
  return list.length ? (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <MuiTabs
        className={classNames(
          className ? className : undefined,
          primary ? "PrimaryTabs" : undefined,
          secondary ? "SecondaryTabs" : undefined
        )}
        {...(fullWidth && { variant: "fullWidth" })}
        {...(centered && { centered: true })}
        value={current}
        onChange={(event: React.SyntheticEvent, newValue: string) => {
          setCurrent(newValue);
        }}
        aria-label={`${ariaLabel}-tab`}
      >
        {list.map((item, index) => (
          <Tab
            {...(disableTouchRipple && { disableTouchRipple: true })}
            key={index}
            value={item}
            label={item}
            aria-controls={`${ariaControls}-${index}`}
          ></Tab>
        ))}
      </MuiTabs>
    </motion.div>
  ) : null;
};

export default Tabs;
