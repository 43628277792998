import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import cartReducer, { calcTotal } from "slices/cartSlice";
import userReducer from "slices/userSlice";
import editReducer from "slices/editSlice";
import UIReducer from "slices/UISlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    shoppingcart: cartReducer,
    edit: editReducer,
    sharedUI: UIReducer,
  },
});

store.dispatch(calcTotal());

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
