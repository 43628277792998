import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
// import img from "assets/img/dummy-image.png";

export interface UIState {
  snapInView: boolean;
}

const initialState: UIState = {
  snapInView: true,
};

export const UISlice = createSlice({
  name: "sharedUI",
  initialState,
  reducers: {
    setSnapInView: (state, action) => {
      state.snapInView = action.payload;
    },
  },
});

export const { setSnapInView } = UISlice.actions;
export const selectSnapInView = (state: RootState) => state.sharedUI.snapInView;

export default UISlice.reducer;
