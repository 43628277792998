import CloseIcon from "@mui/icons-material/Close";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import MuiModal from "@mui/material/Modal";
import classNames from "classnames";
import Button from "components/Button";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./style.module.scss";

type CustomModalProps = {
  className?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  title?: string;
  fullScreen?: boolean;
  footer?: React.ReactNode | null;
  glass?: boolean;
  demo?: boolean;
  okayText?: string;
  okayAction?: () => void;
  awaitLoading?: boolean;
};

const Modal: React.FC<CustomModalProps> = ({
  className,
  contentClassName,
  children,
  open,
  handleClose,
  title,
  fullScreen,
  footer,
  glass,
  demo,
  okayText,
  okayAction,
  awaitLoading,
}) => {
  return (
    <MuiModal
      className={styles.ModalWrapper}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      {...(demo
        ? {
            disablePortal: true,
            disableScrollLock: true,
          }
        : undefined)}
    >
      <div>
        <AnimatePresence mode="wait">
          <motion.div
            {...(glass
              ? {
                  initial: {
                    y: "100%",
                    opacity: 0,
                  },
                  animate: {
                    y: 0,
                    opacity: 1,
                  },
                }
              : {
                  initial: { marginTop: 20, opacity: 0 },
                  whileInView: { marginTop: 0, opacity: 1 },
                  viewport: { once: true },
                })}
            transition={{
              duration: 0.6,
            }}
            className={classNames(
              styles.Modal,
              styles.basic,
              fullScreen ? styles.fullScreen : undefined,
              glass ? styles.glass : undefined,
              className ? className : undefined
            )}
          >
            <div className={classNames(styles.modalCard)}>
              {title ? (
                <div className={styles.head}>
                  <p className={styles.title}>{title}</p>
                  <IconButton className={styles.close} onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              ) : (
                <IconButton className={styles.close} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              )}
              <div
                className={classNames(
                  styles.content,
                  contentClassName ? contentClassName : undefined
                )}
              >
                {children}
              </div>
              {footer || footer === null ? (
                footer
              ) : (
                <div className={styles.foot}>
                  <Button small onClick={okayAction ? okayAction : handleClose}>
                    {awaitLoading ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : okayText ? (
                      okayText
                    ) : (
                      "Done"
                    )}
                  </Button>
                </div>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </MuiModal>
  );
};

export default Modal;

type ConfirmModalProps = {
  className?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  remarks?: string;
  okayText?: string;
  okayAction?: () => void;
  cancelText?: string;
  cancelAction?: () => void;
  danger?: boolean;
  singleOption?: boolean;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  className,
  open,
  setOpen,
  title,
  remarks,
  okayText,
  okayAction,
  cancelText,
  cancelAction,
  danger,
  singleOption,
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <MuiModal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <motion.div
        initial={{ marginTop: 20, opacity: 0 }}
        whileInView={{ marginTop: 0, opacity: 1 }}
        exit={{ marginTop: 20, opacity: 0 }}
        transition={{
          duration: 0.6,
        }}
        className={classNames(
          styles.Modal,
          styles.confirm,
          className ? className : undefined
        )}
      >
        <div className={styles.modalCard}>
          <div className={styles.text}>
            <p className={styles.title}>{title}</p>
            {remarks ? <p className={styles.remarks}>{remarks}</p> : null}
          </div>

          {singleOption ? null : (
            <ButtonBase
              className={classNames(
                styles.button,
                danger ? styles.subdanger : undefined
              )}
              onClick={cancelAction ? cancelAction : handleClose}
            >
              {cancelText ? cancelText : "Cancel"}
            </ButtonBase>
          )}
          <ButtonBase
            className={classNames(
              styles.button,
              styles.theme,
              danger ? styles.danger : undefined
            )}
            onClick={okayAction ? okayAction : handleClose}
          >
            {okayText ? okayText : "Confirm"}
          </ButtonBase>
        </div>
      </motion.div>
    </MuiModal>
  );
};
