import { LinkButton } from "components/Button";
import Placeholder from "components/Placeholder";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

import feature1x from "assets/img/products/static-demo.png";
// import feature1x from "assets/img/feature.png";
// import feature2x from "assets/img/feature@2x.png";
// import nfcBand1x from "assets/img/products/product-band.png";
// import nfcBand2x from "assets/img/products/product-band@2x.png";
// import nfcCard1x from "assets/img/products/product-card.png";
// import nfcCard2x from "assets/img/products/product-card@2x.png";
// import nfcTag1x from "assets/img/products/product-tag.png";
// import nfcTag2x from "assets/img/products/product-tag@2x.png";

import sponsor1 from "assets/img/sponsors/sponsor1.png";
import sponsor2 from "assets/img/sponsors/sponsor2.png";
import sponsor3 from "assets/img/sponsors/sponsor3.png";
import sponsor4 from "assets/img/sponsors/sponsor4.png";
// import sponsor5 from "assets/img/sponsors/sponsor5.png";
import Skeleton from "@mui/material/Skeleton";
import sponsor6 from "assets/img/sponsors/sponsor6.png";
import { HttpStatusCode } from "axios";
import { APIClient } from "services/api";
import { stepList } from "services/constants";
import { IProduct } from "services/type";

// const productList = [
//   {
//     price: "$800",
//     label: "Cards",
//     src: nfcCard1x,
//     src2x: nfcCard2x,
//     subline: "The last business card you’ll ever need",
//     desc: "Our quickest card to process and ship. EVATAR card that lets you make new connections with just a single tap.",
//   },
//   {
//     price: "$500",
//     label: "Tag",
//     src: nfcTag1x,
//     src2x: nfcTag2x,
//     subline: "A small tag makes an impression",
//     desc: "Your name, your logo and job title on a sleek and stylish NFC EVATAR Tag. Available in metal, PVC and bamboo.",
//   },
//   {
//     price: "$500",
//     label: "Band",
//     src: nfcBand1x,
//     src2x: nfcBand2x,
//     subline: "One band, just tap",
//     desc: "A tappable NFC band, anyway you like it. We send you the template and you use design software to make your stylish band.",
//   },
// ];

const businessList = [
  "Customization on Logo and Name",
  "Professional image for your business",
  "Environmentally friendly and can be easily updated",
  "Showcasing a commitment to innovation and technology",
  "Ensure consistency in branding across the team",
];

const marqueeList = [
  {
    alt: "dummy",
    src: sponsor1,
  },
  {
    alt: "dummy",
    src: sponsor2,
  },
  {
    alt: "dummy",
    src: sponsor3,
  },
  {
    alt: "dummy",
    src: sponsor4,
  },
  // {
  //   alt: "dummy",
  //   src: sponsor5,
  // },
  {
    alt: "dummy",
    src: sponsor6,
  },
];

const Product: React.FC = () => {
  const [productList, setProductList] = useState<IProduct[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await APIClient.ProductList();
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        // setLoading(false);
        // console.log("APIClient.ProductList - data", data.data, status);
        if (status === 200) {
          console.log("product data", data.data);
          setProductList(data.data);
        }
      } catch (err: any) {
        // setLoading(false);
        console.log("APIClient.ProductList - catch error", err);
      }
    };

    fetchProducts();
  }, []);

  return (
    <>
      <section className={styles.Product}>
        <div className="container">
          <motion.h4
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.8,
            }}
            viewport={{ once: true }}
          >
            Choose your products
          </motion.h4>
          <div className={styles.grid}>
            {productList.length ? (
              productList.map((product, index) => {
                const display = product.display.filter(
                  (product) => product.locale === "EN"
                )[0];
                const correspondingCurrencyPrice = product.data.prices.filter(
                  (product) => product.currency === "HKD"
                )[0];

                const onSales =
                  correspondingCurrencyPrice.finalPrice !==
                  correspondingCurrencyPrice.price;

                return (
                  <Link
                    to={`/product/${product.id}`}
                    key={index}
                    state={{ product: product }}
                  >
                    <motion.div
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: index * 0.2,
                      }}
                      viewport={{ once: true }}
                      className={styles.item}
                    >
                      <div className={styles.price}>
                        HK$
                        {onSales
                          ? correspondingCurrencyPrice.price
                          : correspondingCurrencyPrice.finalPrice}
                        {onSales ? (
                          <div className={styles.price}>
                            {correspondingCurrencyPrice.finalPrice}
                          </div>
                        ) : null}
                      </div>
                      {/* <div className={styles.remark}>+ shipping</div> */}
                      <Placeholder
                        className={styles.image}
                        src={product.data?.iconUrl}
                        x={268}
                        y={169}
                        centered
                        contained
                        alt={display.value}
                      />
                      <div className={styles.label}>{display.value}</div>
                      <div className={styles.desc}>{display.material}</div>
                      <div className={styles.subline}>{display.title}</div>
                    </motion.div>
                  </Link>
                );
              })
            ) : (
              <>
                <div className={styles.item}>
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ fontSize: "1rem", marginBottom: "12px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={169}
                    sx={{ marginBottom: "20px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1.5rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                </div>

                <div className={styles.item}>
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ fontSize: "1rem", marginBottom: "12px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={169}
                    sx={{ marginBottom: "20px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1.5rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                </div>

                <div className={styles.item}>
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ fontSize: "1rem", marginBottom: "12px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width={"100%"}
                    height={169}
                    sx={{ marginBottom: "20px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1.5rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={100}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={"100%"}
                    sx={{ fontSize: "1rem" }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <section className={styles.Step}>
        <div className="container">
          <h3>Go contactless with EVATAR</h3>
          <div className={styles.grid}>
            {stepList.length
              ? stepList.map((step, index) => (
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{
                      duration: 0.8,
                      delay: index * 0.2,
                    }}
                    viewport={{ once: true }}
                    key={index}
                    className={styles.step}
                  >
                    <Placeholder
                      className={styles.image}
                      src={step.src}
                      centered
                      x={1}
                      y={1}
                    />
                    <div className={styles.label}>{step.label}</div>
                    <p className={styles.desc}>{step.desc}</p>
                  </motion.div>
                ))
              : null}
          </div>
          {/* <LinkButton to="/product" className={styles.button} small>
            Get EVATAR Card
          </LinkButton> */}
        </div>
      </section>

      <section className={styles.Business}>
        <div className="container">
          <Placeholder
            className={styles.image}
            src={feature1x}
            // src2x={feature2x}
            x={551}
            y={670}
            centered
          />
          <div className={styles.content}>
            <motion.h4
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.8,
              }}
              viewport={{ once: true }}
            >
              EVATAR CARD FOR YOUR BRAND
            </motion.h4>
            <ul>
              {businessList.length
                ? businessList.map((item, index) => (
                    <motion.li
                      initial={{ y: 50, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{
                        duration: 0.8,
                        delay: (index + 1) * 0.2,
                      }}
                      viewport={{ once: true }}
                      key={index}
                    >
                      <span>{item}</span>
                    </motion.li>
                  ))
                : null}
            </ul>
            <LinkButton to="/contactus" className={styles.button} small>
              Contact Us
            </LinkButton>
          </div>
        </div>
      </section>

      {/* <ContactBanner /> */}

      <section className={styles.Partner}>
        <div className="container">
          <div className={styles.slogan}>OUR CLIENTS</div>
          <div className={styles.marquee}>
            <Marquee play={true} speed={30} gradient={false}>
              {marqueeList.length ? (
                <>
                  {marqueeList.map((item, index) => (
                    <Placeholder
                      key={index}
                      className={styles.image}
                      src={item.src}
                      alt={item.alt}
                      x={152}
                      y={58}
                      centered
                    />
                  ))}
                  {marqueeList.map((item, index) => (
                    <Placeholder
                      key={index}
                      className={styles.image}
                      src={item.src}
                      alt={item.alt}
                      x={152}
                      y={58}
                      centered
                    />
                  ))}
                </>
              ) : null}
            </Marquee>
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;
