import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import HomeIcon from "@mui/icons-material/Home";
import LoginIcon from "@mui/icons-material/Login";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import styles from "../style.module.scss";

export interface navItem {
  label: string;
  path: string;
  icon: any;
  end?: boolean;
}

export const navList: navItem[] = [
  {
    label: "Home",
    icon: <HomeIcon />,
    path: "/",
    end: true,
  },
  {
    label: "Product",
    icon: <StorefrontIcon />,
    path: "/product",
  },
  // {
  //   label: "Subscription",
  //   path: "/subscription",
  // },
  {
    label: "Contact Us",
    icon: <ContactSupportIcon />,
    path: "/contactus",
  },
];

export const responsiveNavList: navItem[] = [
  {
    label: "Home",
    icon: <HomeIcon />,
    path: "/",
    end: true,
  },
  {
    label: "Product",
    icon: <StorefrontIcon />,
    path: "/product",
  },
  // {
  //   label: "Subscription",
  //   path: "/subscription",
  // },
  {
    label: "Contact Us",
    icon: <ContactSupportIcon />,
    path: "/contactus",
  },
  {
    label: "Login",
    icon: <LoginIcon />,
    path: "/login",
  },
  {
    label: "Sign Up",
    icon: <AssignmentIndIcon />,
    path: "/signup",
  },
];

export const dashboardNav: navItem[] = [
  // {
  //   label: "Cards",
  //   path: "/dashboard/cards",
  // },
  // {
  //   label: "Subscriptions",
  //   path: "/dashboard/subscriptions",
  // },
  // {
  //   label: "Analytics",
  //   path: "/dashboard/analytics",
  // },
  // {
  //   label: "Settings",
  //   path: "/dashboard/settings",
  // },
];
type navigationProps = {
  menu: navItem[];
};

const Navigation: React.FC<navigationProps> = ({ menu }) => {
  return menu.length ? (
    <ul className={styles.menu}>
      {menu.map((item, index) => (
        <motion.li
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.8,
            delay: (index + 1) * 0.2,
          }}
          viewport={{ once: true }}
          key={index}
        >
          <NavLink className={styles.link} end={item.end} to={item.path}>
            {item.label}
          </NavLink>
        </motion.li>
      ))}
    </ul>
  ) : null;
};

export default Navigation;
