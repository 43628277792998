import ButtonBase from "@mui/material/ButtonBase";
import classNames from "classnames";
import React from "react";
import styles from "./style.module.scss";

export interface InfoBoxRowType {
  label: string;
  value: React.ReactNode | string;
}

type InfoBoxProps = {
  className?: string;
  title?: string;
  list: InfoBoxRowType[];
  button?: string;
  action?: () => void;
  summary?: boolean;
};

const InfoBox: React.FC<InfoBoxProps> = ({
  className,
  title,
  list = [],
  button,
  action,
  summary,
}) => {
  return (
    <>
      {title ? <div className={styles.title}>{title}</div> : null}
      <div
        className={classNames(
          styles.InfoBox,
          className ? className : undefined
        )}
      >
        <div className={styles.content}>
          {list?.length
            ? list.map((item, index) => (
                <div className={styles.field} key={index}>
                  <div {...(summary && { className: styles.strong })}>
                    {item.label}
                  </div>
                  <div className={styles.value}>{item.value}</div>
                </div>
              ))
            : null}
        </div>
        {button ? (
          <div className={styles.action}>
            <ButtonBase
              className={styles.change}
              {...(action && { onClick: action })}
            >
              {button}
            </ButtonBase>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default InfoBox;
