import { useAppDispatch } from "app/hooks";
import "assets/styles/style.scss";
import { HttpStatusCode } from "axios";
import { useEffect } from "react";
import { APIClient } from "services/api";
import { setProductList } from "slices/cartSlice";
import Routers from "./routers";

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const res = await APIClient.SessionInit();
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        if (status === 200) {
          console.log("data", data?.data);
        }
      } catch (err: any) {
        console.log("APIClient.Login return error ---", err);
      }
    };
    const fetchProducts = async () => {
      try {
        const res = await APIClient.ProductList();
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        // setLoading(false);
        // console.log("APIClient.ProductList - data", data.data, status);
        if (status === 200) {
          console.log("product data", data.data);
          dispatch(setProductList(data.data));
        }
      } catch (err: any) {
        // setLoading(false);
        console.log("APIClient.ProductList - catch error", err);
      }
    };

    fetchSession();
    fetchProducts();
    // eslint-disable-next-line
  }, []);

  return <Routers />;
};

export default App;
