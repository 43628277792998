import ButtonBase from "@mui/material/ButtonBase";
import { ProfileCard } from "components/Card";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import Paper from "@mui/material/Paper";
// import qrcode from "assets/img/dummy-qrcode.svg";
// import Placeholder from "components/Placeholder";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAppDispatch } from "app/hooks";
import { HttpStatusCode } from "axios";
import LogoLoading from "components/LogoLoading";
import Modal from "components/Modal";
import { motion } from "framer-motion";
import SubscribeTable from "pages/subscription/SubscribeTable";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIClient } from "services/api";
import { IProfile } from "services/type";
import { logout } from "slices/userSlice";
import styles from "../style.module.scss";

const CardProfiles: React.FC = () => {
  const { cardid = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [profileList, setProfileList] = useState<IProfile[]>([]);
  const [defaultProfile, setDefaultProfile] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchProfileList = async () => {
      try {
        const res = await APIClient.ProfileList(cardid);
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        console.log("APIClient.ProfileList - data", data.data, status);
        setLoading(false);
        if (status === 200) {
          setProfileList(data.data.profiles);
          setDefaultProfile(data.data.card.defaultProfileId);
        }
      } catch (err: any) {
        setLoading(false);
        console.log("APIClient.ProfileList - catch error", err);
        if (err && err.response?.status === 401) {
          console.log("401 detected, logout now");
          dispatch(logout());
          navigate("/login", {
            replace: true,
          });
        }
      }
    };

    fetchProfileList();
    // eslint-disable-next-line
  }, [cardid]);

  return (
    <section className={styles.Cards}>
      <div className="container">
        <div className={styles.header}>
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.6,
            }}
            viewport={{ once: true }}
          >
            <ButtonBase
              disableRipple
              className={styles.previous}
              component={Link}
              to="/dashboard/cards"
            >
              {/* <span className="ico ico-pointer-strong"></span> */}
              <ArrowBackIosNewIcon />
              Profiles
            </ButtonBase>
          </motion.div>
        </div>

        {loading ? (
          // <Box
          //   sx={{
          //     display: "flex",
          //     justifyContent: "center",
          //     alignItems: "center",
          //     minHeight: 300,
          //   }}
          // >
          //   <CircularProgress />
          // </Box>
          <LogoLoading />
        ) : (
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.2,
            }}
            viewport={{ once: true }}
            className={styles.content}
          >
            {profileList.length
              ? profileList.map((item, index) => (
                  <ProfileCard
                    // className={styles.profile}
                    key={item.id}
                    index={index}
                    id={item.id}
                    cardId={cardid}
                    img={item.data?.iconUrl ?? ""}
                    name={item.profileName}
                    setDefaultProfile={setDefaultProfile}
                    // data={item.data}
                    {...{
                      isDefault: item.id === defaultProfile,
                    }}
                  />
                ))
              : null}
            {/* <ButtonBase
              className={styles.empty}
              onClick={() => setModalOpen(true)}
            >
              <div className={styles.add}>
                <AddIcon />
                <span>Add Profile</span>
              </div>
            </ButtonBase> */}
            <Modal
              fullScreen
              footer={null}
              open={modalOpen}
              handleClose={() => {
                setModalOpen(false);
              }}
            >
              <SubscribeTable footer={null} />
            </Modal>
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default CardProfiles;
