// import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ButtonBase from "@mui/material/ButtonBase";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { HttpStatusCode } from "axios";
import { DashboardCard } from "components/Card";
import LogoLoading from "components/LogoLoading";
import Modal from "components/Modal";
import { motion } from "framer-motion";
import Activate from "pages/activate";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIClient } from "services/api";
import { ICard } from "services/type";
import { selectProductList } from "slices/cartSlice";
import { logout } from "slices/userSlice";
import styles from "../style.module.scss";

const Cards: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const productList = useAppSelector(selectProductList);

  const [cardList, setCardList] = useState<ICard[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activateModalOpen, setActivateModalOpen] = useState(false);

  useEffect(() => {
    const fetchCardList = async () => {
      try {
        const res = await APIClient.CardList();
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        console.log("APIClient.CardList - data", data.data, status);
        setLoading(false);
        if (status === 200) {
          setCardList(data.data);
        }
      } catch (err: any) {
        setLoading(false);
        console.log("APIClient.CardList - catch error", err);
        if (err && err.response?.status === 401) {
          console.log("401 detected, logout now");
          dispatch(logout());
          navigate("/login", {
            replace: true,
          });
        }
      }
    };
    fetchCardList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className={styles.Cards}>
        <div className="container">
          {/* <div className={styles.header}>
            <SearchBar className={styles.search} />
            <IconButton className={styles.add}>
              <AddIcon />
              <span className="sr-only">Add Card</span>
            </IconButton>
          </div> */}
          <div className={styles.header}>
            <motion.div
              initial={{ y: 10, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.6,
              }}
              viewport={{ once: true }}
            >
              <div className={styles.previous}>Cards</div>
            </motion.div>
          </div>
          {loading ? (
            <LogoLoading />
          ) : (
            <div className={styles.content}>
              {cardList
                ? cardList.map((card, index) => {
                    console.log("map cartList --- ", card);
                    const img = productList.filter(
                      (product) => product.id === card.productId
                    )?.[0]?.data?.iconUrl;
                    return (
                      <DashboardCard
                        key={card.id}
                        index={index}
                        id={card.id}
                        name={card.cardName}
                        img={img ?? ""}
                        defaultProfileId={card.defaultProfileId}
                        to="cards"
                      />
                    );
                  })
                : null}

              <ButtonBase
                className={styles.empty}
                onClick={() => setActivateModalOpen(true)}
                // onClick={() => navigate("/activate")}
              >
                <div className={styles.add}>
                  <AddIcon />
                  <span>Activate Card</span>
                </div>
              </ButtonBase>

              <Modal
                title="Activate card"
                open={activateModalOpen}
                footer={null}
                handleClose={() => {
                  setActivateModalOpen(false);
                }}
              >
                <Activate />
              </Modal>
              {/* <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{
                  duration: 0.6,
                  delay: cardList.length * 0.1,
                }}
                viewport={{ once: true }}
                className={styles.remarks}
              >
                Click any part of the card to start editing or add new fields
                using the buttons below. No changes to the card will be
                persisted until you click the Save Changes button.
              </motion.div> */}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Cards;
