import logo from "assets/img/evatar-logo.svg";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import Navigation, { navList } from "../header/navigation";
import styles from "./style.module.scss";

const socialList = [
  {
    icon: "twitter",
    url: "https://twitter.com",
  },
  {
    icon: "instagram",
    url: "https://www.instagram.com",
  },
  {
    icon: "facebook",
    url: "https://www.facebook.com",
  },
];

const Footer: React.FC = () => {
  return (
    <footer className={styles.Footer}>
      <div className="container">
        <Link className={styles.logo} to="/" aria-label="Back to Homepage">
          <img src={logo} alt="" />
          <p className={styles.copyright}>
            © 2023 Unox Limited. All Rights Reserved.
          </p>
        </Link>
        <div className={styles.center}>
          <Navigation menu={navList} />
        </div>
        <div className={styles.social}>
          {socialList.length
            ? socialList.map((item, index) => (
                <motion.a
                  initial={{ y: 20, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 0.8,
                    delay: (index + 1) * 0.2,
                  }}
                  viewport={{ once: true }}
                  key={index}
                  className={styles.circle}
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  aria-label={`Visit our ${item.icon}`}
                >
                  <span className={`ico ico-${item.icon}`}></span>
                </motion.a>
              ))
            : null}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
