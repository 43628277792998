import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useAppDispatch } from "app/hooks";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import Button from "components/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { APIClient } from "services/api";
import { ILoginResponse } from "services/type";
import { login, logout } from "slices/userSlice";
import styles from "../login/style.module.scss";

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const payload = {
    username: username,
    email: email,
    password: password,
  };

  const fetchLogin = async (email: string) => {
    try {
      const res = await APIClient.Login(payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      const loginRes: ILoginResponse = data?.data;
      if (status === 200) {
        dispatch(login(loginRes.jwt));
        setLoading(false);
        navigate("/signup/success", {
          state: { email: email },
          replace: true,
        });
      }
    } catch (err: any) {
      setLoading(false);
      console.log("APIClient.Login return error ---", err);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  const fetchSignUp = async () => {
    setLoading(true);
    try {
      const res = await APIClient.SignUp(payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // const tokens: ILoginResponse = data?.data;
      if (status === 200) {
        if (data) {
          fetchLogin(email);
        }
      }
    } catch (err: any) {
      setLoading(false);
      console.log("APIClient.SignUp - catch error");
      if (err && err.response?.status === 595) {
        console.log("Email existed");
      }
    }
  };

  return (
    <section className={styles.Login}>
      <div className="container">
        <div className={styles.title}>Sign Up</div>
        <div className={classNames(styles.intro, styles.fullwidth)}>
          <TextField
            className="MuiInput"
            label="Nickname"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            className="MuiInput"
            label="Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            className="MuiInput"
            label="Password"
            type={passwordVisible ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {passwordVisible ? (
                    <VisibilityOutlinedIcon
                      onClick={() => setPasswordVisible((boolean) => !boolean)}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      onClick={() => setPasswordVisible((boolean) => !boolean)}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button
          small
          className={classNames(styles.button, styles.fullwidth)}
          onClick={() => fetchSignUp()}
        >
          {loading ? <CircularProgress color="inherit" size={16} /> : "Sign up"}
        </Button>
      </div>
    </section>
  );
};

export default SignUp;
