import { HttpStatusCode } from "axios";
import { LinkButton } from "components/Button";
import LogoLoading from "components/LogoLoading";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { APIClient } from "services/api";
import styles from "../login/style.module.scss";

const Verify: React.FC = () => {
  const { token = "" } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(true);

  const fetchVerify = async () => {
    try {
      const res = await APIClient.VerifyAccount(token);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // console.log("check res--", res);
      if (status === 200) {
        if (data) {
          setLoading(false);
          setSuccess(true);
        }
      }
    } catch {
      setLoading(false);
      console.log("APIClient.ActivateCard - catch error");
    }
  };

  useEffect(() => {
    fetchVerify();
    // eslint-disable-next-line
  }, []);

  return (
    <section className={styles.Login}>
      <div className="container">
        {loading ? (
          // <CircularProgress />
          <LogoLoading />
        ) : success ? (
          <>
            <div className={styles.title}>Successfully Verified</div>
            <div className={styles.intro}>
              <p>You can now enjoy EVATAR's service</p>
            </div>
            <LinkButton small to="/dashboard" className={styles.button}>
              Go to Dashboard
            </LinkButton>
          </>
        ) : (
          <>
            <div className={styles.title}>Cannot find your card.</div>
            <div className={styles.intro}>
              <p>Please ensure it is the correct activate code / link.</p>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default Verify;
