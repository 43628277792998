import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { HttpStatusCode } from "axios";
import classNames from "classnames";
import { ConfirmModal } from "components/Modal";
import Placeholder from "components/Placeholder";
import Profile from "components/Profile";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import {
  Control,
  FieldErrors,
  FieldValues,
  useFieldArray,
  UseFieldArrayAppend,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
} from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { APIClient } from "services/api";
import { SOCIAL_LINKS } from "services/constants";
import {
  IHeadingTextData,
  ILinksData,
  IProfileData,
  IProfileSection,
  ISocialLinksData,
  IVideoData,
  IWorkExperienceData,
} from "services/type";
import {
  getEditCompanyLogoUrl,
  getEditImageUrl,
  getEditProfile,
  setCompanyLogoUrl,
  setImageUrl,
  setProfile,
} from "slices/editSlice";
import { logout } from "slices/userSlice";
import { getBase64 } from "utils";
import { v4 as uuid } from "uuid";
import styles from "../style.module.scss";
import ComponentAccordion from "./components/ComponentAccordion";
import ComponentDatePicker from "./components/ComponentDatePicker";
import ComponentDraggable from "./components/ComponentDraggable";
import ComponentSelect from "./components/ComponentSelect";

export interface IDragSelection {
  label: string;
  value: string;
  placeholder?: string;
  fields?: IDragSelectionField[];
  inputValue?: string | string[];
  // labelValue?: string;
  // suggestedLabels?: string[];
}

export interface IDragSelectionField {
  name: string;
  label: string;
}

export interface IProfileSectionDraggable extends IProfileSection {
  open: boolean;
  id: string;
}

const AccordionProfile = ({
  data,
  watch,
  control,
  setValue,
  register,
  unregister,
  errors,
  append,
  remove,
  move,
  currentImageData,
  setCurrentImageData,
  setCurrentCompanyLogoData,
}: {
  data?: Partial<IProfileData>;
  watch: UseFormWatch<FieldValues>;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  append: UseFieldArrayAppend<FieldValues, "contacts">;
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  currentImageData: any;
  setCurrentImageData: React.Dispatch<any>;
  setCurrentCompanyLogoData: React.Dispatch<any>;
}) => {
  const defaultSelectionList: IDragSelection[] = [
    {
      label: "Phone",
      value: "Phone",
      placeholder: "Phone number",
      inputValue: "",
    },
    { label: "Email", value: "Email", placeholder: "Email", inputValue: "" },
    { label: "SMS", value: "SMS", placeholder: "SMS", inputValue: "" },
    {
      label: "Location",
      value: "Location",
      placeholder: "Location",
      inputValue: "",
    },
    {
      label: "Whatsapp",
      value: "Whatsapp",
      fields: [
        { name: "phoneNumber", label: "Phone number" },
        { name: "text", label: "Message" },
      ],
      inputValue: ["", ""],
    },
    {
      label: "Contact",
      value: "Contact",
      fields: [
        { name: "firstName", label: "First Name" },
        { name: "lastName", label: "Last Name" },
        { name: "companyName", label: "Company" },
        { name: "email", label: "Email" },
        { name: "phoneNumber", label: "Phone" },
      ],
      inputValue: ["", "", "", "", ""],
    },
  ];
  const [filteredSelectionList, setFilteredSelectionList] =
    useState<IDragSelection[]>(defaultSelectionList);
  const [draggableOrder, setDraggableOrder] = useState<IDragSelection[]>([]);
  // const [currentImageUrl, setCurrentImageUrl] = useState<string>(
  //   data?.iconUrl ?? ""
  // );
  const currentImageUrl = useAppSelector(getEditImageUrl);
  const currentCompanyLogoUrl = useAppSelector(getEditCompanyLogoUrl);
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   console.log("currentImageUrl", currentImageUrl);
  //   // setValue("iconUrl", currentImageUrl ? currentImageUrl : null);
  // }, [currentImageUrl]);

  useEffect(() => {
    let contactsToSelections: IDragSelection[] = [];
    if (data?.contacts) {
      contactsToSelections = data?.contacts.map((item) => {
        // console.log("profile edit ---- ", item);
        let placeholder = "";
        let fields: IDragSelectionField[] = [];
        let inputValue: string[] = [];
        switch (item.type) {
          case "PHONE":
            placeholder = "Phone number";
            break;
          case "EMAIL":
          case "SMS":
          case "LOCATION":
            placeholder = item.title;
            break;
          case "WHATSAPP":
            fields = [
              { name: "phoneNumber", label: "Phone number" },
              { name: "text", label: "Message" },
            ];
            inputValue = [item.data?.phoneNumber, item.data?.text];
            break;
          case "CONTACT":
            fields = [
              { name: "firstName", label: "First Name" },
              { name: "lastName", label: "Last Name" },
              { name: "companyName", label: "Company" },
              { name: "email", label: "Email" },
              { name: "phoneNumber", label: "Phone" },
            ];
            inputValue = [
              item.data?.firstName,
              item.data?.lastName,
              item.data?.companyName,
              item.data?.email,
              item.data?.phoneNumber,
            ];
            break;
          default:
            break;
        }
        return {
          label: item.title,
          value: item.title,
          ...(placeholder ? { placeholder: placeholder } : undefined),
          ...(fields.length ? { fields: fields } : undefined),
          inputValue: inputValue.length ? inputValue : item.data,
        };
      });
      // console.log("contactsToSelections", contactsToSelections);
      setDraggableOrder(contactsToSelections);
    }
  }, [data?.contacts]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    if (type === "CONTACT") {
      console.log("onDragEnd draggableOrder --- ", draggableOrder);
      let arr = Array.from(draggableOrder);
      const [remove] = arr.splice(source.index, 1);
      arr.splice(destination.index, 0, remove);
      console.log("onDragEnd --- ", arr);
      setDraggableOrder(arr);
      move(source.index, destination.index);
    }
  };

  const { getRootProps: getImageRootProps, getInputProps: getImageInputProps } =
    useDropzone({
      maxFiles: 1,
      accept: {
        "image/webp": [".webp"],
        "image/svg+xml": [".svg"],
        "image/gif": [".gif"],
        "image/jpeg": [".jpg", ".jpeg"],
        "image/png": [".png"],
      },
      useFsAccessApi: false,
      onDrop: (acceptedFiles) => {
        if (acceptedFiles[0]) {
          // console.log("acceptedFiles ---- ", acceptedFiles);
          const file = acceptedFiles[0];
          const blob = URL.createObjectURL(file);
          getBase64(file).then(
            (res) =>
              setCurrentImageData({
                type: "PROFILE_ICON",
                fileName: file.name,
                base64: res,
              })
            // console.log("getBase64 --- ", res)
          );
          dispatch(setImageUrl(blob));
          // setCurrentImageUrl(blob);
          // setValue("iconUrl", blob);
        }
      },
    });

  const {
    getRootProps: getCompanyLogoRootProps,
    getInputProps: getCompanyLogoInputProps,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/webp": [".webp"],
      "image/svg+xml": [".svg"],
      "image/gif": [".gif"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    useFsAccessApi: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]) {
        // console.log("acceptedFiles ---- ", acceptedFiles);
        const file = acceptedFiles[0];
        const blob = URL.createObjectURL(file);
        getBase64(file).then(
          (res) =>
            setCurrentCompanyLogoData({
              type: "COMPANY_ICON",
              fileName: file.name,
              base64: res,
            })
          // console.log("getBase64 --- ", res)
        );
        dispatch(setCompanyLogoUrl(blob));
        // setCurrentImageUrl(blob);
        // setValue("iconUrl", blob);
      }
    },
  });

  // +AddContact menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAdd = (selection: IDragSelection) => {
    const pushArray = draggableOrder.slice(0);
    pushArray.push(selection);
    setDraggableOrder(pushArray);

    const popArray = filteredSelectionList.slice(0);
    setFilteredSelectionList(
      popArray.filter((item) => item.value !== selection.value)
    );

    // const defaultValue =
    //   selection.value === "Contact"
    //     ? ["", "", "", "", ""]
    //     : selection.value === "Whatsapp"
    //     ? ["", ""]
    //     : "";
    // console.log("selection check 000 ", selection, defaultValue);
    append({
      type: selection.value?.toUpperCase(),
      title: selection.value,
      // data: defaultValue,
    });

    handleClose();
  };

  return (
    <>
      <div className={styles.accordionUpload}>
        <Placeholder
          className={styles.propic}
          centered
          x={1}
          y={1}
          {...(currentImageUrl
            ? {
                src: currentImageUrl,
              }
            : {
                component: (
                  <div className={styles.defaultAvatar}>
                    <span className="ico ico-image"></span>
                  </div>
                ),
              })}
        />
        <IconButton
          className={classNames(styles.accordionBtn, styles.yellow)}
          onClick={() => {
            dispatch(setImageUrl(""));
            // setCurrentImageUrl("");
            // setValue("iconUrl", "");
          }}
        >
          <span className="ico ico-trash"></span>
          <span className="sr-only">Delete</span>
        </IconButton>
        <div
          {...getImageRootProps({
            className: "dropzone",
          })}
        >
          <input
            // {...register("iconUrl")}
            {...getImageInputProps()}
            // className={errors.iconUrl ? styles.uploadError : undefined}
          />
          <ButtonBase className={styles.uploadBtn}>
            <span className="ico ico-camera"></span>Upload image
          </ButtonBase>
        </div>
      </div>
      <div className={styles.accordionUpload}>
        <Placeholder
          className={styles.propic}
          centered
          x={1}
          y={1}
          {...(currentCompanyLogoUrl
            ? {
                src: currentCompanyLogoUrl,
              }
            : {
                component: (
                  <div className={styles.defaultAvatar}>
                    <span className="ico ico-image"></span>
                  </div>
                ),
              })}
        />
        <IconButton
          className={classNames(styles.accordionBtn, styles.yellow)}
          onClick={() => {
            dispatch(setCompanyLogoUrl(""));
            // setCurrentImageUrl("");
            // setValue("iconUrl", "");
          }}
        >
          <span className="ico ico-trash"></span>
          <span className="sr-only">Delete</span>
        </IconButton>
        <div
          {...getCompanyLogoRootProps({
            className: "dropzone",
          })}
        >
          <input
            // {...register("iconUrl")}
            {...getCompanyLogoInputProps()}
            // className={errors.iconUrl ? styles.uploadError : undefined}
          />
          <ButtonBase className={styles.uploadBtn}>
            <span className="ico ico-camera"></span>Company logo
          </ButtonBase>
        </div>
      </div>

      <TextField
        {...register("name", { required: true })}
        error={errors.name ? true : false}
        name="name"
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Name"
        defaultValue={data?.name ?? ""}
      />
      <TextField
        {...register("jobTitle", { required: true })}
        error={errors.jobTitle ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Job title"
        defaultValue={data?.jobTitle ?? ""}
      />
      <TextField
        {...register("company", { required: true })}
        error={errors.company ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Company"
        defaultValue={data?.company ?? ""}
      />
      <div className={styles.DragArea}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="contact-drop" type="CONTACT">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {draggableOrder.length
                  ? draggableOrder.map((item, index) => {
                      const selections = filteredSelectionList.slice(0);
                      const found = selections.some(
                        (el) => el.value === item.value
                      );
                      if (!found) {
                        selections.unshift(item);
                      }
                      return (
                        <Draggable
                          key={`contact-${item.value}-${index}`}
                          // key={uuid()}
                          draggableId={`contact-${index}`}
                          index={index}
                        >
                          {(p) => (
                            <ComponentDraggable
                              control={control}
                              setValue={setValue}
                              register={register}
                              unregister={unregister}
                              remove={remove}
                              errors={errors}
                              defaultValue={item.value}
                              selections={selections}
                              setFilteredSelectionList={
                                setFilteredSelectionList
                              }
                              innerRef={p.innerRef}
                              draggableProps={p.draggableProps}
                              dragHandleProps={p.dragHandleProps}
                              draggableOrder={draggableOrder}
                              setDraggableOrder={setDraggableOrder}
                              dragIndex={index}
                              inputValue={item.inputValue}
                            />
                          )}
                        </Draggable>
                      );
                    })
                  : null}
                {provided.placeholder}
                {filteredSelectionList.length ? (
                  <>
                    <ButtonBase
                      className={styles.addFieldBtn}
                      id="add-contact-button"
                      aria-controls={open ? "add-contact-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      + Add More
                    </ButtonBase>
                    <Menu
                      id="add-contact-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{ className: styles.addFieldMenu }}
                      MenuListProps={{
                        "aria-labelledby": "add-contact-button",
                      }}
                    >
                      {filteredSelectionList.map((item, index) => (
                        <MenuItem
                          key={`${item.label}-${index}`}
                          onClick={() => handleAdd(item)}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : null}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </>
  );
};
const AccordionHeadingText = ({
  index,
  data,
  register,
  errors,
}: {
  index: number;
  data?: IHeadingTextData;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}) => {
  return (
    <>
      <input
        {...register(`sections.${index}.type`)}
        value="HEADER_TEXT"
        hidden
      />
      <TextField
        {...register(`sections.${index}.data.header`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.header`] ? true : false}
        // {...register(`sections-HEADER_TEXT-${index}-header`, {
        //   required: true,
        // })}
        // error={errors[`sections-HEADER_TEXT-${index}-header`] ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Heading"
        defaultValue={data?.header ?? ""}
      />
      <TextField
        multiline
        {...register(`sections.${index}.data.text`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.text`] ? true : false}
        // {...register(`sections-HEADER_TEXT-${index}-text`, {
        //   required: true,
        // })}
        // error={errors[`sections-HEADER_TEXT-${index}-text`] ? true : false}
        className={classNames(styles.ComponentField, styles.ComponentTextarea)}
        aria-label="description textarea"
        placeholder="Description"
        defaultValue={data?.text ?? ""}
      />
    </>
  );
};
const AccordionWorkExperience = ({
  index,
  data,
  control,
  register,
  errors,
  accordionOrder,
  setAccordionOrder,
  setValue,
}: {
  index: number;
  data?: IWorkExperienceData;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  accordionOrder: Partial<IProfileSectionDraggable>[];
  setAccordionOrder: React.Dispatch<
    React.SetStateAction<Partial<IProfileSectionDraggable>[]>
  >;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  const now = dayjs();
  const from = data?.from ? dayjs(data?.from, "DD-MM-YYYY") : null;
  const to = data?.to ? dayjs(data?.to, "DD-MM-YYYY") : now;
  return (
    <>
      <input
        {...register(`sections.${index}.type`)}
        value="WORK_EXPERIENCE"
        hidden
      />
      <TextField
        {...register(`sections.${index}.data.company`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.company`] ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Company Name"
        defaultValue={data?.company ?? ""}
      />
      <TextField
        {...register(`sections.${index}.data.jobTitle`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.jobTitle`] ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Title"
        defaultValue={data?.jobTitle ?? ""}
      />
      <Stack
        className={classNames(styles.accordionRow, styles.fullWidth)}
        direction="row"
        alignItems="center"
      >
        <ComponentDatePicker
          name={`sections.${index}.data.from`}
          control={control}
          register={register}
          errors={errors}
          label="Start Date"
          defaultValue={from}
          accordionOrder={accordionOrder}
          setAccordionOrder={setAccordionOrder}
          dragIndex={index}
          setFormValue={setValue}
        />
        <span className={styles.text}>to</span>
        <ComponentDatePicker
          name={`sections.${index}.data.to`}
          control={control}
          register={register}
          errors={errors}
          label="End Date"
          defaultValue={to}
          accordionOrder={accordionOrder}
          setAccordionOrder={setAccordionOrder}
          dragIndex={index}
          setFormValue={setValue}
        />
      </Stack>
      <TextField
        multiline
        {...register(`sections.${index}.data.description`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.description`] ? true : false}
        className={classNames(styles.ComponentField, styles.ComponentTextarea)}
        aria-label="description textarea"
        placeholder="Description"
        defaultValue={data?.description ?? ""}
      />
    </>
  );
};
const AccordionSocialLinks = ({
  index,
  data,
  setValue,
  register,
  errors,
  accordionOrder,
  setAccordionOrder,
}: {
  index: number;
  data?: ISocialLinksData;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  accordionOrder: Partial<IProfileSectionDraggable>[];
  setAccordionOrder: React.Dispatch<
    React.SetStateAction<Partial<IProfileSectionDraggable>[]>
  >;
}) => {
  // console.log("AccordionSocialLinks data", data);
  return (
    <>
      <input
        {...register(`sections.${index}.type`)}
        value="SOCIAL_LINKS"
        hidden
      />
      <ComponentSelect
        name={`sections.${index}.data`}
        setValue={setValue}
        register={register}
        errors={errors}
        placeholder="URL"
        defaultValue={data?.type}
        inputValue={data?.url ?? ""}
        selections={SOCIAL_LINKS}
        labelValue={data?.label ?? ""}
        accordionOrder={accordionOrder}
        setAccordionOrder={setAccordionOrder}
        dragIndex={index}
      />
    </>
  );
};
const AccordionLinks = ({
  index,
  data,
  register,
  errors,
}: {
  index?: number;
  data?: ILinksData;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}) => {
  return (
    <>
      <input {...register(`sections.${index}.type`)} value="LINKS" hidden />
      <TextField
        {...register(`sections.${index}.data.title`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.title`] ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Heading"
        defaultValue={data?.title ?? ""}
      />
      <TextField
        {...register(`sections.${index}.data.url`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.url`] ? true : false}
        fullWidth
        className={classNames(
          styles.ComponentField,
          styles.ComponentInput,
          styles.centered
        )}
        placeholder="https://"
        defaultValue={data?.url ?? ""}
      />
    </>
  );
};
const AccordionVideo = ({
  index,
  data,
  register,
  errors,
}: {
  index?: number;
  data?: IVideoData;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}) => {
  return (
    <>
      <input {...register(`sections.${index}.type`)} value="VIDEO" hidden />
      <TextField
        {...register(`sections.${index}.data.title`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.title`] ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Video title"
        defaultValue={data?.title ?? ""}
      />
      <TextField
        multiline
        {...register(`sections.${index}.data.description`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.description`] ? true : false}
        className={classNames(styles.ComponentField, styles.ComponentTextarea)}
        aria-label="description textarea"
        placeholder="Description"
        defaultValue={data?.description ?? ""}
      />
      <TextField
        {...register(`sections.${index}.data.videoUrl`, {
          required: true,
        })}
        error={errors[`sections.${index}.data.videoUrl`] ? true : false}
        fullWidth
        className={classNames(styles.ComponentField, styles.ComponentInput)}
        label="Video link"
        defaultValue={data?.videoUrl ?? ""}
      />
    </>
  );
};

const defaultAccordionList: Partial<IProfileSectionDraggable>[] = [
  {
    type: "HEADER_TEXT",
    data: {
      text: "",
      header: "",
    },
    open: false,
  },
  {
    type: "WORK_EXPERIENCE",
    data: {
      to: null,
      from: null,
      company: "",
      jobTitle: "",
      description: "",
    },
    open: false,
  },
  {
    type: "SOCIAL_LINKS",
    data: {
      url: "",
      type: "FACEBOOK",
      label: "",
    },
    open: false,
  },
  {
    type: "LINKS",
    data: { url: "", title: "", iconUrl: "" },
    open: false,
  },
  {
    type: "VIDEO",
    data: { title: "", description: "", videoUrl: "" },
    open: false,
  },
];

const CardEdit: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cardid, profileid } = useParams();
  // const [profile, setProfile] = useState<Partial<IProfileData>>({});
  const profile = useAppSelector(getEditProfile);
  const currentImageUrl = useAppSelector(getEditImageUrl);
  const currentCompanyLogoUrl = useAppSelector(getEditCompanyLogoUrl);

  const [loading, setLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [demoScroll, setDemoScroll] = useState<boolean>(true);
  const [editLoading, setEditLoading] = useState<boolean>(true);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [accordionOrder, setAccordionOrder] = useState<
    Partial<IProfileSectionDraggable>[]
  >([]);
  const [profilename, setProfilename] = useState<string>("");

  const [previousImageUrl, setPreviousImageUrl] = useState<string>("");
  const [previousCompanyLogoUrl, setPreviousCompanyLogoUrl] =
    useState<string>("");

  const [currentImageData, setCurrentImageData] = useState<any>({});
  const [currentCompanyLogoData, setCurrentCompanyLogoData] = useState<any>({});

  const fetchUpdateProfile = async (
    payload: any,
    newImage?: string,
    newCompanyLogo?: string
  ) => {
    console.log(
      "check submission fetchUpdateProfile --- ",
      payload,
      newImage,
      newCompanyLogo
    );
    try {
      const res = await APIClient.UpdateProfile(profileid ?? "", payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // console.log("APIClient.UpdateProfile - data", data.data, status);
      setSubmitLoading(false);
      if (status === 200) {
        // console.log("submit success data -----", data);
        if (data.data) {
          if (newImage) setPreviousImageUrl(newImage);
          if (newCompanyLogo) setPreviousCompanyLogoUrl(newCompanyLogo);
          setConfirmOpen(true);
        }
      }
    } catch (err: any) {
      console.log("APIClient.UpdateProfile - catch error", err);
      setSubmitLoading(false);
      if (err && err.response?.status === 401) {
        console.log("401 detected, logout now");
        dispatch(logout());
        navigate("/login", {
          replace: true,
        });
      }
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const res = await APIClient.Profile(profileid ?? "");
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        // console.log("APIClient.Profile - data", data.data, status);
        setLoading(false);
        setEditLoading(false);
        if (status === 200) {
          const profileData = data?.data;
          console.log("set data", profileData);
          if (profileData?.profileName) {
            setProfilename(profileData?.profileName);
          }
          if (profileData?.data) {
            dispatch(setProfile(profileData?.data));
            dispatch(setImageUrl(profileData?.data?.iconUrl));
            dispatch(setCompanyLogoUrl(profileData?.data?.companyLogoUrl));
            setPreviousImageUrl(profileData?.data?.iconUrl);
            setPreviousCompanyLogoUrl(profileData?.data?.companyLogoUrl);
            setAccordionOrder(
              profileData?.data?.sections.map((section: IProfileSection) => {
                return { ...section, open: false, id: uuid() };
              })
            );
          }
        }
      } catch (err: any) {
        setLoading(false);
        setEditLoading(false);
        console.log("APIClient.Profile - catch error", err);
        if (err && err.response?.status === 401) {
          console.log("401 detected, logout now");
          dispatch(logout());
          navigate("/login", {
            replace: true,
          });
        }
      }
    };

    fetchProfile();
    // eslint-disable-next-line
  }, [cardid, profileid]);

  // Accordion Actions
  const onDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    if (type === "DEFAULT") {
      let arr = Array.from(accordionOrder);
      const [remove] = arr.splice(source.index, 1);
      arr.splice(destination.index, 0, remove);
      move(source.index, destination.index);
      // console.log("ondragend accordion --- ", arr);
      setAccordionOrder(arr);
    }
  };
  const handleAddAccordion = (selection: any) => {
    const pushArray = accordionOrder.slice(0);
    pushArray.push({ ...selection, id: uuid() });
    // console.log("new accordion --- ", pushArray);
    setAccordionOrder(pushArray);
    append({ type: selection.type, data: selection.data });
    handleClose();
  };
  const handleRemoveAccordion = (dragIndex: number) => {
    remove(dragIndex);
    const arr = accordionOrder.filter((item, index) => dragIndex !== index);
    setAccordionOrder(arr);
  };

  // +AddComponent Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Form Handling
  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const formEl = useRef<HTMLFormElement>(null);
  const dispatchSubmit = () => {
    formEl?.current?.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };

  // const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
  const { append, remove, move } = useFieldArray({
    control,
    name: "sections",
  });

  const {
    append: contactsAppend,
    remove: contactsRemove,
    move: contactsMove,
  } = useFieldArray({
    control,
    name: "contacts",
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // value = {
      //   ...value,
      //   ...(value.iconUrl
      //     ? { iconUrl: profile.iconUrl }
      //     : { iconUrl: previousImageUrl }),
      // };
      dispatch(setProfile(value));
      console.log("watching ... ", value, name, type);
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch, profile.iconUrl]);
  // }, [watch]);

  return (
    <section className={styles.CardEdit}>
      <div className="container">
        <form
          ref={formEl}
          onSubmit={handleSubmit((data) => {
            // console.log("submitting ... ", data);
            // console.log("submitting ... ", currentImageData);
            console.log("submitting ... old image url", previousImageUrl);
            console.log("submitting ... new image url", currentImageUrl);
            console.log("old company logo", previousCompanyLogoUrl);
            console.log("new company logo", currentCompanyLogoUrl);

            setSubmitLoading(true);

            Promise.all([
              currentImageUrl !== previousImageUrl
                ? currentImageUrl
                  ? APIClient.UploadProfileImage(currentImageData)
                  : undefined
                : undefined,
              currentCompanyLogoUrl !== previousCompanyLogoUrl
                ? currentCompanyLogoUrl
                  ? APIClient.UploadProfileImage(currentCompanyLogoData)
                  : undefined
                : undefined,
            ])
              .then((values) => {
                console.log("promise.all --- ", values);

                let finalIconUrl: string | null = currentImageUrl
                  ? previousImageUrl
                  : null;
                let finalCompanyLogoUrl: string | null = currentCompanyLogoUrl
                  ? previousCompanyLogoUrl
                  : null;

                if (values[0] && values[0].status === 200) {
                  console.log("values[0].data.data", values[0].data.data);
                  finalIconUrl = values[0].data.data;
                  // setPreviousImageUrl(values[0].data.data);
                }
                if (values[1] && values[1].status === 200) {
                  console.log("values[1].data.data", values[1].data.data);
                  finalCompanyLogoUrl = values[1].data.data;
                  // setPreviousCompanyLogoUrl(values[1].data.data);
                }

                const finalSubmission = {
                  action: {
                    type: "DEFAULT",
                  },
                  profileName: profilename,
                  data: {
                    ...profile,
                    iconUrl: finalIconUrl,
                    companyLogoUrl: finalCompanyLogoUrl,
                  },
                };

                console.log("check finalSubmission ...", finalSubmission);

                fetchUpdateProfile(
                  finalSubmission,
                  values[0] && values[0].status === 200
                    ? values[0].data.data
                    : undefined,
                  values[1] && values[1].status === 200
                    ? values[1].data.data
                    : undefined
                );
              })
              .catch((err) => {
                console.log("Promise.all upload image - catch error", err);
                setSubmitLoading(false);
                if (err && err.response?.status === 401) {
                  console.log("401 detected, logout now");
                  dispatch(logout());
                  navigate("/login", {
                    replace: true,
                  });
                }
              });
          })}
          // onSubmit={handleSubmission}
        >
          {/* <form ref={formEl} onSubmit={handleSubmission}> */}
          <div className={styles.header}>
            <motion.div
              initial={{ y: 10, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                duration: 0.6,
              }}
              viewport={{ once: true }}
              className={styles.left}
            >
              <div className={styles.previous}>
                <IconButton
                  disableRipple
                  component={Link}
                  to={`/dashboard/cards/${cardid}`}
                >
                  {/* <span className="ico ico-pointer-strong"></span> */}
                  <ArrowBackIosNewIcon />
                </IconButton>

                {loading ? null : (
                  <TextField
                    {...register("profileName", {
                      required: true,
                      onChange: (e) => {
                        setProfilename(e.target.value);
                      },
                    })}
                    error={errors.profileName ? true : false}
                    fullWidth
                    className={styles.cardname}
                    label="Profile name"
                    value={profilename}
                  />
                )}
              </div>
            </motion.div>
            <div className={styles.right}>
              {loading ? null : (
                <>
                  <Link
                    className={styles.cancel}
                    to={`/dashboard/cards/${cardid}`}
                  >
                    Cancel
                  </Link>
                  <ButtonBase
                    className={styles.save}
                    onClick={() => dispatchSubmit()}
                  >
                    {submitLoading ? (
                      <CircularProgress color="inherit" size={16} />
                    ) : (
                      "Save"
                    )}
                  </ButtonBase>
                </>
              )}
              <ConfirmModal
                title="Profile has been updated"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                okayText="Back to Card Page"
                okayAction={() => {
                  navigate(`/dashboard/cards/${cardid}`, {
                    replace: true,
                  });
                }}
                singleOption
                // cancelText="Back to Card Page"
                // cancelAction={() => {
                //   navigate(`/dashboard/cards/${cardid}`, {
                //     replace: true,
                //   });
                // }}
              />
            </div>
          </div>

          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.2,
            }}
            viewport={{ once: true }}
            className={classNames(styles.content, styles.editing)}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: 300,
                }}
              ></Box>
            ) : (
              <Paper className={styles.EditCard}>
                <div className={styles.wrapper}>
                  {/* 
                  <Placeholder
                    className={styles.banner}
                    src={""}
                    centered
                    x={540}
                    y={165}
                  />
                  <div className={styles.name}>{profilename}</div>
                  <div className={styles.head}>
                    <div className={styles.left}>Profile id: {profileid}</div>
                    <div className={styles.right}>
                      <ButtonBase className={styles.shareBtn}>
                        <span className="ico ico-camera"></span>Update image
                      </ButtonBase>
                    </div>
                  </div>
                  <div className={styles.default}>Default</div>
                  <span className="ico ico-evatar"></span> */}
                  <div className={styles.banner}>
                    <div className={styles.name}>{profilename}</div>
                    <span className="ico ico-evatar"></span>
                  </div>
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="accordion-drop">
                    {(provided) => (
                      <div
                        className={styles.info}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        <section
                          className={styles.section}
                          // {...p.dragHandleProps}
                        >
                          <ComponentAccordion
                            label={"Profile"}
                            content={
                              <AccordionProfile
                                data={profile}
                                control={control}
                                watch={watch}
                                setValue={setValue}
                                register={register}
                                unregister={unregister}
                                errors={errors}
                                append={contactsAppend}
                                remove={contactsRemove}
                                move={contactsMove}
                                currentImageData={currentImageData}
                                setCurrentImageData={setCurrentImageData}
                                setCurrentCompanyLogoData={
                                  setCurrentCompanyLogoData
                                }
                              />
                            }
                            handleRemove={handleRemoveAccordion}
                          />
                        </section>
                        {accordionOrder.length
                          ? accordionOrder.map((item, index) => {
                              // console.log("check section ---", item);
                              let content: React.ReactNode = null;
                              let label = "";
                              switch (item.type) {
                                case "HEADER_TEXT":
                                  label = "Heading + Text";
                                  content = (
                                    <AccordionHeadingText
                                      index={index}
                                      data={item.data}
                                      register={register}
                                      errors={errors}
                                    />
                                  );
                                  break;
                                case "WORK_EXPERIENCE":
                                  label = "Work Experience";
                                  content = (
                                    <AccordionWorkExperience
                                      index={index}
                                      data={item.data}
                                      control={control}
                                      register={register}
                                      errors={errors}
                                      accordionOrder={accordionOrder}
                                      setAccordionOrder={setAccordionOrder}
                                      setValue={setValue}
                                    />
                                  );
                                  break;
                                case "SOCIAL_LINKS":
                                  label = "Social Links";
                                  content = (
                                    <AccordionSocialLinks
                                      index={index}
                                      data={item.data}
                                      setValue={setValue}
                                      register={register}
                                      errors={errors}
                                      accordionOrder={accordionOrder}
                                      setAccordionOrder={setAccordionOrder}
                                    />
                                  );
                                  break;
                                case "LINKS":
                                  label = "Links";
                                  content = (
                                    <AccordionLinks
                                      index={index}
                                      data={item.data}
                                      register={register}
                                      errors={errors}
                                    />
                                  );
                                  break;
                                case "VIDEO":
                                  label = "Video";
                                  content = (
                                    <AccordionVideo
                                      index={index}
                                      data={item.data}
                                      register={register}
                                      errors={errors}
                                    />
                                  );
                                  break;
                                default:
                                  break;
                              }
                              return (
                                <Draggable
                                  key={`${label}-${index}-${item.id}`}
                                  draggableId={`${label}-${index}-${item.id}`}
                                  index={index}
                                >
                                  {(p) => (
                                    <section
                                      className={styles.section}
                                      ref={p.innerRef}
                                      {...p.draggableProps}
                                      // {...p.dragHandleProps}
                                      key={`${label}-${index}-${item.id}`}
                                    >
                                      <ComponentAccordion
                                        open={item.open}
                                        label={label}
                                        content={content}
                                        setAccordionOrder={setAccordionOrder}
                                        dragHandleProps={p.dragHandleProps}
                                        dragIndex={index}
                                        handleRemove={handleRemoveAccordion}
                                      />
                                    </section>
                                  )}
                                </Draggable>
                              );
                            })
                          : null}
                        {provided.placeholder}

                        {defaultAccordionList.length ? (
                          <>
                            <ButtonBase
                              className={styles.addFieldBtn}
                              id="add-component-button"
                              aria-controls={
                                open ? "add-component-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                            >
                              + Add Component
                            </ButtonBase>
                            <Menu
                              id="add-component-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              PaperProps={{ className: styles.addFieldMenu }}
                              MenuListProps={{
                                "aria-labelledby": "add-component-button",
                              }}
                            >
                              {defaultAccordionList.map((item, index) => {
                                let label = "";
                                switch (item.type) {
                                  case "HEADER_TEXT":
                                    label = "Heading + Text";
                                    break;
                                  case "WORK_EXPERIENCE":
                                    label = "Work Experience";
                                    break;
                                  case "SOCIAL_LINKS":
                                    label = "Social Links";
                                    break;
                                  case "LINKS":
                                    label = "Links";
                                    break;
                                  case "VIDEO":
                                    label = "Video";
                                    break;
                                  default:
                                    break;
                                }
                                return (
                                  <MenuItem
                                    key={`${label}-${index}`}
                                    onClick={() => handleAddAccordion(item)}
                                  >
                                    {label}
                                  </MenuItem>
                                );
                              })}
                            </Menu>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Paper>
            )}
            <div className={styles.contentRight}>
              <div className={styles.PhoneDemo}>
                <div
                  className={classNames(
                    styles.wrapper,
                    demoScroll ? undefined : styles.disabled
                  )}
                >
                  <Profile
                    editMode
                    editData={profile}
                    editLoading={editLoading}
                    currentImageUrl={currentImageUrl}
                    currentCompanyLogoUrl={currentCompanyLogoUrl}
                    setDemoScroll={setDemoScroll}
                  />
                </div>
              </div>
            </div>
          </motion.div>
        </form>
      </div>
    </section>
  );
};

export default CardEdit;
