import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import MuiButton from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
// import img from "assets/img/dummy-image.png";
import classNames from "classnames";
import { LinkButton } from "components/Button";
import Placeholder from "components/Placeholder";
import { motion } from "framer-motion";
// import { useLocalStorage } from "hooks/useLocalStorage";
import { HttpStatusCode } from "axios";
import { useEffect } from "react";
import { APIClient } from "services/api";
import {
  calcTotal,
  decrement,
  increment,
  remove,
  selectCart,
  selectCartOpen,
  selectProductList,
  selectSubtotal,
  setCart,
  setCartOpen,
} from "slices/cartSlice";
import styles from "./style.module.scss";
import Tooltip from "@mui/material/Tooltip";

// const shippingList = [
//   {
//     label: "Standard Shipping",
//     price: 50,
//   },
//   {
//     label: "Express Shipping",
//     price: 250,
//   },
// ];

const Cart: React.FC = () => {
  //   const [productList, setProductList] = useLocalStorage<CartItem[]>(
  //     "shopping-cart-products",
  //     []
  //   );
  const dispatch = useAppDispatch();
  const cartOpen = useAppSelector(selectCartOpen);
  // const currentShipping = useAppSelector(selectShipping);
  // const shippingFee = shippingList.find(
  //   (item) => item.label === currentShipping
  // )?.price;
  const cartList = useAppSelector(selectCart);
  const productList = useAppSelector(selectProductList);
  const subtotal = useAppSelector(selectSubtotal);
  // const total = subtotal! + shippingFee!;

  useEffect(() => {
    dispatch(calcTotal());
    // eslint-disable-next-line
  }, [cartList]);

  useEffect(() => {
    const fetchCart = async () => {
      try {
        const res = await APIClient.CartList();
        const {
          data,
          status,
        }: {
          status: HttpStatusCode;
          data: any;
        } = res;
        // setLoading(false);
        // console.log("APIClient.CartList - data", data.data, status);
        if (status === 200) {
          console.log("cart data", data.data);
          dispatch(setCart(data.data?.products));
        }
      } catch (err: any) {
        // setLoading(false);
        console.log("APIClient.CartList - catch error", err);
      }
    };

    fetchCart();
    // eslint-disable-next-line
  }, []);

  const fetchCartUpdate = async (payload: Object) => {
    try {
      const res = await APIClient.CartUpdate(payload);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // setLoading(false);
      // console.log("APIClient.CartUpdate - data", data.data, status);
      if (status === 200) {
        console.log("cart updated", data.data);
        dispatch(calcTotal());
      }
    } catch (err: any) {
      // setLoading(false);
      console.log("APIClient.CartUpdate - catch error", err);
    }
  };

  const updateCart = (quantity: number, productId: string) => {
    let currentCart = cartList.slice(0);
    console.log("currentCart", currentCart);
    if (quantity === 0) {
      currentCart = currentCart.filter((item) => item.productId !== productId);
    } else {
      currentCart = currentCart.map((item) => {
        if (item.productId === productId)
          return { ...item, quantity: item.quantity + quantity };
        else return item;
      });
    }
    console.log("after modify", currentCart);
    fetchCartUpdate({ products: currentCart });
  };

  return (
    <>
      <Tooltip title="Shopping Cart">
        <MuiButton
          className={classNames("MuiButton", styles.CartIcon)}
          onClick={() => dispatch(setCartOpen(true))}
        >
          <span className="ico ico-bag"></span>
          <span className={styles.badge}>{cartList.length}</span>
        </MuiButton>
      </Tooltip>
      <Drawer
        anchor={"right"}
        open={cartOpen}
        onClose={() => dispatch(setCartOpen(false))}
        PaperProps={{
          className: styles.Cart,
        }}
      >
        <section className={styles.head}>
          <div>Your Cart ({cartList.length})</div>
          <IconButton onClick={() => dispatch(setCartOpen(false))}>
            <CloseIcon />
            <span className="sr-only">Close menu</span>
          </IconButton>
        </section>
        {cartList.length ? (
          <>
            <section className={styles.list}>
              {cartList.map((item, index) => {
                const product = productList.filter(
                  (product) => product.id === item.productId
                )?.[0];

                const display = product?.display?.filter(
                  (product) => product.locale === "EN"
                )?.[0];

                const correspondingCurrencyPrice =
                  product?.data?.prices?.filter(
                    (product) => product.currency === "HKD"
                  )?.[0];

                return (
                  <motion.div
                    className={styles.item}
                    variants={{
                      open: { opacity: 1, y: 0 },
                      closed: { opacity: 0, y: 20 },
                    }}
                    initial="closed"
                    animate={cartOpen ? "open" : "closed"}
                    transition={{
                      duration: 0.8,
                      delay: (index + 1) * 0.2,
                    }}
                    key={index}
                  >
                    <div className={styles.column}>
                      <Placeholder
                        className={styles.thumbnail}
                        src={product?.data?.iconUrl}
                        x={75}
                        y={52}
                        centered
                        contained
                        alt={display?.value}
                      />
                      <div className={styles.info}>
                        <div>{display?.value}</div>
                      </div>
                      <div className={styles.price}>
                        HKD $
                        {item.quantity *
                          (correspondingCurrencyPrice?.finalPrice! ?? 0)}
                      </div>
                    </div>
                    <div className={styles.column}>
                      <div className={styles.quantity}>
                        <MuiButton
                          className="MuiButton"
                          onClick={() => {
                            dispatch(decrement(item.productId));
                            updateCart(-1, item.productId);
                          }}
                          {...(item.quantity === 1
                            ? { disabled: true }
                            : void {})}
                        >
                          <RemoveIcon />
                          <span className="sr-only">Subtract one</span>
                        </MuiButton>
                        <span className={styles.number}>{item.quantity}</span>
                        <MuiButton
                          className="MuiButton"
                          onClick={() => {
                            dispatch(increment(item.productId));
                            updateCart(1, item.productId);
                          }}
                        >
                          <AddIcon />
                          <span className="sr-only">Add one</span>
                        </MuiButton>
                      </div>
                      <MuiButton
                        className={classNames("MuiButton", styles.remove)}
                        onClick={() => {
                          dispatch(remove(item.productId));
                          updateCart(0, item.productId);
                        }}
                      >
                        Remove
                      </MuiButton>
                    </div>
                  </motion.div>
                );
              })}
            </section>
          </>
        ) : (
          <section className={styles.empty}>
            You have no items in your bag
            <span className="ico ico-bag"></span>
          </section>
        )}
        {cartList.length ? (
          <>
            {/* <section className={styles.summary}>
              <div className={styles.column}>
                <div className={styles.left}>Subtotal:</div>
                <div className={styles.right}>HKD ${subtotal}</div>
              </div>
              <div className={styles.column}>
                <div className={styles.left}>Shipping:</div>
                <div className={styles.right}>
                  HKD ${shippingList ? shippingFee : 0}
                </div>
              </div>
              <Select
                fullWidth
                className={styles.shipping}
                MenuProps={{ className: styles.shippingMenu }}
                IconComponent={(props) => (
                  <div {...props}>
                    <span className="ico ico-arrow-down-strong"></span>
                  </div>
                )}
                labelId="quantity-select"
                id="quantity-select"
                value={currentShipping}
                displayEmpty
                onChange={(event: SelectChangeEvent) => {
                  dispatch(setShipping(event.target.value as string));
                }}
              >
                {shippingList.length
                  ? shippingList.map((item, index) => (
                      <MenuItem key={index} value={item.label}>
                        {`${item.label} (HKD $${item.price})`}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </section> */}
            <section className={styles.foot}>
              <div className={styles.column}>
                <div className={styles.left}>Total:</div>
                <div className={styles.right}>HKD ${subtotal}</div>
              </div>
              <LinkButton
                to="/checkout"
                className={styles.checkout}
                fullWidth
                small
                onClick={() => dispatch(setCartOpen(false))}
              >
                Continue to checkout
              </LinkButton>
              {/* <Payment /> */}
            </section>
          </>
        ) : null}
      </Drawer>
    </>
  );
};

export default Cart;
