import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import IconButton from "@mui/material/IconButton";
import classNames from "classnames";
import { ConfirmModal } from "components/Modal";
import React, { useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import styles from "../../../style.module.scss";
import { IProfileSectionDraggable } from "../../edit";

type ComponentAccordionProps = {
  open?: boolean;
  label: string;
  content: React.ReactNode;
  setAccordionOrder?: React.Dispatch<
    React.SetStateAction<Partial<IProfileSectionDraggable>[]>
  >;
  dragHandleProps?: DraggableProvidedDragHandleProps | null | undefined;
  dragIndex?: number;
  handleRemove: (dragIndex: number) => void;
};

const ComponentAccordion: React.FC<ComponentAccordionProps> = ({
  open = false,
  label,
  content,
  setAccordionOrder,
  dragHandleProps,
  dragIndex = 0,
  handleRemove,
}) => {
  const [expanded, setExpanded] = useState<boolean>(open);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const disabled = !dragHandleProps;

  const toggleAccordion = () => {
    setExpanded((boolean) => {
      setAccordionOrder?.((order) => {
        return order.map((item, index) => {
          if (index === dragIndex) return { ...item, open: !boolean };
          else return item;
        });
      });
      return !boolean;
    });
  };

  return (
    <Accordion
      className={classNames(styles.ComponentField, styles.ComponentAccordion)}
      expanded={expanded}
      // onChange={toggleAccordion}
    >
      <AccordionSummary
        expandIcon={
          <IconButton className={styles.accordionBtn} onClick={toggleAccordion}>
            {expanded ? <RemoveIcon /> : <AddIcon />}
          </IconButton>
        }
        classes={{
          expandIconWrapper: styles.expandIcon,
        }}
      >
        <div
          {...dragHandleProps}
          className={classNames(
            styles.accordionBtn,
            dragHandleProps ? undefined : styles.disabled
          )}
        >
          <span className="ico ico-drag"></span>
          <span className="sr-only">Drag</span>
        </div>
        <div className={styles.accordionLabel}>{label}</div>
        {/* <IOSSwitch /> */}
        <IconButton
          className={classNames(
            styles.accordionBtn,
            disabled ? styles.disabled : undefined
          )}
          {...(disabled
            ? { disableRipple: true }
            : { onClick: () => setConfirmOpen(true) })}
        >
          <span className="ico ico-trash"></span>
          <span className="sr-only">Delete</span>
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>

      <ConfirmModal
        danger
        title="Delete component"
        remarks="Are you sure to delete this component?"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        okayText="Delete"
        okayAction={() => {
          handleRemove(dragIndex);
          setConfirmOpen(false);
        }}
      />
    </Accordion>
  );
};

export default React.memo(ComponentAccordion);
