import ButtonBase from "@mui/material/ButtonBase";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import Paper from "@mui/material/Paper";
// import qrcode from "assets/img/dummy-qrcode.svg";
// import Placeholder from "components/Placeholder";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Paper from "@mui/material/Paper";
import InfoBox from "components/InfoBox";
import Placeholder from "components/Placeholder";
import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../style.module.scss";

const SubscriptionEdit: React.FC = () => {
  return (
    <section className={styles.CardEdit}>
      <div className="container">
        <div className={styles.header}>
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{
              duration: 0.6,
            }}
            viewport={{ once: true }}
          >
            <ButtonBase
              disableRipple
              className={styles.previous}
              component={Link}
              to="/dashboard/subscriptions"
            >
              {/* <span className="ico ico-pointer-strong"></span>Subscriptions */}
              <ArrowBackIosNewIcon />
            </ButtonBase>
          </motion.div>
        </div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            duration: 0.8,
            delay: 0.2,
          }}
          viewport={{ once: true }}
          className={styles.content}
        >
          <Paper className={styles.EditCard}>
            <div className={styles.wrapper}>
              <Placeholder
                className={styles.card}
                src={""}
                centered
                x={420}
                y={260}
              />
              <div className={styles.head}>
                <div className={styles.left}>Profile id: 12345abcd</div>
                <div className={styles.right}>
                  <ButtonBase className={styles.shareBtn}>
                    <span className="ico ico-share"></span>Share Profile
                  </ButtonBase>
                </div>
              </div>
              <div className={styles.default}>Default</div>
            </div>
            <div className={styles.info}>
              <div className={styles.title}>Card 2</div>
              <div className={styles.row}>
                <div className={styles.label}>Monthly Professional Plan</div>
                <div className={styles.price}>$59</div>
              </div>
              <div className={styles.remarks}>
                Starting on 01 December 2023, your plan will renew for the
                regular price of HKD $59 every month until cancelled{" "}
              </div>
              <ButtonBase className={styles.cancel}>
                Cancel Subscription
              </ButtonBase>
            </div>
          </Paper>
          <div>
            <div></div>
            <InfoBox
              className={styles.block}
              title="Subscription Details"
              button="Update plan"
              list={[
                { label: "Subscription", value: "Monthly Professional Plan" },
                { label: "Expiry Date", value: "01 Jan 2023" },
              ]}
            />
          </div>
          {/* <Paper>
            <div className={styles.qrcode}>
              <div className={styles.title}>Send Card</div>
              <Placeholder
                className={styles.qr}
                src={qrcode}
                centered
                x={224}
                y={224}
              />
              <div className={styles.socials}>
                <IconButton>
                  <span className="ico ico-whatsapp"></span>
                </IconButton>
                <IconButton>
                  <span className="ico ico-instagram"></span>
                </IconButton>
                <IconButton>
                  <span className="ico ico-facebook"></span>
                </IconButton>
                <IconButton>
                  <span className="ico ico-twitter"></span>
                </IconButton>
                <IconButton>
                  <span className="ico ico-linkedin"></span>
                </IconButton>
              </div>
              <Button className={styles.copy}>
                <span className="ico ico-copy"></span>Copy Link
              </Button>
            </div>
          </Paper> */}
        </motion.div>
      </div>
    </section>
  );
};

export default SubscriptionEdit;
