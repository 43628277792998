import classNames from "classnames";
import Card from "components/Card";
import ProductBanner from "components/home/ProductBanner";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";

// import feature1x from "assets/img/feature.png";
// import feature2x from "assets/img/feature@2x.png";
// import nfcBand1x from "assets/img/products/nfc-band.png";
// import nfcBand2x from "assets/img/products/nfc-band@2x.png";
// import nfcCard1x from "assets/img/products/nfc-card.png";
// import nfcCard2x from "assets/img/products/nfc-card@2x.png";
// import nfcTag1x from "assets/img/products/nfc-tag.png";
// import nfcTag2x from "assets/img/products/nfc-tag@2x.png";
import demo3d from "assets/img/products/phone-demo-3d.png";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppDispatch } from "app/hooks";
import goldCard from "assets/img/products/gold-card.png";
import demoVideoMP4 from "assets/img/products/phone-demo-compressed.mp4";
import demoVideo from "assets/img/products/phone-demo.webm";
import silverCard from "assets/img/products/silver-card.png";
import ContactUs from "pages/contactus";
import { setSnapInView } from "slices/UISlice";

// import productBand1x from "assets/img/products/product-band.png";
// import productBand2x from "assets/img/products/product-band@2x.png";
// import productCard1x from "assets/img/products/product-card.png";
// import productCard2x from "assets/img/products/product-card@2x.png";
// import productTag1x from "assets/img/products/product-tag.png";
// import productTag2x from "assets/img/products/product-tag@2x.png";

const productList = [
  {
    alt: "NFC Cards",
    src: goldCard,
    // src2x: nfcCard2x,
  },
  {
    alt: "NFC Tag",
    src: silverCard,
    // src2x: nfcTag2x,
  },
  // {
  //   alt: "NFC Band",
  //   src: nfcBand1x,
  //   src2x: nfcBand2x,
  // },
];

const featureLeftList = [
  {
    title: "REUSABLE",
    desc: "Enable the safe transmission of selected contact details & social profiles data to any other smartphone.",
  },
  {
    title: "LIGHT-WEIGHTED",
    desc: "Say goodbye to carrying multiple business cards, this is the one and only business card you need in your next exhibition.",
  },
  {
    title: "CUSTOMIZABLE",
    desc: "Your profile is dynamically displayed, you can edit the information and switch your profile anytime you want.",
  },
];

const featureRightList = [
  {
    title: "PROFESSIONAL",
    desc: "The best business card to make yourself look professional to your target audiences.",
  },
  {
    title: "ECO-FRIENDLY",
    desc: "The most eco-friendly way of giving out a business card is not to give them a piece of anything that would ended up in the bottom of their drawers.",
  },
  {
    title: "COST LESS",
    desc: "Traditional business cards are recurring consumables. Digital business card is a tool that transmit your contact info.",
  },
];

const cardList = [
  {
    img: "https://www.newstrail.com/wp-content/uploads/2021/12/business-cards-1024x941.jpg",
    title: "Business Cards Statistics, Facts And Trends for 2023",
    content:
      "In today’s technological and digitally charged age, professionals don’t usually find business cards necessary; however, studies show that it may be considered a breach of etiquette not to produce business cards within formal professional environments. Attempting to network without them may be a mistake since most expect them.",
    link: "https://www.newstrail.com/business-cards/",
    external: true,
  },
  {
    img: "https://pri-num.com/wp-content/themes/twentynineteen/images/issuer/nfc-issuer-walletw-overview-1.jpg",
    title: "Business Cards Statistics, Facts And Trends for 2023",
    content:
      "The ubiquity of its presence in most every mobile phone built today along with its versatility, incredibly small factor, and low cost means you'll find NFC everywhere and in most everything. It's a touchless, secure wireless technology that connects us to the things around us.",
    link: "https://nfc-forum.org/learn/use-cases/",
    external: true,
  },
];

// const infoList = [
//   {
//     name: "EVATAR Card",
//     src: productCard1x,
//     src2x: productCard2x,
//     content: [
//       "The last business card you'll ever need",
//       "EVATAR Card is the business card for the Future, get connected with your them with a single tap.",
//     ],
//   },
//   {
//     name: "EVATAR Tag",
//     src: productTag1x,
//     src2x: productTag2x,
//     content: [
//       "Most convenient option to carry your profile",
//       "Carry your professional profile anywhere with this extra light-weighted option.",
//     ],
//   },
//   {
//     name: "EVATAR Band",
//     src: productBand1x,
//     src2x: productBand2x,
//     content: [
//       "Modern pick-up line for Smart people",
//       "Catch everyone's attention instantly with EVATAR Band!",
//     ],
//   },
// ];

const Home: React.FC = () => {
  const dispatch = useAppDispatch();

  const snapRef = useRef<HTMLDivElement>(null);
  const floatRef = useRef<HTMLDivElement>(null);
  const featureRef = useRef<HTMLDivElement>(null);
  // const [snapInView, setSnapInView] = useState(true);
  const breakpoint = useMediaQuery("(min-width: 992px)");

  // const { scrollYProgress } = useScroll({ target: snapRef });
  // const snapSlide = useTransform(
  //   scrollYProgress,
  //   [0, 1 / 3, 2 / 3, 1],
  //   [1, 2, 3, 4]
  // );

  // console.log("test", snapSlide);

  // Scroll Direction
  // const [scrollDir, setScrollDir] = useState("scrolling down");

  // useEffect(() => {
  //   const threshold = 0;
  //   let lastScrollY = window.pageYOffset;
  //   let ticking = false;

  //   const updateScrollDir = () => {
  //     const scrollY = window.pageYOffset;

  //     if (Math.abs(scrollY - lastScrollY) < threshold) {
  //       ticking = false;
  //       return;
  //     }
  //     setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
  //     lastScrollY = scrollY > 0 ? scrollY : 0;
  //     ticking = false;
  //   };

  //   const onScroll = () => {
  //     if (!ticking) {
  //       window.requestAnimationFrame(updateScrollDir);
  //       ticking = true;
  //     }
  //   };

  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, [scrollDir]);

  // Scroll Offset
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.addEventListener("scroll", onScroll, { passive: true });
    // clean up code
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const bottom = offset + window.innerHeight;
    // console.log(scrollDir);
    if (snapRef.current?.clientHeight) {
      if (
        bottom >
        (breakpoint
          ? snapRef.current?.clientHeight
          : snapRef.current?.clientHeight -
            (featureRef.current?.clientHeight ?? 0) * 0.3)
      ) {
        document.documentElement.style.scrollSnapType = "unset";
      } else {
        document.documentElement.style.scrollSnapType = "y mandatory";
      }
    }
    if (floatRef.current?.clientHeight) {
      if (bottom > floatRef.current?.clientHeight) {
        dispatch(setSnapInView(false));
      } else {
        dispatch(setSnapInView(true));
      }
    }
    // eslint-disable-next-line
  }, [offset, breakpoint]);

  return (
    <>
      <div className={styles.SnapScroll} ref={snapRef}>
        <div className={styles.float} ref={floatRef}>
          <section className={styles.Hero}>
            <div className="container">
              <div className={styles.box}>
                <div className={styles.wave}>
                  <div className={"animated-wave"}></div>
                </div>
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true }}
                >
                  <h1>
                    CHANGE THE WAY
                    <br />
                    WE NETWORK
                  </h1>
                  <p>Light-weighted</p>
                  <p>Change anytime</p>
                  <p>Leave professional impression</p>
                </motion.div>
                <motion.div
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.2 }}
                  viewport={{ once: true }}
                  className={styles.image}
                >
                  <img src={demo3d} alt="" />
                </motion.div>
              </div>
            </div>
          </section>

          {productList.length
            ? productList.map((product, index) => (
                <ProductBanner key={index} {...product} />
              ))
            : null}
        </div>
        <section className={styles.Features} ref={featureRef}>
          <div className="container">
            <div className={styles.wave}>
              <div className={"animated-wave dark"}></div>
            </div>
            <motion.h4
              initial={{ y: 50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
            >
              BUSINESS CARD FOR THE NEXT GENERATION
            </motion.h4>
            <div className={styles.grid}>
              {featureLeftList.length
                ? featureLeftList.map((feature, index) => (
                    <motion.div
                      initial={{ y: 50, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.8, delay: (index + 1) * 0.2 }}
                      viewport={{ once: true }}
                      key={index}
                      className={styles.feature}
                    >
                      <p className="h6">{feature.title}</p>
                      <p>{feature.desc}</p>
                    </motion.div>
                  ))
                : null}

              {/* <img
                className={styles.demo}
                src={feature1x}
                srcSet={`${feature1x} 1x, ${feature2x} 2x`}
                alt=""
              /> */}

              <div className={classNames(styles.PhoneDemo, styles.rowSpan)}>
                <video className={styles.demo} autoPlay loop muted playsInline>
                  <source src={demoVideoMP4} type="video/mp4" />
                  <source src={demoVideo} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
              </div>

              {featureRightList.length
                ? featureRightList.map((feature, index) => (
                    <motion.div
                      initial={{ y: 50, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.8, delay: (index + 3) * 0.2 }}
                      viewport={{ once: true }}
                      key={index}
                      className={styles.feature}
                    >
                      <p className="h6">{feature.title}</p>
                      <p>{feature.desc}</p>
                    </motion.div>
                  ))
                : null}
            </div>
          </div>
        </section>
      </div>

      <section className={styles.Cards}>
        <div className="container">
          {cardList.length
            ? cardList.map((item, index) => (
                <Card key={index} className={styles.item} {...item} />
              ))
            : null}
        </div>
      </section>

      {/* <section className={styles.ProductInfo}>
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className="container"
        >
          <h3>Choose your products</h3>
          <div className={styles.grid}>
            {infoList.length
              ? infoList.map((product, index) => (
                  <motion.div
                    initial={{ y: 50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                    viewport={{ once: true }}
                    key={index}
                    className={styles.card}
                  >
                    <Placeholder
                      src={product.src}
                      src2x={product.src2x}
                      alt={product.name}
                      x={600}
                      y={400}
                      centered
                      contained
                    />
                    <div className="h6">{product.name}</div>
                    <ul>
                      {product.content?.length
                        ? product.content.map((desc, j) => (
                            <motion.li
                              initial={{ y: 50, opacity: 0 }}
                              whileInView={{ y: 0, opacity: 1 }}
                              transition={{
                                duration: 0.8,
                                delay: j * 0.2 + index * 0.6,
                              }}
                              viewport={{ once: true }}
                              key={j}
                            >
                              <span>{desc}</span>
                            </motion.li>
                          ))
                        : null}
                    </ul>
                    <LinkButton to="/checkout" className={styles.button} small>
                      View Product
                    </LinkButton>
                  </motion.div>
                ))
              : null}
          </div>
        </motion.div>
      </section> */}

      <ContactUs />
    </>
  );
};

export default Home;
