import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { IUtilitiesSocialLinks } from "services/type";
import { capitalise } from "utils";
import styles from "../../../style.module.scss";
import { IProfileSectionDraggable } from "../../edit";

type ComponentSelectProps = {
  defaultValue?: string;
  inputValue?: string;
  labelValue?: string;
  selections: IUtilitiesSocialLinks[];
  placeholder: string;
  name?: string;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  accordionOrder: any[];
  setAccordionOrder: React.Dispatch<
    React.SetStateAction<Partial<IProfileSectionDraggable>[]>
  >;
  dragIndex: number;
};

const ComponentSelect: React.FC<ComponentSelectProps> = ({
  defaultValue,
  inputValue,
  labelValue,
  selections,
  placeholder,
  name = "",
  setValue,
  register,
  errors,
  accordionOrder,
  setAccordionOrder,
  dragIndex,
}) => {
  const [current, setCurrent] = useState<string>(
    defaultValue ?? selections[0].id
  );
  const [input, setInput] = useState<string>(inputValue ?? "");
  const [label, setLabel] = useState<string>(labelValue ?? "");

  const handleSelect = (event: SelectChangeEvent) => {
    setCurrent(event.target.value as string);
    setInput("");
    setLabel("");
  };

  useEffect(() => {
    const arr = accordionOrder.reduce((accumulator, item, index) => {
      return [
        ...accumulator,
        dragIndex === index
          ? {
              ...item,
              // ...selections.filter((item) => item.value === current)[0],
              data: {
                type: current,
                url: input,
                label: label,
              },
            }
          : item,
      ];
    }, []);

    setAccordionOrder(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, input, label]);

  return (
    <div className={styles.HalfRow}>
      <div className={styles.bar}>
        <Select
          {...register(`${name}.type`, {
            onChange: handleSelect,
          })}
          className={styles.HalfSelect}
          value={current}
          MenuProps={{ className: "MuiSelectMenu" }}
          IconComponent={(props) => (
            <div {...props}>
              <span className="ico ico-arrow-down"></span>
            </div>
          )}
        >
          {selections.map((item, index) => (
            <MenuItem key={`${item.id}-${index}`} value={item.id}>
              {capitalise(item.id)}
            </MenuItem>
          ))}
        </Select>
        <TextField
          {...register(`${name}.url`, {
            required: true,
            onChange: (e) => {
              setInput(e.target.value);
            },
          })}
          error={errors[`${name}.url`] ? true : false}
          fullWidth
          className={classNames(
            styles.ComponentField,
            styles.ComponentInput,
            styles.HalfInput
          )}
          label={placeholder}
          value={input}
        />
      </div>
      <TextField
        {...register(`${name}.label`, {
          required: true,
          onChange: (e) => {
            setLabel(e.target.value);
          },
        })}
        error={errors[`${name}.label`] ? true : false}
        fullWidth
        className={classNames(
          styles.ComponentField,
          styles.ComponentInput,
          styles.socialLink
        )}
        label="Label"
        value={label}
      />
    </div>
  );
};

export default ComponentSelect;
