import logo from "assets/img/evatar-logo-qrcode.svg";
import styles from "./style.module.scss";
import MuiModal from "@mui/material/Modal";
import classNames from "classnames";

type LoadingProps = {
  hideBackdrop?: boolean;
};

const LogoLoading: React.FC<LoadingProps> = ({ hideBackdrop }) => {
  return (
    <MuiModal
      open={true}
      className={classNames(
        styles.LogoLoading,
        hideBackdrop ? styles.navy : undefined
      )}
      {...(hideBackdrop ? { hideBackdrop: true } : undefined)}
    >
      <>
        <img className={styles.logo} src={logo} alt="" />
      </>
    </MuiModal>
  );
};

export default LogoLoading;

// export const WaveLoading = () => {
//   return (
//     <MuiModal hideBackdrop open={true} className={styles.LogoLoading}>
//       <>
//         <div className={styles.WaveLoading}>Evatar</div>
//       </>
//     </MuiModal>
//   );
// };
