import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { HttpStatusCode } from "axios";
import Button from "components/Button";
import LogoLoading from "components/LogoLoading";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { APIClient } from "services/api";
import styles from "./style.module.scss";

const Activate: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [activateCode, setActivateCode] = useState<string>("");

  const fetchActivate = async (code: string) => {
    setLoading(true);
    try {
      const res = await APIClient.ActivateCard(code);
      const {
        data,
        status,
      }: {
        status: HttpStatusCode;
        data: any;
      } = res;
      // console.log("check res--", res);
      if (status === 200) {
        if (data) {
          setLoading(false);
          setPage(false);
          setSuccess(true);
          setTimeout(() => navigate(0), 1200);
        }
      }
    } catch {
      setLoading(false);
      setPage(false);
      setSuccess(false);
      console.log("APIClient.ActivateCard - catch error");
    }
  };

  // useEffect(() => {
  // fetchActivate();
  // eslint-disable-next-line
  // }, []);

  // Form Handling
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formEl = useRef<HTMLFormElement>(null);
  // const dispatchSubmit = () => {
  //   formEl?.current?.dispatchEvent(
  //     new Event("submit", { cancelable: true, bubbles: true })
  //   );
  // };

  return (
    <section className={styles.Activate}>
      {/* <div className="container"> */}

      {page ? (
        <>
          <form
            className={styles.form}
            ref={formEl}
            onSubmit={handleSubmit((data) => {
              console.log("activateCode", data);
              fetchActivate(activateCode);
            })}
          >
            <div className={styles.title}>
              You can activate multiple cards at once with activation codes
              separated with comma
              <br />
              <span className={styles.subline}>
                (e.g. ABCD1234opQ5,EFGH67RsTUV8)
              </span>
            </div>
            <div className="MuiInput">
              <TextField
                {...register("activateCode", { required: true })}
                error={errors.activateCode ? true : false}
                fullWidth
                label="Activate Code"
                type="text"
                value={activateCode}
                onChange={(e) => setActivateCode(e.target.value)}
              />
            </div>
            <br />
            <Button
              small
              // onClick={() => fetchActivate(activateCode)}
            >
              {loading ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                "Activate"
              )}
            </Button>
            <br />
            <ButtonBase
              component={Link}
              to={"/product"}
              className={styles.link}
            >
              Get EVATAR Card
            </ButtonBase>
          </form>
        </>
      ) : loading ? (
        // <CircularProgress />
        <LogoLoading />
      ) : success ? (
        <div className={styles.title}>Successfully activated your card</div>
      ) : (
        <>
          <div className={styles.title}>Cannot find your card.</div>
          <div className={styles.intro}>
            <p>Please ensure the activation codes are valid.</p>
          </div>
        </>
      )}
      {/* </div> */}
    </section>
  );
};

export default Activate;
