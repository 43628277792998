import { IUtilitiesSocialLinks } from "./type";
import step1 from "assets/img/steps/card.svg";
import step2 from "assets/img/steps/user-tick.svg";
import step3 from "assets/img/steps/user-evatar.svg";
import step4 from "assets/img/steps/user-input.svg";
import step5 from "assets/img/steps/scan-card.svg";
import step1Dark from "assets/img/steps/card-dark.svg";
import step2Dark from "assets/img/steps/user-tick-dark.svg";
import step3Dark from "assets/img/steps/user-evatar-dark.svg";
import step4Dark from "assets/img/steps/user-input-dark.svg";
import step5Dark from "assets/img/steps/scan-card-dark.svg";

export const SOCIAL_LINKS: IUtilitiesSocialLinks[] = [
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/facebook.png",
    },
    id: "FACEBOOK",
    code: "SOCIAL_LINKS",
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/Instagram.png",
    },
    id: "INSTAGRAM",
    code: "SOCIAL_LINKS",
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/signal.png",
    },
    id: "SIGNAL",
    code: "SOCIAL_LINKS",
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/twitter.png",
    },
    code: "SOCIAL_LINKS",
    id: "TWITTER",
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/twitch.png",
    },
    id: "TWITCH",
    code: "SOCIAL_LINKS",
  },
  {
    id: "TELEGRAM",
    code: "SOCIAL_LINKS",
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/telegram.png",
    },
  },
  {
    data: {
      iconUrl:
        "https://storage.cloud.google.com/evatar_cloud_storage_dev_1/UTILITIES/reddit.png?authuser=1",
    },
    id: "REDDIT",
    code: "SOCIAL_LINKS",
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/snapchat.png",
    },
    id: "SNAPCHAT",
    code: "SOCIAL_LINKS",
  },
  {
    id: "LINKEDIN",
    code: "SOCIAL_LINKS",
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/linkedin.png",
    },
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/youtube.png",
    },
    id: "YOUTUBE",
    code: "SOCIAL_LINKS",
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/wechat.png",
    },
    id: "WECHAT",
    code: "SOCIAL_LINKS",
  },
  {
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/line.png",
    },
    id: "LINE",
    code: "SOCIAL_LINKS",
  },
  {
    id: "DISCORD",
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/discord.png",
    },
    code: "SOCIAL_LINKS",
  },
  {
    id: "TIKTOK",
    code: "SOCIAL_LINKS",
    data: {
      iconUrl:
        "https://storage.googleapis.com/evatar_cloud_storage_dev_1/UTILITIES/tiktok.png",
    },
  },
];

export const regionList = [
  { regionCode: "HK", display: "Hong Kong" },
  // { regionCode: "MO", display: "Macau" },
  // { regionCode: "CN", display: "Mainland China" },
  // { regionCode: "TW", display: "Taiwan" },
];

export const countryCodeList = ["852", "853", "86", "886"];

export const stepList = [
  {
    label: "STEP 1",
    src: step1,
    srcDark: step1Dark,
    desc: "Purchase your EVATAR Card",
  },
  {
    label: "STEP 2",
    src: step2,
    srcDark: step2Dark,
    desc: "Sign up and Login to EVATAR Dashboard",
  },
  {
    label: "STEP 3",
    src: step3,
    srcDark: step3Dark,
    desc: "Enter your EVATAR Card Activation Code sent to your email",
  },
  {
    label: "STEP 4",
    src: step4,
    srcDark: step4Dark,
    desc: "Customize your profile",
  },
  {
    label: "STEP 5",
    src: step5,
    srcDark: step5Dark,
    desc: "Tap your EVATAR Card in every event and leave lasting impression",
  },
];
