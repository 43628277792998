const env = process.env;

const CONFIG = {
  REACT_APP_APP_CLIENT_DOMAIN:
    env.REACT_APP_APP_CLIENT_DOMAIN ?? "dev.evatar.tech",
  REACT_APP_APP_API_DOMAIN:
    env.REACT_APP_APP_API_DOMAIN ??
    "https://us-central1-evatar-cloud-functions-dev.cloudfunctions.net/",
  REACT_APP_TTECH_URL:
    env.REACT_APP_TTECH_URL ?? "https://cardtest.ttechglobal.com",
};

export default CONFIG;
