import Button from "components/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./style.module.scss";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.NotFound}>
      <div className="container">
        <h1>404</h1>
        <h2>Page Not Found!</h2>
        <p>This is not the web page you are looking for.</p>
        <Button className={styles.button} onClick={() => navigate(-1)}>
          Previous Page
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
