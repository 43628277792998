import { LinkButton } from "components/Button";
import { motion, MotionValue, useScroll, useTransform } from "framer-motion";
import styles from "pages/home/style.module.scss";
import React, { useRef } from "react";

type ProductBannerProps = {
  src: string;
  alt?: string;
  src2x?: string;
};

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

const ProductBanner: React.FC<ProductBannerProps> = ({ src, src2x, alt }) => {
  const parallaxRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll({ target: parallaxRef });
  const y = useParallax(scrollYProgress, 200);

  return (
    <section className={styles.Product}>
      <div className={styles.product} ref={parallaxRef}>
        <div className={styles.wave}>
          <div className={"animated-wave"}></div>
        </div>
        <motion.img
          style={{ y }}
          className={styles.image}
          src={src}
          {...(src2x ? { srcSet: `${src} 1x, ${src2x} 2x` } : {})}
          alt={alt}
        />
      </div>
      <LinkButton to="/product" className={styles.button} small>
        Get EVATAR Card
      </LinkButton>
    </section>
  );
};
// );

export default ProductBanner;
