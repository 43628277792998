import { useAppDispatch, useAppSelector } from "app/hooks";
import Cookies from "js-cookie";
import Layout from "layouts";
import { Navigate, Outlet, useMatches } from "react-router-dom";
import { isLoggedin, logout } from "slices/userSlice";

const Routes = () => {
  const access_token = Cookies.get("API_TOKEN") ?? "";
  const logged_in = useAppSelector(isLoggedin);
  // const verified = useAppSelector((state) => state.user.verified);
  const matches = useMatches();
  const dispatch = useAppDispatch();

  console.log("matches", matches);

  // const fetchAuthGet = async () => {
  //   try {
  //     const res = await APIClient.AuthGet();
  //     const {
  //       data,
  //       status,
  //     }: {
  //       status: HttpStatusCode;
  //       data: any;
  //     } = res;
  //     console.log("APIClient.AuthGet - data", data);
  //     dispatch(setUser(data.data));
  //   } catch (err: any) {
  //     console.log("APIClient.AuthGet - catch error", err);
  //     if (err && err.response?.status === 401) {
  //       console.log("401 detected, logout now");
  //       dispatch(logout());
  //     }
  //   }
  // };

  if (!access_token) {
    dispatch(logout());
  }
  // else {
  // fetchAuthGet();
  // }

  if (
    matches[matches.length - 1].id === "Login" ||
    matches[matches.length - 1].id === "SignUp"
  ) {
    if (logged_in) {
      return <Navigate to="/dashboard/cards" replace />;
    }
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default Routes;
